import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import validator from "validator";
import ReCAPTCHA from 'react-google-recaptcha';
import HCaptcha from "@hcaptcha/react-hcaptcha";

import { SocialProvider } from '../SocialProvider';
import { CheckLocalUser } from '../CheckLocalUser';
import { CustomSelectComponent } from '../../common/CustomSelectComponent';
import { MustContainItem } from '../MustContainItem';
import { SignerService } from '../../service/SignerService';
import { IRISService } from '../../service/IRISService';
import { LoadingContent } from '../../common/LoadingContent';
import { trackBusinessEvt } from "../../common/CustomEvents";
import { sha256 } from '../../common/IdentityUtils';

import classes from './SignUp.module.css';
import './UpdatePasswordContainer.css';

var fieldInfo = {};

export const SignUp = props => {
	const { identityConfig } = props;
	const google_captcha_key = process.env.GOOGLE_CAPTCHA_KEY;
  	const hcaptcha_key = process.env.HCAPTCHA_KEY;
	const { t } = useTranslation();
	const givenNameInputRef = useRef();
	const familyNameInputRef = useRef();
	const emailInputRef = useRef();
	const passwordInputRef = useRef();
	const confirmPasswordInputRef = useRef();
	const recaptchaRef = useRef();
	const hcaptchaRef = useRef();
	const [showEmailSignInInfo, setShowEmailSignInInfo] = useState(true);
	const [showToggleButton, setShowToggleButton] = useState(true);
	const [checkLocalUser, setCheckLocalUser] = useState(false);
	const [userObj, setUserObj] = useState(null);
	const [error, setError] = useState('');
	const [countryType, setCountryType] = useState('');
	const [selectedCountry, setSelectedCountry] = useState('');
	const [selectedCountryId, setSelectedCountryId] = useState('');
	const [selectedState, setSelectedState] = useState('');
	const [selectedStateId, setSelectedStateId] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [showPasswordRules, setshowPasswordRules] = useState(false);
	const [errors, setErrors] = useState([]);
	const [emailError, setEmailError] = useState("");
	const [isTypeAConsentSelected, setTypeAConsentSelected] = useState(false);
	const [isTypeDConsentSelected, setTypeDConsentSelected] = useState(false);
	const [countryData, setCountryData] = useState([]);
	const [stateData, setStateData] = useState([]);
	const [isHuman, setIsHuman] = useState(false);
	const [autoConsent, setAutoConsent] = useState(false);

	// booleans for password validations
	const [containsUL, setContainsUL] = useState(false) // uppercase letter
	const [containsLL, setContainsLL] = useState(false) // lowercase letter
	const [containsN, setContainsN] = useState(false) // number
	const [containsSC, setContainsSC] = useState(false) // special character
	const [contains8C, setContains8C] = useState(false) // min 8 characters
	const [passwordMatch, setPasswordMatch] = useState(false) // passwords match
	const privacy_policy_text = t('privacy_policy_text');
	const [FP_text, setFPConsentText] = useState('');
	const [fp_consent, setFPConsent] = useState(null);

	// labels and state boolean corresponding to each validation
	const mustContainData = [
		[t('eight_char'), contains8C],
		[t('lower_case'), containsLL],
		[t('upper_case'), containsUL],
		[t('special_char'), containsSC],
		[t('one_number'), containsN],
		[t('passwords_match'), passwordMatch],
	];

	const getCountryData = useCallback(() => {
		SignerService.signedAPIRequest(
			'execute-api',
			'GET',
			identityConfig.proxyURL + `/v2/taxonomy/public/countries?locale=${identityConfig.language}`,
			''
		).then(response => {
			setCountryData(response.data);
		});
	}, [identityConfig]);

	useEffect(() => {
		localStorage.setItem('widget_screen', 'signup');
		getCountryData();
		localStorage.setItem("iiris_ust", new Date());
	}, [getCountryData]);

	const redirectToSignIn = () => {
		props.setFormType('signin');
		try {
			const evtObj = {};
			evtObj.cat = 'IRISIdentityEvent';
			evtObj.lbl = 'Page Visited';
			evtObj.act = 'login';
			evtObj.prop = 'emailLogin';
			evtObj.val = 1.00;
			if (window.IIRISTracker && window.IIRISTracker.trackStructEvt) {
				window.IIRISTracker.trackStructEvt(evtObj);
			}
		} catch (error) {
			console.log(error);
		}
	}

	const redirectToForgetPassword = () => {
		props.setFormType("forgetpassword");
	}

	const showSignUpWithEmailFields = () => {
		setError('');
		setShowToggleButton(false);
		setShowEmailSignInInfo(true);
	}

	function hasError(key) {
		return errors.indexOf(key) !== -1;
	}

	const validateForm = () => {
		const enteredGivenName = givenNameInputRef.current.value;
		const enteredFamilyName = familyNameInputRef.current.value;
		const enteredEmail = emailInputRef.current.value;
		const enteredPassword = passwordInputRef.current.value;
		const enteredConfirmPassword = confirmPasswordInputRef.current.value;
		let isValid = true;
		const errs = [];
		if (enteredGivenName === '') {
			isValid = false;
			errs.push('givenNameInputRef');
		}

		if (enteredFamilyName === '') {
			isValid = false;
			errs.push('familyNameInputRef');
		}

		if (enteredEmail === '') {
			isValid = false;
			errs.push('emailInputRef');
			setEmailError('emailValidation');
		}

		//email validation
		var validEmail = validator.isEmail(enteredEmail);

		if (emailError !== '') {
			isValid = false;
		}

		if (!validEmail) {
			isValid = false;
			errs.push('emailInputRef');
		}

		if (enteredPassword.length < 8) {
			isValid = false;
			errs.push('passwordInputRef');
		}
		if (enteredPassword.toLowerCase() === enteredPassword) {
			isValid = false;
			errs.push('passwordInputRef');
		}

		if (enteredPassword.toUpperCase() === enteredPassword) {
			isValid = false;
			errs.push('passwordInputRef');
		}

		if (!/\d/.test(enteredPassword)) {
			isValid = false;
			errs.push('passwordInputRef');
		}

		if (!hasSpecialChars(enteredPassword)) {
			isValid = false;
			errs.push('passwordInputRef');
		}

		if (enteredConfirmPassword !== enteredPassword) {
			isValid = false;
			errs.push('confirmPasswordInputRef');
		}

		if (selectedCountry === '') {
			isValid = false;
			errs.push('country');
		}

		if (selectedState === '' && stateData.length > 0) {
			isValid = false;
			errs.push('state');
		}

		if (identityConfig.captcha && !isHuman) {
			isValid = false;
			errs.push('captcha');
		}

		switch (countryType) {
			case "A":
				if (!isTypeAConsentSelected) {
					isValid = false;
					errs.push("Aterms");
				}
				break;
			// case "D":
			// 	if (!isTypeDConsentSelected) {
			// 		isValid = false;
			// 		errs.push("Cterms");
			// 	}
			// 	break;
			default:
				break;
		}

		if (!isValid) {
			setError('');
		}
		setErrors(errs);
		return isValid;
	}

	const userExistCheck = () => {
		var validEmail = validator.isEmail(emailInputRef.current.value);
		if (validEmail) {
			const payload = {
				"username": emailInputRef.current.value
			};
			const url = identityConfig.proxyURL + '/v2.1/authorization/public/passport/checkuser';
			return SignerService.signedAPIRequest(
				"execute-api",
				"POST",
				url,
				payload
			).then(response => {
				if (response && response.status === 'success') {					
					const code = response.data.code;
					switch (code) {
						case 'UserNotFound':
							setEmailError("");
							break;
						case 'UserFound':
							setEmailError("userExistCheck");
							break;
						case 'PasswordResetRequired':
							setEmailError("passwordResetCheck");
							break;
						case 'UserNotConfirmed':
							setEmailError("invalidUser");
							break;
						default:
							setEmailError("");
					}
				}
			});
		} else {
			setEmailError("emailValidation");
		}
	}

	// Select Event Functionality
	const OnCountrySelectChange = (...e) => {
		if (e.length !== 0) {
			setSelectedCountry(e[1].name);
			setSelectedCountryId(e[1].id);
			setStateData(e[1].states);
			fieldInfo["country"] = e[1].id;
			fieldInfo["country_name"] = e[1].name;
			if (e[1].states.length === 0) {
				setSelectedState('');
				setSelectedStateId('');
			}
			var countryTypeVal = e[1].group;
			setCountryType(countryTypeVal);
		} else {
			setSelectedCountry('');
			setCountryType('');
		}
		trackFields();
	}

	const OnStateSelectChange = (...e) => {
		if (e.length !== 0) {
			setSelectedStateId(e[1].id);
			setSelectedState(e[1].name);
			fieldInfo["state"] = e[1].id;
			fieldInfo["state_name"] = e[1].name;
		} else {
			setSelectedState('');
			setSelectedStateId('');
		}
		trackFields();
	}

	const clearObjError = obj => {
		obj.style.borderColor = '';
		setError('');
	}

	const trackFields = () => {
		try {
			fieldInfo["abandoned_on"] = "page1_dropout";
			fieldInfo["email"] = identityConfig.email_case_insensitive ? emailInputRef.current.value.toLowerCase() : emailInputRef.current.value;
			fieldInfo["first_name"] = givenNameInputRef.current.value;
			fieldInfo["last_name"] = familyNameInputRef.current.value;
			localStorage.setItem("trackedFields", JSON.stringify(fieldInfo));
		}
		catch (error) {
			console.log(error);
		}
	}

	const handleConsent = async(accessToken, userObj) => {
		try {
			const consentPayload = {
				iiris_id: userObj.id,
				product_id: identityConfig.id,
				product_type: identityConfig.siteType,
				product_sub_type: identityConfig.siteSubType,
				version: '2.x',
				date: new Date(),
				country: userObj.country,
				country_group: userObj.countryType ? userObj.countryType : '',
				consent: [
					{
						type: 'PP',
						sub_type: '',
						text: privacy_policy_text,
						link: 'https://www.informa.com/privacy-policy/',
						flag: true,
					},
				],
			};
			if (fp_consent !== null) {
				consentPayload.consent.push({
					type: '1P',
					sub_type: 'non-purchase',
					text: FP_text,
					link: '',
					flag: fp_consent,
				});
			} else if (fp_consent == null && (countryType === 'B' || countryType === 'C' || countryType === 'D' )) {
				consentPayload.consent.push({
					type: '1P',
					sub_type: 'non-purchase',
					text: t('tick_here_text'),
					link: '',
					flag: true,
				});
			}
			IRISService.makePostRequest(
				identityConfig.audienceDomainURL + '/v3/consent/data',
				consentPayload,
				accessToken
			);
		} catch (e) {
			console.log(e)
		}
	}

	const signUpWithEmail = async(event) => {
		event.preventDefault();
		if (!validateForm()) {
			return;
		}

		if (identityConfig.captcha) {
			if (identityConfig.captcha_type === "recaptcha") {
				recaptchaRef.current.reset();
			} else if (identityConfig.captcha_type === "hcaptcha") {
				hcaptchaRef.current.resetCaptcha();
			}
			setIsHuman(false);
		}
		setError('');
		setIsLoading(true);
		const given_name = givenNameInputRef.current.value;
		const family_name = familyNameInputRef.current.value;
		const email = identityConfig.email_case_insensitive ? emailInputRef.current.value.toLowerCase() : emailInputRef.current.value;
		const password = passwordInputRef.current.value;
		const country = selectedCountry;
		const state = selectedState;
		const username = email;

		// Create an AES cipher object with Cipher Block Chaining (CBC) mode
		const cipher = await window.crypto.subtle.importKey(
			'raw',
			new TextEncoder().encode(process.env.REACT_SECRET_KEY),
			{ name: 'AES-GCM' },
			false,
			['encrypt']
		  );
	  
		  // The initialization vector should be a random value and different for each request
		  const iv = new Uint8Array(16);
		  window.crypto.getRandomValues(iv);
	  
		  // Encrypt the password with the IV and cipher
		  const encodedPassword = new TextEncoder().encode(password);
		  const encryptedPassword = new Uint8Array(await window.crypto.subtle.encrypt(
			{ name: 'AES-GCM', iv: iv },
			cipher,
			encodedPassword
		  ));
	  
		  // Encode the encrypted password and IV using Base64
		  const encodedEncryptedPassword = btoa(String.fromCharCode(...new Uint8Array(encryptedPassword.buffer)));
		  const encodedIV = btoa(String.fromCharCode(...new Uint8Array(iv.buffer)));

		try {
			const signUpPayload = {
				"username": username,
				"password": password,
				"user_attributes": [
					{
						"Name": "email",
						"Value": username
					},
					{
						"Name": "given_name",
						"Value": given_name
					},
					{
						"Name": "family_name",
						"Value": family_name
					},
					{
						"Name": "custom:terms",
						"Value": "true"
					},
					{
						"Name": "custom:country",
						"Value": country
					},
					{
						"Name": "custom:state",
						"Value": state
					}
				]
			};
			const url = identityConfig.proxyURL + '/v2.1/authorization/public/passport/signup';
			SignerService.signedAPIRequest(
				"execute-api",
				"POST",
				url,
				signUpPayload,
				// identityConfig.isAutomated
			).then(() => {
				// send the encoded encrypted password and IV in the POST body to the backend
				const loginPayload = {
				"auth_flow": "CUSTOM_SRP_AUTH",
				"username": username,
				"srp_key": encodedEncryptedPassword,
				"salt": encodedIV
				}

				const url = identityConfig.proxyURL + '/v2.1/authorization/public/passport/initiateauth';
				SignerService.signedAPIRequest(
					"execute-api",
					"POST",
					url,
					loginPayload
				).then(response => {
					document.cookie = "IRIS_AUTH=" + response.data.AuthenticationResult.AccessToken + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
					document.cookie = "IRIS_HASH=" + response.data.AuthenticationResult.RefreshToken + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
					//document.cookie = "IRIS_IDT=" + response.data.AuthenticationResult.IdToken + "; path=/; samesite=strict; secure;domain=" + window.location.hostname;
					document.cookie = "IRIS_APPID=" + identityConfig.id + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
					localStorage.setItem("is_social_login", false);
					const audPayload = {
						business_email: email,
						first_name: given_name,
						last_name: family_name,
						company: '',
						product_ids: [identityConfig.id],
						country: selectedCountryId,
						country_name: selectedCountry,
						state: selectedStateId,
						state_name: selectedState,
						job_title: '',
						is_social_login: false,
						business_type: '',
						job_function: '',
						social_accounts: [],
						audience_source: identityConfig.siteName ? identityConfig.siteName : ''
					};
					IRISService.makePostRequest(
						identityConfig.audienceDomainURL + '/v3/audience/profile',
						audPayload,
						response.data.AuthenticationResult.AccessToken
					).then(audResponse => {
						if (audResponse.status === 'success') {
							const responseData = audResponse.data[0];
							const user = {
								id: responseData.id,
								email: responseData.business_email,
								given_name: responseData.first_name,
								family_name: responseData.last_name,
								flow: "emailSignup",
								product_ids: responseData.product_ids,
								country: responseData.country,
								country_id: selectedCountryId,
								country_name: responseData.country_name,
								countryType: countryType,
								state: responseData.state,
								state_name: responseData.state_name,
								company: responseData.company,
								job_title: responseData.job_title,
								is_social_login: responseData.is_social_login,
								business_type: responseData.business_type,
								job_function: responseData.job_function,
								social_accounts: responseData.social_accounts,
							};
							setUserObj(user);
							//Set User to Track
							document.cookie = "IRIS_ID=" + user.id + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
							sha256(user.email.toLowerCase()).then((hash) => {
								document.cookie = "IRIS_UHASH=" + hash + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
							});
							if (window.IIRISTracker && window.snowplow) {
								window.snowplow("setUserIdFromCookie", "IRIS_ID");
							}
							try {
								//Tracker Sign Up Event for Sign Up
								const transactionID = window.localStorage.getItem("regTransactionId");
								const evt = {
									entityId: 'NA',
									transactionId: transactionID,
									formId: 'registration',
									status: 'page1submit-email',
									type: 'register',
									ts: new Date().toISOString()
								};
								const asset = {
									id: 'NA',
									name: window.location.host,
									type: 'form',
									size: 0,
									url: window.location.href,
									created: new Date().toISOString(),
									lastUpdated: new Date().toISOString(),
									tags: 'SignUp',
									metadata: JSON.stringify(user),
								};
								trackBusinessEvt(evt, asset);
							}
							catch (error) {
								console.log(error);
							}
							handleConsent(response.data.AuthenticationResult.AccessToken, user);
							setIsLoading(false);
							setCheckLocalUser(true);
						} else if (audResponse.status === 'error') {
							setIsLoading(false);
							setError(audResponse.message);
							setEmailError("");
						}
					}).catch(() => {
						setIsLoading(false);
						setError('Signup failed.');
						setEmailError("");
					});
				}).catch(() => {
					setIsLoading(false);
					setError("Signup failed.");
				});
			}).catch(() => {
				setIsLoading(false);
				setError("Signup failed.");
			});
		} catch (e) {
			setIsLoading(false);
			setError(e.message);
			setContainsUL(false);
			setContainsLL(false);
			setContainsN(false);
			setContainsSC(false);
			setContains8C(false);
			setPasswordMatch(false);
			setEmailError("");
		}
	}

	const hasSpecialChars = str => {
		let iChars = '~`!#$%@^&*+=-[]\\\'_;,/{}()|":<>?';
		for (var i = 0; i < str.length; i++) {
			if (iChars.indexOf(str.charAt(i)) !== -1) {
				return true;
			}
		}
		return false;
	}

	const validatePassword = () => {
		// has uppercase letter
		const hasUpperCase = passwordInputRef.current.value.toLowerCase() !== passwordInputRef.current.value;
		setContainsUL(hasUpperCase);

		// has lowercase letter
		const hasLowerCase = passwordInputRef.current.value.toUpperCase() !== passwordInputRef.current.value;
		setContainsLL(hasLowerCase);

		// has number
		const hasNumber = /\d/.test(passwordInputRef.current.value);
		setContainsN(hasNumber);

		// has special character
		const hasSpChrs = hasSpecialChars(passwordInputRef.current.value);
		setContainsSC(hasSpChrs);

		// has 8 characters
		const hasLength8 = passwordInputRef.current.value.length >= 8;
		setContains8C(hasLength8);

		// passwords match
		const passwordMatches = passwordInputRef.current.value.length > 0 && passwordInputRef.current.value === confirmPasswordInputRef.current.value;
		setPasswordMatch(passwordMatches);
	}

	const showHidePassword = event => {
		const type = passwordInputRef.current.type === 'password' ? 'text' : 'password';
		passwordInputRef.current.type = type;
		event.target.classList.toggle('bi-eye');
	}

	const showHideConfirmPassword = event => {
		const type = confirmPasswordInputRef.current.type === 'password' ? 'text' : 'password';
		confirmPasswordInputRef.current.type = type;
		event.target.classList.toggle('bi-eye');
	}

	const showHidePasswordRules = () => {
		setshowPasswordRules(!showPasswordRules);
	}

	// ReCAPTCHA Client Side
	const onCaptchaLoad = () => {
		console.log('Captcha loaded.');
	}

	const verifyCaptcha = res => {
		if (res) {
			setIsHuman(true);
		}
	}

	const onError = (err) => {
		console.log(`hCaptcha Error: ${err}`);
	};

	// ReCAPTCHA Expired
	const expireCaptcha = () => {
		setIsHuman(false);
	}

	const handleTypeASiteConsent = (event) => {
		setTypeAConsentSelected(true);
		setTypeDConsentSelected(false);
		setFPConsentText(t('FP_consent_text'));
		if (event.target.value === 'YES') {
			setFPConsent(true);
		} else if (event.target.value === 'NO') {
			setFPConsent(false);
		}
	}

	const handleTypeBSiteConsent = event => {
		setFPConsentText(t('tick_here_text'));
		if (event.target.checked) {
			setFPConsent(false); // If selected means opt-out here
		} else {
			setFPConsent(true);
		}
	}

	const handleTypeDConsent = (event) => {
		console.log(event)
		setFPConsentText(t('by_continuing'));
		if (event.target.checked) {
			setTypeDConsentSelected(true);
			setTypeAConsentSelected(false);
			setFPConsent(true);
		} else {
			setTypeDConsentSelected(false);
			setTypeAConsentSelected(false);
			setFPConsent(false);
		}
	}

	return (
		<div>
			{isLoading && <LoadingContent />}
			{checkLocalUser && !isLoading && (
				<CheckLocalUser userObj={userObj} identityConfig={identityConfig} />
			)}
			{!checkLocalUser && !isLoading && (
				<div className={classes['iiris-registration-form']}>
					<form method='post' novalidate autoComplete='off'>
						<h4 className='iiris-text-center'>{props.identityConfig.siteName}</h4>
						{identityConfig.hide_social.length < 3 ?
							<div className={classes['iiris-hint-text']}>
								{t('sign_up')}
							</div> : null}
						<div className={classes['iiris-hint-text']}>
							{t('already_have')}{' '}
							<span
								onClick={redirectToSignIn}
								className={classes['iiris-login-link']}
								style={{
									cursor: 'pointer'
								}}
							>
								{t('log_in')}
							</span>
						</div>
						<div className={`${classes['iiris-social-media-option-container']} iiris-social-media-option-container`}>
							<SocialProvider
								identityConfig={identityConfig}
								showEmailSignInInfo={showEmailSignInInfo}
								showSignUpWithEmailFields={showSignUpWithEmailFields}
							/>
						</div>

						<div style={{ display: showEmailSignInInfo ? 'block' : 'none' }}>
							{identityConfig.hide_social.length < 3 ?
								<div className={`${classes['iiris-or-seperator']} iiris-or-seperator`}>
									<span>Or</span>
								</div>
								:
								null
							}

							<div
								className={classes['iiris-error-space']}
								style={{ display: error.length > 0 ? 'block' : 'none' }}
							>
								{error}
							</div>
							{identityConfig.default_social_option ?
								<h4 style={{ color: '#0593ff', margin: '15px 0' }}>Account details</h4> : ''
							}
							<div className={classes['iiris-form-container-custom']}>
								<div className={classes['iiris-form-group']}>
									<label className={classes['iiris-form-label']}>{t('first_name')}</label>
									<label style={{ color: 'red', marginLeft: '3px' }}>*</label>
									<div className='iiris-input-group'>
										<input
											type='text'
											className='iiris-form-control'
											name='given_name'
											required='required'
											maxlength='120'
											ref={givenNameInputRef}
											onChange={(e) => { clearObjError(e.target); trackFields() }}
										/>
									</div>
									<div
										className={
											classes[
											hasError('givenNameInputRef') ? 'iiris-error-space' : 'iiris-hidden'
											]
										}
									>
										{t('please_first')}
									</div>
								</div>
								<div className={classes['iiris-form-group']}>
									<label className={classes['iiris-form-label']}>{t('last_name')}</label>
									<label style={{ color: 'red', marginLeft: '3px' }}>*</label>
									<div className='iiris-input-group'>
										<input
											type='text'
											className='iiris-form-control'
											name='family_name'
											required='required'
											maxlength='120'
											ref={familyNameInputRef}
											onChange={(e) => { clearObjError(e.target); trackFields() }}
										/>
									</div>
									<div
										className={
											classes[
											hasError('familyNameInputRef')
												? 'iiris-error-space'
												: 'iiris-hidden'
											]
										}
									>
										{t('please_last')}
									</div>
								</div>
							</div>

							<div className={classes['iiris-form-container-custom']}>
								<div
									style={{
										width: '100%',
										marginBottom: '1rem',
									}}
								>
									<label className={classes['iiris-form-label']}>
										{t('business_email')}
									</label>
									<label style={{ color: 'red', marginLeft: '3px' }}>*</label>
									<div className='iiris-input-group'>
										<input
											type='email'
											className='iiris-form-control'
											name='email'
											required='required'
											autoComplete='off'
											ref={emailInputRef}
											onChange={(e) => { setEmailError(""); trackFields() }}
											onBlur={userExistCheck}
											style={{
												...((emailError === "emailValidation" || emailError === "invalidUser" || emailError === "userExistCheck" || emailError === "passwordResetCheck") && { borderColor: 'red' })
											}}
										/>
									</div>
									{emailError === "emailValidation" && (
										<div className={classes["iiris-error-space"]}>
											{t('valid_email')}
										</div>
									)}

									{emailError === "invalidUser" && (
										<div className={classes["iiris-error-space"]}>
											{t('invalid_user_status')}.
										</div>
									)}

									{emailError === "userExistCheck" && (
										<div className={classes["iiris-error-space"]}>
											{t('email_already_exist')}{" "}
											<a
												href="#"
												onClick={redirectToSignIn}
												className={classes["iiris-login-link"]}
											>
												{t('here')}
											</a>{" "}
											{t('to_login_in')}
										</div>
									)}

									{emailError === "passwordResetCheck" && (
										<div className={classes["iiris-error-space"]}>
											{t('email_already_exist_need_password_reset')}{" "}
											<a
												href="#"
												onClick={redirectToForgetPassword}
												className={classes["iiris-login-link"]}
											>
												{t('here')}
											</a>{" "}
											{t('to_reset_password')}
										</div>
									)}
								</div>
							</div>

							<div className={classes['iiris-form-container-custom']}>
								<div className={classes['iiris-form-group']}>
									<label className={classes['iiris-form-label']}>{t('country')}</label>
									<label style={{ color: 'red', marginLeft: '3px' }}>*</label>
									<div>
										<CustomSelectComponent
											isDisabled={false}
											text={t('select_country')}
											data={countryData}
											OnChange={OnCountrySelectChange}
											isSearchable={true}
											label='name'
											value='name'
											position='bottom'
											customPosition={true}
											selectedValue={selectedCountry}
										/>
									</div>

									<div
										className={
											classes[hasError('country') ? 'iiris-error-space' : 'iiris-hidden']
										}
									>
										{t('please_country')}
									</div>
								</div>

								<div className={classes['iiris-form-group']}>
									<label className={classes['iiris-form-label']}>
										{t('state')}
									</label>
									{(selectedCountryId === "US" ||
										selectedCountryId === "CA" ||
										selectedCountryId === "BR") && (
											<label style={{ color: "red", marginLeft: "3px" }}>*</label>)}
									<div>
										<CustomSelectComponent
											isDisabled={stateData.length === 0 ? true : false}
											text={
												stateData.length === 0
													? t('not_applicable')
													: t('select_state')
											}
											data={stateData}
											OnChange={OnStateSelectChange}
											isSearchable={true}
											label='name'
											value='name'
											position='bottom'
											customPosition={true}
											selectedValue={selectedState}
										/>
									</div>
									<div
										className={
											classes[hasError('state') ? 'iiris-error-space' : 'iiris-hidden']
										}
									>
										{t('please_state')}
									</div>
								</div>
							</div>

							<div className={classes['iiris-form-container-custom']}>
								<div className={classes['iiris-form-group']}>
									<label className={classes['iiris-form-label']}>{t('password')}</label>
									<label style={{ color: 'red', marginLeft: '3px' }}>*</label>
									<div className='iiris-input-group'>
										<input
											type='password'
											className='iiris-form-control'
											name='password'
											required='required'
											ref={passwordInputRef}
											onKeyUp={validatePassword}
											onFocus={() => showHidePasswordRules()}
											onBlur={() => showHidePasswordRules()}
											style={{
												...(hasError("passwordInputRef") && { borderColor: 'red' })
											}}
										/>
										<i
											style={{
												position: 'absolute',
												right: '12px',
												zIndex: 5555,
												cursor: 'pointer',
											}}
											className='bi bi-eye-slash icon'
											id='togglePassword'
											onClick={showHidePassword}
										></i>
									</div>
									<div
										className={
											classes[
											hasError('passwordInputRef') ? 'iiris-error-space' : 'iiris-hidden'
											]
										}
									>
										{t('please_password')}
									</div>
								</div>
								<div className={classes['iiris-form-group']}>
									<label className={classes['iiris-form-label']}>
										{t('confirm_password')}
									</label>
									<label style={{ color: 'red', marginLeft: '3px' }}>*</label>
									<div className='iiris-input-group'>
										<input
											type='password'
											className='iiris-form-control'
											name='confirmPassword'
											required='required'
											ref={confirmPasswordInputRef}
											onKeyUp={validatePassword}
											onFocus={() => showHidePasswordRules()}
											onBlur={() => showHidePasswordRules()}
											style={{
												...(hasError("confirmPasswordInputRef") && { borderColor: 'red' })
											}}
										/>
										<i
											style={{
												position: 'absolute',
												right: '12px',
												zIndex: 5555,
												cursor: 'pointer',
											}}
											className='bi bi-eye-slash icon'
											id='toggleConfirmPassword'
											onClick={showHideConfirmPassword}
										></i>
									</div>
									<div
										className={
											classes[
											hasError('confirmPasswordInputRef')
												? 'iiris-error-space'
												: 'iiris-hidden'
											]
										}
									>
										{t('please_confirm_password')}
									</div>
								</div>
							</div>

							<div className={classes['iiris-password-error-group']}>
								<div
									className={
										classes[showPasswordRules === true ? 'iiris-passwordPopup' : 'iiris-hidden']
									}
								>
									<ul
										style={{
											columnCount: 1,
											margin: '5px 0px 20px',
											padding: '0px 0px',
										}}
									>
										{mustContainData.map((data) => (
											<MustContainItem data={data} showPasswordRules={showPasswordRules} />
										))}
									</ul>
								</div>
							</div>

							{countryType === 'A' && (
								<div>
									<div className={classes['iiris-formbox']}>
										<div className='iiris-input-group'>
											<p>
												{t('news_promotions')}
											</p>
										</div>
										<div className={classes['iiris-form-group']}>
											<div className='iiris-form-check iiris-form-check-inline'>
												<input
													name='inlineRadioOptions1'
													className='iiris-form-check-input'
													type='radio'
													id='inlineRadio11'
													value='YES'
													onClick={handleTypeASiteConsent}
												/>
												<label className="iiris-form-check-label" for="inlineRadio11">{t('yes')}</label>
											</div>
											<div className='iiris-form-check iiris-form-check-inline'>
												<input
													name='inlineRadioOptions1'
													className='iiris-form-check-input'
													type='radio'
													id='inlineRadio12'
													value='NO'
													onClick={handleTypeASiteConsent}
												/>
												<label className="iiris-form-check-label" for="inlineRadio12">{t('no')}</label>
											</div>
										</div>
									</div>
									<div
										className={
											classes[hasError('Aterms') ? 'iiris-error-space' : 'iiris-hidden']
										}
									>
										{t('please_consent')}
									</div>
								</div>
							)}

							{(countryType === 'B' || countryType === 'C') && (
								<div>
									<div className={classes['iiris-formbox']}>
										<label className={classes['iiris-checkbox-container']}>
											<input
												type='checkbox'
												className={classes['iiris-checkbox-style']}
												onClick={handleTypeBSiteConsent}
											/>
											<span className={classes['iiris-checkmark']}></span>
											<span>
												{t('tick_here')}
											</span>
											<strong> {t('do_not')}</strong>
											<span> {t('want_to_receive')}</span>
										</label>
									</div>
								</div>
							)}
							{countryType === 'D' && (
								<div>
									<div className={classes['iiris-formbox']}>
										<div className='iiris-input-group'>
											<p>
												{t('by_continuing')}
											</p>
										</div>
									</div>
									 {/* <div className={classes['iiris-formbox']}>
										<label className={classes['iiris-checkbox-container']}>
											<input
												type='checkbox'
												onClick={handleTypeDConsent}
												className={classes['iiris-checkbox-style']}
											/>
											<span className={classes['iiris-checkmark']}></span>
											<span>
												{t('i_agree')} &nbsp;
												<a
													style={{
														textDecoration: 'underline',
														color: '#0593FF',
													}}
													href='https://www.informa.com/privacy-policy/'
													target='_blank'
													rel='noreferrer'
												>
													<b>{t('privacy_policy')}</b>
												</a>
											</span>
										</label>
									</div>
									<div
										className={
											classes[hasError("Cterms") ? 'iiris-error-space' : 'iiris-hidden']
										}
									>
										{t('please_privacy_policy')}
									</div> */}
								</div>
							)}

							{countryType !== '' && (
								<div style={{
									width: '100%',
									marginBottom: '0.8rem',
								}}>
									<div className={classes['iiris-formbox']}>
										<span>
											{t('opt_out')}{' '}
											<a
												style={{ textDecoration: 'underline', color: '#0593FF' }}
												href='https://www.informa.com/privacy-policy/'
												target='_blank'
												rel='noreferrer'
											>
												<b>{t('privacy_policy')}</b>
											</a>
										</span>
									</div>
								</div>)}

							<div style={{
								width: '100%',
								marginBottom: '0.8rem',
							}}>
								<div className={classes['iiris-formbox-terms-condn']}>
									<span>
										{t('terms_and_condition')}
									</span>
								</div>
							</div>

							{
								
									identityConfig.captcha && (
										<div className={classes['iiris-g-recaptcha']}>
											{identityConfig.captcha_type === "recaptcha" && (
												<ReCAPTCHA
													ref={recaptchaRef}
													sitekey={google_captcha_key}
													onloadCallback={onCaptchaLoad}
													onChange={verifyCaptcha}
													onExpired={expireCaptcha}
													type="image"
													hl={identityConfig.language}
												/>
											)}
											{identityConfig.captcha_type === "hcaptcha" && (
												<HCaptcha
													ref={hcaptchaRef}
													sitekey={hcaptcha_key}
													onLoad={onCaptchaLoad}
													onVerify={verifyCaptcha}
													onError={onError}
													onExpire={expireCaptcha}
												/>
											)}
											<div
												className={
													classes[hasError('captcha') ? 'iiris-error-space' : 'iiris-hidden']
												}
											>
												{t('please_captcha')}
											</div>
										</div>
									) 
							}

							<div className="iiris-btn-center">
								<button
									onClick={signUpWithEmail}
									type='button'
									className={`${classes['iiris-social-btn']} iiris-btn iiris-btn-block `}
									style={{
										color: identityConfig.skin.skin_color,
										background: identityConfig.skin.skin_background,
									}}
								>
									{t('sign_up_email')}
								</button>
							</div>
						</div>
					</form>
				</div>
			)}
		</div>
	)
};
