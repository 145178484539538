// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iiris-LmiyS {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  border-radius: 50%;\n  height: 3rem;\n  width: 3rem;\n  border: 1px solid rgba(0,0,0,.1);\n}\n\n.iiris-LmiyS img {\n  height: 1.5rem;\n  width: 1.5rem;\n}\n\n.iiris-LmiyS:hover {\n  background: #e5f4ff;\n}\n\n.iiris-yN7eS {\n  display: flex;\n  justify-content: center;\n  flex-wrap: wrap;\n  margin: 0;\n  gap: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./src/components/identity/dependents/containers/SocialProvider/SocialProvider.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,gCAAgC;AAClC;;AAEA;EACE,cAAc;EACd,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,eAAe;EACf,SAAS;EACT,SAAS;AACX","sourcesContent":[".iiris-social-icon {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  border-radius: 50%;\n  height: 3rem;\n  width: 3rem;\n  border: 1px solid rgba(0,0,0,.1);\n}\n\n.iiris-social-icon img {\n  height: 1.5rem;\n  width: 1.5rem;\n}\n\n.iiris-social-icon:hover {\n  background: #e5f4ff;\n}\n\n.iiris-custom-row-container {\n  display: flex;\n  justify-content: center;\n  flex-wrap: wrap;\n  margin: 0;\n  gap: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iiris-social-icon": "iiris-LmiyS",
	"iiris-custom-row-container": "iiris-yN7eS"
};
export default ___CSS_LOADER_EXPORT___;
