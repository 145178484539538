import axios from "axios";

export const IRISService = {
  makePostRequest: function (apiUrl, payload, token) {
    try {
      const url = apiUrl;
      const data = payload;
      const jwtToken = token;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": jwtToken,
        },
        data: data,
        url,
      };
      const result = axios(options).then(function (response) {
        return response.data;
      });
      return result;
    } catch (error) {
      return error.response;
    }
  },

  makePutRequest: function (apiUrl, payload, token) {
    try {
      const url = apiUrl;
      const data = payload;
      const jwtToken = token;
      const options = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": jwtToken,
        },
        data: data,
        url,
      };
      const result = axios(options).then(function (response) {
        return response.data;
      });
      return result;
    } catch (error) {
      return error.response;
    }
  },

  makeGetRequest: function (apiUrl, token) {
    try {
      const url = apiUrl;
      const jwtToken = token;
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": jwtToken,
        },
        url,
      };
      const result = axios(options).then(function (response) {
        return response.data;
      });
      return result;
    } catch (error) {
      return error.response;
    }
  }
};