import { Signer } from "@aws-amplify/core";
import axios from "axios";

export const SignerService = {
  signedAPIRequest: function (service, method, url, body) {
    const region = process.env.REACT_APP_COGNITO_REGION;
    const apiDomain = process.env.API_DOMAIN;
    const cognitoIdentityURL = `https://${apiDomain}/v2.1/authorization/passport/identity/credentials`;
    const headers = {
      "Content-Type": "application/json"
    };
    const serviceResponse = axios
      .get(cognitoIdentityURL, { headers })
      .then(function (response) {
        const request = {
          method: method,
          url: url,
          data: method === "GET" ? body : JSON.stringify(body)
        };
        const access_info = {
          access_key: response.data.AccessKeyId,
          secret_key: response.data.SecretKey,
          session_token: response.data.SessionToken,
        };
        const service_info = {
          service: service,
          region: region,
        };
        const signedRequest = Signer.sign(request, access_info, service_info);
        signedRequest.headers["Content-Type"] = "application/json";
        // isAutomated ? signedRequest.headers["Is-Automated"] = "true" : '' ;
        delete signedRequest.headers["host"];
        const instance = axios.create();
        const result = instance(signedRequest)
          .then(function (apiresponse) {
            return apiresponse;
          })
          .catch(function (error) {
            if (error.response) {
              return error.response;
            }
          });
        return result;
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function (signedresponse) {
        return signedresponse.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    return serviceResponse;
  },
};