import React from "react";
import { useTranslation } from "react-i18next";

import "./Modal.css";

export const Modal = props => {
  const identityConfig = props.identityConfig;
  const { t } = useTranslation();

  return (
    <div className="iiris-modal-box" style={{scrollbarColor:identityConfig.skin.skin_background,
     msScrollbarFaceColor:identityConfig.skin.skin_background, msScrollbarHighlightColor:identityConfig.skin.skin_background,
     msScrollbarTrackColor:identityConfig.skin.skin_background}}>
      <div className="iiris-box">
        <div className="iiris-close-icon-container">
          {props.heading ? 
            <span className="iiris-modal-header">
              <h4>{props.heading}</h4>
            </span> 
          : 
            ''
          }
          <span className="iiris-close-icon" id="iiris-modal-close-btn" style={{background:identityConfig.skin.skin_background,
           color:"#fff"}} onClick={props.handleClose}>
            x
          </span>
        </div>
        {props.content}
        <div className="iiris-powered-by">
          {t('powered_by')}&nbsp; <b>IIRIS</b>
        </div>
      </div>
    </div>
  );
};