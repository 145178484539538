import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import isEmail from 'validator/lib/isEmail';

import { SocialProvider } from "../SocialProvider";
import { CheckLocalUser } from "../CheckLocalUser";
import { LoadingContent } from "../../common/LoadingContent";
import { SignerService } from '../../service/SignerService';
import { IRISService } from '../../service/IRISService';
import { sha256 } from '../../common/IdentityUtils';

import classes from "./SignIn.module.css";

export const SignInWithPassword = props => {
    const { identityConfig } = props;
    const { t } = useTranslation();
    const emailInputRef = useRef();
    const passwordInputRef = useRef();
    const [showEmailSignInInfo, setShowEmailSignInInfo] = useState(false);
    const [showToggleButton, setShowToggleButton] = useState(true);
    const [error, setError] = useState("");
    const [checkLocalUser, setCheckLocalUser] = useState(false);
    const [userObj, setUserObj] = useState(null);
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const redirectToSignUp = () => {
        props.setFormType("signup");
        try {
            const evtObj = {};
            evtObj.cat = 'IRISIdentityEvent';
            evtObj.lbl = 'Page Visited';
            evtObj.act = 'register';
            evtObj.prop = 'emailRegister';
            evtObj.val = 1.00;
            if (window.IIRISTracker && window.IIRISTracker.trackStructEvt) {
                window.IIRISTracker.trackStructEvt(evtObj);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const redirectToForgetPassword = () => {
        props.setFormType("forgetpassword");
        props.setForgetPasswordScreenConfig(null);
    }

    const showSignInWithEmailFields = () => {
        setError("");
        setShowToggleButton(false);
        setShowEmailSignInInfo(true);
    }

    const validateForm = () => {
        const enteredEmail = emailInputRef.current.value.trim();
        const enteredPassword = passwordInputRef.current.value;
        let isValid = true;
        const errs = [];
        if (enteredEmail === "") {
            isValid = false;
            errs.push("emailInputRef");
        }
        //email validation
        var validEmail = isEmail(enteredEmail);
        if (!validEmail) {
            isValid = false;
            errs.push("emailInputRef");
        }
        if (enteredPassword === "") {
            isValid = false;
            errs.push("passwordInputRef");
        }
        if (!isValid) {
            setError("");
        }
        setErrors(errs);
        return isValid;
    }

    const clearObjError = obj => {
        obj.style.borderColor = "";
        setError("");
    }

    function hasError(key) {
        return errors.indexOf(key) !== -1;
    }

    const clearIRISCookies = () => {
        const cookies = document.cookie.split(';');
        for (let cookie of cookies) {
            const eqPos = cookie.indexOf('=');
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            if (name.indexOf('IRIS_') >= 0) {
                document.cookie =
                    name +
                    '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=' +
                    window.location.hostname
            }
        }
    }

    const triggerLoginCallback = (data, token) => {
        if (identityConfig.persist_session === false) {
            clearIRISCookies();
        }

        if (window.irisLoginCallback) {
            let callbackUrl = ''
            if (!identityConfig.show_progressive_profile){
                callbackUrl =  `${identityConfig.audienceDomainURL}/v3/audience/profile/${data.id}`
            }else{
                callbackUrl = `${identityConfig.audienceDomainURL}/v3/audience/profile/${data.id}/${identityConfig.id}`
            }
            IRISService.makeGetRequest(
                callbackUrl,
                token
            ).then(res => {
                if (res.status === 'success') {
                    let userCallbackdata = res.data[0]
					userCallbackdata.is_social_login = JSON.parse(localStorage.getItem("is_social_login"))
                    const loginCallBackObj = { "success": true, "user": userCallbackdata, "token": token };
                    window.irisLoginCallback(loginCallBackObj);
                } else if (res.status === 'error') {
                    const loginCallBackObj = { "success": false, "user": null, "token": null };
                    window.irisLoginCallback(loginCallBackObj);
                }
            }).catch((err) => {
                const loginCallBackObj = { "success": false, "user": null, "token": null };
                window.irisLoginCallback(loginCallBackObj);
            });
        }
        localStorage.setItem("CheckLocalUser", "NO");
        if (document.getElementById("iiris-modal-close-btn")) {
            document.getElementById("iiris-modal-close-btn").click();
        }
    }

    const signInWithEmail = async (event) => {
        event.preventDefault();
        if (!validateForm()) {
            return;
        }
        setError("");
        setIsLoading(true);
        const email = emailInputRef.current.value.trim();
        const password = passwordInputRef.current.value;
        const username = identityConfig.email_case_insensitive ? email.toLowerCase() : email;

        // Create an AES cipher object with Cipher Block Chaining (CBC) mode
        const cipher = await window.crypto.subtle.importKey(
            'raw',
            new TextEncoder().encode(process.env.REACT_SECRET_KEY),
            { name: 'AES-GCM' },
            false,
            ['encrypt']
        );
    
        // The initialization vector should be a random value and different for each request
        const iv = new Uint8Array(16);
        window.crypto.getRandomValues(iv);
    
        // Encrypt the password with the IV and cipher
        const encodedPassword = new TextEncoder().encode(password);
        const encryptedPassword = new Uint8Array(await window.crypto.subtle.encrypt(
            { name: 'AES-GCM', iv: iv },
            cipher,
            encodedPassword
        ));
    
        // Encode the encrypted password and IV using Base64
        const encodedEncryptedPassword = btoa(String.fromCharCode(...new Uint8Array(encryptedPassword.buffer)));
        const encodedIV = btoa(String.fromCharCode(...new Uint8Array(iv.buffer)));
            
        // send the encoded encrypted password and IV in the POST body to the backend
        const payload = {
            "auth_flow": "CUSTOM_SRP_AUTH",
            "username": username,
            "srp_key": encodedEncryptedPassword,
            "salt": encodedIV
        }

        let user = {};
        const url = identityConfig.proxyURL + '/v2.1/authorization/public/passport/initiateauth';
        SignerService.signedAPIRequest(
            "execute-api",
            "POST",
            url,
            payload
        ).then(response => {
            if (response.status === 'success') {
                document.cookie = "IRIS_AUTH=" + response.data.AuthenticationResult.AccessToken + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
                document.cookie = "IRIS_HASH=" + response.data.AuthenticationResult.RefreshToken + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
                //document.cookie = "IRIS_IDT=" + response.data.AuthenticationResult.IdToken + "; path=/; samesite=strict; secure;domain=" + window.location.hostname;
                document.cookie = "IRIS_APPID=" + identityConfig.id + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
                let profileinfo_payload = {
                    "email" : username,
                    "profile_type":"business_email"
                  }
                  IRISService.makePostRequest(
                    `${identityConfig.audienceDomainURL}/v3/audience/profileinfo`,
                    profileinfo_payload,
                    response.data.AuthenticationResult.AccessToken
                ).then(audResponse => {
                    if (audResponse.status === 'success') {
                        //setUser(response.data[0]);
                        const data = audResponse.data[0];
                        user.id = data.id;
                        user.email = data.business_email;
                        user.given_name = data.first_name;
                        user.family_name = data.last_name;
                        user.flow = "emailSignin";
                        user.country = data.country;
                        user.countryType = "";
                        user.state = data.state;
                        user.company = data.company;
                        user.job_title = data.job_title;
                        user.is_social_login = data.is_social_login;
                        user.business_type = data.business_type;
                        user.job_function = data.job_function;
                        user.social_accounts = data.social_accounts;
                        user.product_ids = data.product_ids ? data.product_ids : [];
                        //Set User to Track, updated cookie expiry to 90 days.
                        document.cookie = "IRIS_ID=" + user.id + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
                        sha256(user.email.toLowerCase()).then((hash) => {
                            document.cookie = "IRIS_UHASH=" + hash + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
                        });
                        if (window.IIRISTracker && window.snowplow) {
                            window.snowplow("setUserIdFromCookie", "IRIS_ID");
                        }
                        try {
                            const evtObj = {};
                            evtObj.cat = 'IRISIdentityEvent';
                            evtObj.lbl = 'Login Mode';
                            evtObj.act = 'login';
                            evtObj.prop = 'emailLogin';
                            evtObj.val = 1.00;
                            if (window.IIRISTracker && window.IIRISTracker.trackStructEvt) {
                                window.IIRISTracker.trackStructEvt(evtObj);
                            }
                        } catch (error) {
                            console.log(error);
                        }

                        if (identityConfig.cognito_add_business_email_attr && !returnedResponse.attributes['custom:business_email']) {
                            // UPDATE USER WITH BUSINESS EMAIL
                            try {
                                CognitoService.updateUserAttributes({
                                    "Name": "custom:business_email",
                                    "Value": data.business_email
                                });
                            } catch (e) {
                                console.log(e);
                            }
                            try {
                                let isLinkedInLogin = user.email.startsWith('linkedin.');
                                CognitoService.updateTokens(
                                    identityConfig.clientId,
                                    isLinkedInLogin,
                                    isLinkedInLogin ? user.email.split("linkedin.")[1] : user.email
                                ).then(token => {
                                    if (identityConfig.persist_session === false) {
                                        clearIRISCookies();
                                    }
                                    const loginCallBackObj = { "success": true, "user": data, "token": token.access_token, "idToken": token.id_token };
                                    if (window.irisLoginCallback) {
                                        window.irisLoginCallback(loginCallBackObj);
                                    }
                                });
                            } catch (e) {
                                console.log(e);
                            }
                        }

                        IRISService.makeGetRequest(
                            `${identityConfig.audienceDomainURL}/v3/audience/registration/${audResponse.data[0].id}/${identityConfig.id}`,
                            response.data.AuthenticationResult.AccessToken
                        ).then(regResponse => {
                            if (regResponse.status === 'success') {
                                triggerLoginCallback(data, response.data.AuthenticationResult.AccessToken);
                            } else if (regResponse.status === 'error') {
                                if (!identityConfig.show_progressive_profile) {
                                    triggerLoginCallback(data, response.data.AuthenticationResult.AccessToken);
                                } else {
                                    setUserObj(user);
                                    setCheckLocalUser(true);
                                    setIsLoading(false);
                                }
                            }
                        }).catch((err) => {
                            if (!identityConfig.show_progressive_profile) {
                                triggerLoginCallback(data, response.data.AuthenticationResult.AccessToken);
                            } else {
                                setUserObj(user);
                                setCheckLocalUser(true);
                                setIsLoading(false);
                            }
                        });
                    } else if (audResponse.status === 'error') {
                        setIsLoading(false);
                        setError("User not found");
                    }
                }).catch((err) => {
                    setIsLoading(false);
                    setError("User not found");
                });
            } else if (response.status === 'error') {
                setIsLoading(false);
                const code = response.message;
                switch (code) {
                    case 'UserNotFoundException':
                        setError(t('login_user_not_found'));
                        break;
                    case 'NotAuthorizedException':
                        setError(t('login_not_authorized'));
                        break;
                    case 'PasswordResetRequiredException':
                        setError(t('login_password_reset_required'));
                        break;
                    case 'UserNotConfirmedException':
                        setError(t('login_user_not_confirmed'));
                        break;
                    default:
                        setError(t('login_default_error'));
                }
            }
        });
    }

    const showHidePassword = event => {
        const type =
            passwordInputRef.current.type === "password" ? "text" : "password";
        passwordInputRef.current.type = type;
        event.target.classList.toggle("bi-eye");
    }

    const loginOnEnter = event => {
        // Number 13 is the "Enter" key on the keyboard
        if (event.keyCode === 13) {
            signInWithEmail(event);
        }
    }

    return (
        <div>
            {isLoading && <LoadingContent />}
            {checkLocalUser && !isLoading && (
                <CheckLocalUser userObj={userObj} identityConfig={identityConfig} />
            )}
            {!checkLocalUser && !isLoading && (
                <div className={classes["iiris-login-form"]}>
                    <form method="post" autoComplete="off">
                        <h4 className="iiris-text-center">
                            {props.identityConfig.siteName}
                        </h4>
                        <div className={classes["iiris-hint-text"]} style={{ width: "350px" }}>
                            {t('sign_in')}
                        </div>
                        <div className={`${classes["iiris-social-media-option-container"]} iiris-social-media-option-container`}>
                            <SocialProvider
                                identityConfig={identityConfig}
                                showEmailSignInInfo={showEmailSignInInfo}
                                showSignUpWithEmailFields={showSignInWithEmailFields}
                            />
                        </div>

                        <div style={{ display: showEmailSignInInfo ? "block" : "none" }}>
                            {identityConfig.hide_social.length < 3 ?
                                <div className={`${classes["iiris-or-seperator"]} iiris-or-seperator`}>
                                    <span>Or</span>
                                </div>
                                :
                                null
                            }
                            <div
                                className={classes["iiris-error-space-center"]}
                                style={{ display: error.length > 0 ? "block" : "none", width: "350px", textAlign: "center !important", marginTop: '5px' }}
                            >
                                {error}
                            </div>

                            <div style={{ width: "100%" }}>
                                <label className={classes["iiris-form-label"]}>{t('email')}</label>
                                <label style={{ color: "red", marginLeft: "3px" }}>*</label>
                                <div className="iiris-input-group">
                                    <input
                                        type="text"
                                        className="iiris-form-control"
                                        name="email"
                                        required="required"
                                        ref={emailInputRef}
                                        onChange={(e) => clearObjError(e.target)}
                                    />
                                </div>
                                <div
                                    className={
                                        classes[
                                        hasError("emailInputRef") ? "iiris-error-space" : "iiris-hidden"
                                        ]
                                    }
                                >
                                    {t('valid_email')}
                                </div>
                            </div>

                            <div style={{ width: "100%", marginTop: "10px" }}>
                                <label className={classes["iiris-form-label"]}>{t('password')}</label>
                                <label style={{ color: "red", marginLeft: "3px" }}>*</label>
                                <div className="iiris-input-group">
                                    <input
                                        type="password"
                                        className="iiris-form-control"
                                        name="password"
                                        required="required"
                                        ref={passwordInputRef}
                                        onChange={(e) => clearObjError(e.target)}
                                        onKeyUp={loginOnEnter}
                                    />
                                    <i
                                        style={{
                                            position: "absolute",
                                            right: "12px",
                                            zIndex: 5555,
                                            cursor: "pointer",
                                        }}
                                        className="bi bi-eye-slash"
                                        id="togglePassword"
                                        onClick={showHidePassword}
                                    ></i>
                                </div>
                                <div
                                    className={
                                        classes[
                                        hasError("passwordInputRef") ? "iiris-error-space" : "iiris-hidden"
                                        ]
                                    }
                                >
                                    {t('enter_password')}
                                </div>
                            </div>

                            <div className="iiris-clearfix" style={{ marginTop: "20px" }}>
                                <a
                                    href="#"
                                    className={classes["iiris-forget-password-link"]}
                                    onClick={redirectToForgetPassword}
                                >
                                    {t('forgot')}
                                </a>
                            </div>

                            <div className="iiris-text-center iiris-btn-center" style={{ marginTop: "20px" }}>
                                <button
                                    onClick={signInWithEmail}
                                    className={`${classes["iiris-social-btn"]} iiris-btn iiris-btn-block `}
                                    style={{ color: identityConfig.skin.skin_color, background: identityConfig.skin.skin_background }}
                                    type="button"
                                >
                                    {t('sign_in_email')}
                                </button>
                            </div>

                            <div className={classes["iiris-hint-text"]}>
                                {t('havent_joined')}{" "}
                                <a
                                    href="#"
                                    className={classes["iiris-register-link"]}
                                    onClick={redirectToSignUp}
                                    style={{
                                        textDecoration: "none",
                                        color: "#0593FF",
                                    }}
                                >
                                    {t('register')}
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};