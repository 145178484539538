/**
 *  Identity root file
 * **/

import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';
import regeneratorRuntime from "regenerator-runtime";

import { SignerService } from '../identity/dependents/service/SignerService';
import { Modal } from './dependents/common/Modal';
import { CognitoService } from '../identity/dependents/service/CognitoService';
import resources from '../../resources';
import { WidgetType } from './dependents/containers/widgetType';
import { trackBusinessEvt } from "./dependents/common/CustomEvents";

import './iiris-identity.css';

var CryptoJS = require("crypto-js");

const IrisIdentity = props => {
	const { identityConfig } = props;
	const [isLoggedInUser, setIsLoggedInUser] = useState(false);
	const [formType, setFormType] = useState('');
	const [user, setUser] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const [forgetPasswordScreenConfig, setForgetPasswordScreenConfig] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	identityConfig.toggleModal = () => {
		if (window.localStorage.getItem("trackedFields")) {
			//Tracker Sign Up Event for Sign Up
			const transactionID = window.localStorage.getItem("regTransactionId");
			const metadata = window.localStorage.getItem("trackedFields");
			const pageDetails = JSON.parse(metadata).abandoned_on;
			const eventData = {
				formId: 'registration',
				status: pageDetails,
				type: 'abandoned',
				tags: 'SignUp',
			}
			sendTrackerEvent(transactionID, metadata, eventData);
			window.localStorage.removeItem("trackedFields");
		}
		setIsOpen(!isOpen);
		if(localStorage.getItem('widget_screen') === 'profile_form'){
			window.location.reload()
		}
	};

	identityConfig.redirectToProfilePage = () => {
		if (document.getElementById("iiris-modal-close-btn")) {
			document.getElementById("iiris-modal-close-btn").click();
		} else {
			identityConfig.toggleModal();
		}
	};

	const sendTrackerEvent = (transactionID, metadata, eventData) => {
		try {
			const evt = {
				entityId: 'NA',
				transactionId: transactionID,
				formId: eventData.formId,
				status: eventData.status,
				type: eventData.type,
				ts: new Date().toISOString()
			}
			const asset = {
				id: 'NA',
				name: window.location.host,
				type: 'form',
				size: 0,
				url: window.location.href,
				created: new Date().toISOString(),
				lastUpdated: new Date().toISOString(),
				tags: eventData.tags,
				metadata: metadata,
			}
			trackBusinessEvt(evt, asset);
		}
		catch (error) {
			console.log(error);
		}
	};

	const trackStructEvt = (act, prop) => {
		try {
			const evtObj = {};
			evtObj.cat = 'IRISIdentityEvent';
			evtObj.lbl = 'Page Visited';
			evtObj.act = act;
			evtObj.prop = prop;
			evtObj.val = 1.00;
			if (window.IIRISTracker && window.IIRISTracker.trackStructEvt) {
				window.IIRISTracker.trackStructEvt(evtObj);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const register = () => {
		setIsLoading(false);
		clearIRISCookies();
		localStorage.removeItem('CheckLocalUser');
		localStorage.removeItem('auth_flow');
		window.localStorage.removeItem("trackedFields");
		setFormType('signup');
		setIsOpen(true);
		localStorage.setItem("iiris_ust", new Date());

		//Tracker Initial Event for Sign Up
		const transactionID = uuidv4();
		window.localStorage.setItem("regTransactionId", transactionID);
		const eventData = {
			formId: 'NA',
			status: 'init',
			type: 'register',
			tags: 'Initiated',
		}
		sendTrackerEvent(transactionID, '', eventData);
		trackStructEvt('register', 'Register');
	};

	const login = () => {
		setIsLoading(false);
		clearIRISCookies();
		localStorage.removeItem('CheckLocalUser');
		localStorage.removeItem('auth_flow');
		setFormType('signin');
		setIsOpen(true);
		localStorage.setItem("iiris_ust", new Date());
		trackStructEvt('login', 'Login');
	}

	const reload = async () => {
		setUser(null);
		setFormType("workemailcheck");
		setIsLoading(true);
		await CognitoService.getUserAttributes().then(user => {
			if (user != null && user.email !== "") {
				setIsLoading(false);
				setUser(user);
				setIsLoggedInUser(true);
			}
		}).catch((e) => {
			setIsLoading(false);
		});
	};

	const clearIRISCookies = () => {
		const cookies = document.cookie.split(';')
		for (let cookie of cookies) {
			const eqPos = cookie.indexOf('=')
			const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
			if (name.indexOf('IRIS_') >= 0) {
				document.cookie =
					name +
					'=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=' +
					window.location.hostname
			}
		}
	}

	const revokeIRISToken = async () => {
		try {
			const auth_token = getIIRISCookie('IRIS_AUTH');
			const refresh_token = getIIRISCookie('IRIS_HASH');
			
			const response = await fetch('https://' + apiDomain + '/v2.1/authorization/revokeToken', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${auth_token}`
				},
				body: JSON.stringify({
					refresh_token: refresh_token
				})
			});

			if (response.ok) {
				console.log('Logout successful');
			} else {
				console.error('Logout failed');
			}
		} catch (error) {
			console.error('Error while logging out:', error);
		}

	}
	
	const getIIRISCookie = (cname) => {
		let name = cname + "=";
		let decodedCookie = decodeURIComponent(document.cookie);
		let ca = decodedCookie.split(";");
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == " ") {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	const logout = async () => {
		//Clear Tracking UserID
		if (window.IIRISTracker) {
			window.IIRISTracker.clearTrackingUID()
		}
		setUser(null);
		setIsLoggedInUser(false);
		setIsOpen(false);
		localStorage.removeItem('CheckLocalUser');
		localStorage.removeItem('auth_flow');
		localStorage.removeItem('iiris_ust');
		localStorage.removeItem("audience_profile");
		localStorage.removeItem("audience_consent");
		await revokeIRISToken();
		clearIRISCookies();
		window.location.reload();
		if (window.irisLogoutCallback) {
			const logoutObj = { "success": true };
			window.irisLogoutCallback(logoutObj)
		}
		document.cookie = 'IDENTITY_LOCALE=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=' + window.location.hostname;
	}

	const updateUser = async () => {
		try {
			await CognitoService.getUserAttributes().then((user) => {
				if (user != null && user.email !== "") {
					const checkForLocalUser = localStorage.getItem("CheckLocalUser") && localStorage.getItem("CheckLocalUser") === "NO";
					if (checkForLocalUser) {
						if (window.identitySuccessCallback) {
							window.identitySuccessCallback(user);
						}
					} else if (!identityConfig.show_registration) {
						setFormType("workemailcheck");
					}
					setIsLoggedInUser(!checkForLocalUser);
					setIsOpen(!checkForLocalUser);
					setUser(user);
				}
			});
		} catch (error) {
			setUser(null);
			if (window.irisProfileMgrCallback) {
				const widgetReloadCallbackObj = { "success": true, "user": null, "token": null, "idToken": null };
				window.irisProfileMgrCallback(widgetReloadCallbackObj);
			}
			if (!identityConfig.show_registration) {
				setFormType("");
				setIsOpen(true);
			}
		}
		if (window.iirisIdentityLibLoaded) {
			window.iirisIdentityLibLoaded();
		}
	};

	const setResponseToCookies = (responseData) => {
		localStorage.setItem("is_social_login", true);
		try {
			document.cookie = "IRIS_AUTH=" + responseData.access_token + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
			document.cookie = "IRIS_HASH=" + responseData.refresh_token + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
			document.cookie = "IRIS_IDT=" + responseData.id_token + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
			reload();
		} catch (e) {
			console.log(e);
		}
	};

	const iirisLinkedinAuthHandler = code => {
		setFormType("");
		setIsLoading(true);
		fetch('https://' + apiDomain + '/v2.1/authorization/linkedInAuth', {
			method: 'POST',
			headers: {
				'Authorization': code
			},
		}).then((response) => response.json())
			.then((responseData) => {
				setResponseToCookies(responseData.data);
			}).catch(err => {
				console.log(err);
			})
	};

	const iirisSocialAuthHandler = (code, url) => {
		var details = {
			'grant_type': 'authorization_code',
			'client_id': identityConfig.clientId,
			'code': code,
			'code_verifier': localStorage.getItem("ouath_pkce_key"),
			'redirect_uri': url
		};

		var formBody = [];
		for (var property in details) {
			var encodedKey = encodeURIComponent(property);
			var encodedValue = encodeURIComponent(details[property]);
			formBody.push(encodedKey + "=" + encodedValue);
		}
		formBody = formBody.join("&");

		fetch('https://' + process.env.REACT_APP_COGNITO_DOMAIN + '/oauth2/token', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			body: formBody
		}).then((response) => response.json())
			.then((responseData) => {
				setResponseToCookies(responseData);
			}).catch(err => {
				console.log(err);
			})
	};

	window.iirisLogoutHandler = async () => {
		//Clear Tracking UserID
		if (window.IIRISTracker) {
			window.IIRISTracker.clearTrackingUID();
		}
		setUser(null);
		setIsLoggedInUser(false);
		setIsOpen(false);
		localStorage.removeItem('CheckLocalUser');
		localStorage.removeItem('auth_flow');
		localStorage.removeItem('iiris_ust');
		await revokeIRISToken();
		clearIRISCookies();
		if (window.irisLogoutCallback) {
			const logoutObj = { "success": true };
			window.irisLogoutCallback(logoutObj);
		}
		document.cookie = 'IDENTITY_LOCALE=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=' + window.location.hostname;
	}

	window.handleMessageEvent = e => {
		window.removeEventListener("message", window.handleMessageEvent, false);
		try {
			const { code, redirect_url } = e.data;
			if (code) {
				setUser(null);
				if (redirect_url) {
					iirisSocialAuthHandler(code, redirect_url);
				} else {
					iirisLinkedinAuthHandler(code);
				}
			}
		} catch {
			return;
		}
	};

	useEffect(() => {
		updateUser();
	}, []);

	return (
		<div>
			<div style={{ display: 'none' }}>
				{identityConfig.show_registration &&
					<>
						<input
							id='irisRegisterBtn'
							type='button'
							value='Register'
							onClick={register}
						/>
						<input id='irisLoginBtn' type='button' value='LogIn' onClick={login} />
					</>
				}
				<input
					id='irisLogoutBtn'
					type='button'
					value='LogOut'
					onClick={logout}
				/>
				<input
					id='irisReloadBtn'
					type='button'
					value='reload'
					onClick={reload}
				/>
			</div>
			{isOpen && (
				identityConfig.is_modal ? (
					<Modal
						identityConfig={identityConfig}
						content={
							<WidgetType
								isLoading={isLoading}
								formType={formType}
								setFormType={setFormType}
								identityConfig={identityConfig}
								forgetPasswordScreenConfig={forgetPasswordScreenConfig}
								setForgetPasswordScreenConfig={setForgetPasswordScreenConfig}
								user={user}
								isLoggedInUser={isLoggedInUser}
							/>}
						handleClose={identityConfig.toggleModal}
					/>
				) : (
					<div className='iiris-container-body'>
						<div className='iiris-container'>
							<WidgetType
								isLoading={isLoading}
								formType={formType}
								setFormType={setFormType}
								identityConfig={identityConfig}
								forgetPasswordScreenConfig={forgetPasswordScreenConfig}
								setForgetPasswordScreenConfig={setForgetPasswordScreenConfig}
								user={user}
								isLoggedInUser={isLoggedInUser}
							/>
						</div>
					</div>
				)
			)}
		</div>
	)
};

const loadWidgetByConfig = configData => {
	document.cookie = "IRIS_APPID=" + configData.id + "; path=/; samesite=strict; secure;domain=" + window.location.hostname;
	const siteName = configData.name;
	const domain = configData.domain;
	const widgetConfig = configData.widget_configs.find((obj) => {
		return obj.type === 'identity';
	});
	const pages = widgetConfig.pages;
	const properties = widgetConfig.properties;
	const skin = properties.style;
	const proxyURL = 'https://' + apiDomain;
	const audienceDomainURL = 'https://' + audienceConsentDomain;
	const redirect_signin_url = process.env.REACT_APP_REDIRECT_SIGNIN;
	const redirect_signout_url = process.env.REACT_APP_REDIRECT_SIGNOUT;
	const clientId = process.env.REACT_APP_COGNITO_WEBCLIENT_ID;
	const brand = properties.brand;
	const division = properties.division;
	const linkedin_redirect_url = process.env.LINKEDIN_REDIRECT_URL;
	const IS_LOCALHOST =
		window.location.hostname === 'localhost' ||
			window.location.hostname === '127.0.0.1' ||
			window.location.hostname.indexOf('.local') >= 0
			? true
			: false;

	const props = {
		identityConfig: {
			id: configData.id ? configData.id : appId,
			siteName,
			language,
			showShareIcons: "",
			configureRoles: false,
			siteType: configData.type ? configData.type : '',
			siteSubType: configData.sub_type ? configData.sub_type : '',
			newsLetterName: properties.newsletter_name
				? properties.newsletter_name
				: '',
			publicAssetsUrl: properties.public_assets_url,
			skin,
			proxyURL,
			audienceDomainURL,
			clientId,
			redirect_signin_url,
			redirect_signout_url,
			brand,
			division,
			check_user: '',
			crud_user: '',
			integration_type: 'javascript',
			hide_social: properties.hide_social ? properties.hide_social : [],
			domain: IS_LOCALHOST ? document.location.hostname : domain,
			captcha: !window.location.host.includes('localhost') && properties.captcha ? properties.captcha : false,
			captcha_type: properties.captcha_type ? properties.captcha_type : 'hcaptcha',
			email_case_insensitive: true,
			welcome_email_template: properties.welcome_email_template ? properties.welcome_email_template : "iiris",
			welcome_email_sender_id: properties.welcome_email_sender_id ? properties.welcome_email_sender_id : "",
			welcome_email_source_arn: properties.welcome_email_source_arn ? properties.welcome_email_source_arn : "",
			welcome_email_return_path_arn: properties.welcome_email_return_path_arn ? properties.welcome_email_return_path_arn : "",
			linkedin_redirect_url,
			pages,
			is_modal: isModal,
			show_progressive_profile: showProgressiveProfile,
			show_registration: showRegistration,
			show_social_login_names: showSocialLoginNames,
			default_social_option: defaultOption ? defaultOption : '',
			passwordless_login: passwordLessLogin,
			cognito_add_business_email_attr: cognitoAddBusinessEmail,
			email_login_option: properties.email_login_option ? properties.email_login_option : "all",
			persist_session: properties.hasOwnProperty('persist_session') ? properties.persist_session : true,
			environment_mode: properties.hasOwnProperty('environment_mode') ? properties.environment_mode : process.env.ENV_MODE,
			// isAutomated: isAutomated
		},
	}

	i18n
		.use(initReactI18next) // passes i18n down to react-i18next
		.init({
			resources,
			lng: language,
			fallbackLng: "en",
		});

	ReactDOM.render(React.createElement(IrisIdentity, props), identityWidget)
};

const identityWidget = document.getElementById('iris-identity');
const isModal = !(identityWidget.getAttribute('ismodal') === "false");
const showProgressiveProfile = !(identityWidget.getAttribute('showprogressiveprofile') === "false");
const showRegistration = !(identityWidget.getAttribute('showregistration') === "false");
const showSocialLoginNames = !(identityWidget.getAttribute('showSocialLoginNames') === "false");
const defaultOption = identityWidget.getAttribute('defaultOption');
const passwordLessLogin = identityWidget.getAttribute('logintype') !== "password";
const cognitoAddBusinessEmail = identityWidget.getAttribute('cognitoAddBusinessEmail') && identityWidget.getAttribute('cognitoAddBusinessEmail') === "true";
const language = identityWidget.getAttribute("locale") || "en";
const apiDomain = process.env.API_DOMAIN;
const audienceConsentDomain = process.env.AUDIENCE_CONSENT_API_DOMAIN;

// Setting up the  cookie for locale.
document.cookie = "IDENTITY_LOCALE=" + language + "; path=/; samesite=strict; secure;domain=" + window.location.hostname;
// const isAutomated = identityWidget.getAttribute('isAutomated');
if (identityWidget.getAttribute('config') !== null) {
	const encrypted_config = identityWidget.getAttribute('config');
	const bytes = CryptoJS.AES.decrypt(encrypted_config, process.env.REACT_SECRET_KEY);
	const decryptedConfigData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
	loadWidgetByConfig(decryptedConfigData);
} else {
	const appId = identityWidget.getAttribute('appId') !== null ? identityWidget.getAttribute('appId') : '';
	SignerService.signedAPIRequest(
		'execute-api',
		'GET',
		'https://' + apiDomain + '/v2/management/public/config/item/' + appId,
		''
	).then(dynamoConfigData => {
		loadWidgetByConfig(dynamoConfigData.data.config);
	}).catch(e => {
		SignerService.signedAPIRequest(
			'execute-api',
			'GET',
			'https://' + apiDomain + '/v2/management/public/app/' + appId,
			''
		).then(configData => {
			loadWidgetByConfig(configData.data[0]);
		});
	});
}

export { IrisIdentity }
