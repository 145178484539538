import axios from "axios";
import { SignerService } from '../service/SignerService';

export const CognitoService = {
    getUserAttributes: function () {
        const apiDomain = process.env.API_DOMAIN;
        const url = `https://${apiDomain}/v2.1/authorization/passport/cognitouser`;
        const headers = {
            "Content-Type": "application/json",
            "Authorization": document.cookie.split('; ').find(row => row.startsWith('IRIS_AUTH=')).split('=')[1]
        };
        const serviceResponse = axios.get(url, { headers }).then(function (response) {
            let userAttributes = {};
            const userData = response.data.data.UserAttributes;
            userData.forEach(function (item) {
                userAttributes[item.Name] = item.Value;
            });
            return userAttributes;
        }).catch(function (error) {
            console.log(error);
        });
        return serviceResponse;
    },

    updateUserAttributes: function (attributes) {
        const apiDomain = process.env.API_DOMAIN;
        const url = `https://${apiDomain}/v2.1/authorization/passport/update/cognitouser`;
        const payload = { 
            "user_attributes": [attributes] 
        };
        const headers = {
            "Content-Type": "application/json",
            "Authorization": document.cookie.split('; ').find(row => row.startsWith('IRIS_AUTH=')).split('=')[1]
        };
        const serviceResponse = axios.put(url, payload, { headers }).then(function (response) {
            return response;
        }).catch(function (error) {
            console.log(error);
        });
        return serviceResponse;
    }, 

    updateTokens: function(clientID, isLinkedInLogin, email) {
        const apiDomain = process.env.API_DOMAIN;
        const payload = {};
        if (isLinkedInLogin) {
            payload["linkedin_email"] = email;
        } else {
            payload["client_id"] = clientID;
            payload["refresh_token"] = document.cookie.split('; ').find(row => row.startsWith('IRIS_HASH=')).split('=')[1];
        }
        try {
            const url = `https://${apiDomain}/v2.1/authorization/public/refreshAuthToken${isLinkedInLogin ? `?m2m=true` : ''}`;
            const result = SignerService.signedAPIRequest(
              "execute-api",
              "POST",
              url,
              payload
            ).then(function (response) {
                document.cookie = "IRIS_AUTH=" + response.access_token + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
                //document.cookie = "IRIS_IDT=" + response.id_token + "; path=/; samesite=strict; secure;domain=" + window.location.hostname;
                return response;
            });
            return result;
        } catch (error) {
            return error.response;
        }

    }
};