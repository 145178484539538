import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from "react-i18next";

import { CheckLocalUser } from '../CheckLocalUser';
import { CustomSelectComponent } from '../../common/CustomSelectComponent';
import { SignerService } from '../../service/SignerService';
import { IRISService } from '../../service/IRISService';
import { LoadingContent } from '../../common/LoadingContent';

import classes from './ProfileForm.module.css';

var fieldInfo = {};

export const ProfileForm = props => {
	const { identityConfig } = props;
	const { t } = useTranslation();
	const givenNameInputRef = useRef();
	const familyNameInputRef = useRef();
	const [showEmailSignInInfo, setShowEmailSignInInfo] = useState(true);
	const [showToggleButton, setShowToggleButton] = useState(true);
	const [checkLocalUser, setCheckLocalUser] = useState(false);
	const [userObj, setUserObj] = useState(null);
	const [error, setError] = useState('');
	const [countryType, setCountryType] = useState('');
	const [selectedCountry, setSelectedCountry] = useState('');
	const [selectedCountryId, setSelectedCountryId] = useState('');
	const [selectedState, setSelectedState] = useState('');
	const [selectedStateId, setSelectedStateId] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [showPasswordRules, setshowPasswordRules] = useState(false);
	const [errors, setErrors] = useState([]);
	const [isTypeAConsentSelected, setTypeAConsentSelected] = useState(false);
	const [isTypeDConsentSelected, setTypeDConsentSelected] = useState(false);
	const [countryData, setCountryData] = useState([]);
	const [stateData, setStateData] = useState([]);

	const privacy_policy_text = t('privacy_policy_text');
	const [FP_text, setFPConsentText] = useState('');
	const [fp_consent, setFPConsent] = useState(null);
	const [audienceProfile, setAudienceProfile] = useState(null);
	const authToken = document.cookie.split('; ').find(row => row.startsWith('IRIS_AUTH=')).split('=')[1];


	const getCountryData = useCallback(() => {
		SignerService.signedAPIRequest(
			'execute-api',
			'GET',
			identityConfig.proxyURL + `/v2/taxonomy/public/countries?locale=${identityConfig.language}`,
			''
		).then(response => {
			setCountryData(response.data);
			const profile = JSON.parse(localStorage.getItem("audience_profile"))
			set_country_state(response.data,profile)
			set_consent()
		});
	}, [identityConfig]);

	useEffect(() => {
		localStorage.setItem('widget_screen', 'profile_form');
		const profile = JSON.parse(localStorage.getItem("audience_profile"))
		setAudienceProfile(profile);
		givenNameInputRef.current.value = profile.first_name;
	    familyNameInputRef.current.value = profile.last_name;
		getCountryData();
	}, [getCountryData]);

	function hasError(key) {
		return errors.indexOf(key) !== -1;
	}

	const set_consent = () => {
		if(localStorage.getItem("audience_consent")){
			const consent = JSON.parse(localStorage.getItem("audience_consent"))
			const fp_consent = consent.consent.find(obj => obj.type === "1P");
			setFPConsent(fp_consent.flag)
			if(consent.country_group){
				switch (consent.country_group.toUpperCase()) {
					case "A":
						setTypeAConsentSelected(true);
						setTypeDConsentSelected(false);
						setFPConsentText(t('FP_consent_text'));
						break;
					case "B":
					case "C":
						setFPConsentText(t('tick_here_text'));
						if(fp_consent.flag){
							document.getElementById("typeBCConsentCheckBox").checked = false;
						}else{
							document.getElementById("typeBCConsentCheckBox").checked = true;
						}
						break;
					case "D":
						setFPConsentText(t('by_continuing'));
						setTypeDConsentSelected(true);
						setTypeAConsentSelected(false);
						setFPConsent(true);
						if(fp_consent.flag){
							document.getElementById("typeDConsentCheckBox").checked = true;
						}else{
							document.getElementById("typeDConsentCheckBox").checked = false;
						}
						break;
					default:
						break;
				}
			}
			
		}
	}
	const set_country_state = (country_data, audience_profile) => {
		if (audience_profile.country) {
		  if(audience_profile.country === 'US'){
			setSelectedCountry("United States")
			setSelectedCountryId("US")
			const us = country_data.find(item => item.id === 'US');
			setStateData(us.states)
			setCountryType(us.group)
			if (audience_profile.state) {
			  if(us.states.some(item => item.id.includes(audience_profile.state))){
				const state = us.states.find(obj => obj.id === audience_profile.state);
				setSelectedState(state.name)
				setSelectedStateId(audience_profile.state)
			  }
			}
		  }else if(audience_profile.country === 'CA'){
			setSelectedCountry("Canada")
			setSelectedCountryId("CA")
			const ca = country_data.find(item => item.id === 'CA');
			setStateData(ca.states)
			setCountryType(ca.group)
			if (audience_profile.state) {
			  if(ca.states.some(item => item.id.includes(audience_profile.state))){
				const state = ca.states.find(obj => obj.id === audience_profile.state);
				setSelectedState(state.name)
				setSelectedStateId(audience_profile.state)
			  }
			}
		  }else if(country_data.some(item => item.id.includes(audience_profile.country))){
			const c = country_data.find(item => item.id === audience_profile.country);
			setSelectedCountry(c.name)
			setSelectedCountryId(c.id)
			setCountryType(c.group)
		  }
		}
	
	  }

	// Select Event Functionality
	const OnCountrySelectChange = (...e) => {
		if (e.length !== 0) {
			setSelectedCountry(e[1].name);
			setSelectedCountryId(e[1].id);
			setStateData(e[1].states);
			fieldInfo["country"] = e[1].id;
			fieldInfo["country_name"] = e[1].name;
			if (e[1].states.length === 0) {
				setSelectedState('');
				setSelectedStateId('');
			}
			var countryTypeVal = e[1].group;
			setCountryType(countryTypeVal);
		} else {
			setSelectedCountry('');
			setCountryType('');
		}
	}

	const OnStateSelectChange = (...e) => {
		if (e.length !== 0) {
			setSelectedStateId(e[1].id);
			setSelectedState(e[1].name);
			fieldInfo["state"] = e[1].id;
			fieldInfo["state_name"] = e[1].name;
		} else {
			setSelectedState('');
			setSelectedStateId('');
		}
	}

	const handleConsent = async(accessToken, userObj) => {
		try {
			const consentPayload = {
				iiris_id: userObj.id,
				product_id: identityConfig.id,
				product_type: identityConfig.siteType,
				product_sub_type: identityConfig.siteSubType,
				version: '2.x',
				date: new Date(),
				country: userObj.country,
				country_group: userObj.countryType ? userObj.countryType : '',
				consent: [
					{
						type: 'PP',
						sub_type: '',
						text: privacy_policy_text,
						link: 'https://www.informa.com/privacy-policy/',
						flag: true,
					},
				],
			};
			if (fp_consent !== null) {
				consentPayload.consent.push({
					type: '1P',
					sub_type: 'non-purchase',
					text: FP_text,
					link: '',
					flag: fp_consent,
				});
			} else if (fp_consent == null && (countryType === 'B' || countryType === 'C')) {
				consentPayload.consent.push({
					type: '1P',
					sub_type: 'non-purchase',
					text: t('tick_here_text'),
					link: '',
					flag: true,
				});
			}
			IRISService.makePostRequest(
				identityConfig.audienceDomainURL + '/v3/consent/data',
				consentPayload,
				accessToken
			);
		} catch (e) {
			console.log(e)
		}
	}

	const validateForm = () => {
		const enteredGivenName = givenNameInputRef.current.value;
		const enteredFamilyName = familyNameInputRef.current.value;
		let isValid = true;
		const errs = [];
		if (enteredGivenName === '') {
			isValid = false;
			errs.push('givenNameInputRef');
		}

		if (enteredFamilyName === '') {
			isValid = false;
			errs.push('familyNameInputRef');
		}

		if (selectedCountry === '') {
			isValid = false;
			errs.push('country');
		}

		if (selectedState === '' && stateData.length > 0) {
			isValid = false;
			errs.push('state');
		}

		switch (countryType) {
			case "A":
				if (!isTypeAConsentSelected) {
					isValid = false;
					errs.push("Aterms");
				}
				break;
			case "D":
				if (!isTypeDConsentSelected) {
					isValid = false;
					errs.push("Cterms");
				}
				break;
			default:
				break;
		}

		if (!isValid) {
			setError('');
		}
		setErrors(errs);
		return isValid;
	}

	const update_profile = async (event) => {
		event.preventDefault();
		if (!validateForm()) {
			return;
		}
		setIsLoading(true);

		let audPayload ={
			first_name: givenNameInputRef.current.value,
			last_name: familyNameInputRef.current.value,
			country: selectedCountryId,
			country_name: selectedCountry,
			state: selectedStateId,
			state_name: selectedState
		}
		IRISService.makePutRequest(
			`${identityConfig.audienceDomainURL}/v3/audience/profile/update/${audienceProfile.id}/${identityConfig.id}`,
			audPayload,
			authToken
		).then(audResponse => {
			if (audResponse.status === 'success') {
				const responseData = audResponse.data[0];
				const user = {
					id: responseData.id,
					email: responseData.business_email,
					given_name: responseData.first_name,
					family_name: responseData.last_name,
					flow: "emailSignup",
					product_ids: responseData.product_ids,
					country: responseData.country,
					country_id: selectedCountryId,
					country_name: responseData.country_name,
					countryType: countryType,
					state: responseData.state,
					state_name: responseData.state_name,
					company: responseData.company,
					job_title: responseData.job_title,
					is_social_login: responseData.is_social_login,
					business_type: responseData.business_type,
					job_function: responseData.job_function,
					social_accounts: responseData.social_accounts,
				};
				handleConsent(authToken, user);
				check_registration(user, responseData, authToken)
			} else if (audResponse.status === 'error') {
				setIsLoading(false);
				setError(audResponse.message);
			}
		}).catch((err) => {
			console.log(err)
			setIsLoading(false);
			setError('Error');
		});

	}

	const check_registration = (user, data, access_token) => {
		IRISService.makeGetRequest(
		  `${identityConfig.audienceDomainURL}/v3/audience/registration/${data.id}/${identityConfig.id}`,
		  access_token
		).then(regResponse => {
		  if (regResponse.status === 'success') {
			triggerLoginCallback(data, access_token);
		  } else if (regResponse.status === 'error') {
			if (!identityConfig.show_progressive_profile) {
			  triggerLoginCallback(data, access_token);
			} else {
			  setUserObj(user);
			  setCheckLocalUser(true);
			  setIsLoading(false);
			}
		  }
		}).catch((err) => {
		  if (!identityConfig.show_progressive_profile) {
			triggerLoginCallback(data, access_token);
		  } else {
			setUserObj(user);
			setCheckLocalUser(true);
			setIsLoading(false);
		  }
		});
	  }

	const handleTypeASiteConsent = (event) => {
		setTypeAConsentSelected(true);
		setTypeDConsentSelected(false);
		setFPConsentText(t('FP_consent_text'));
		if (event.target.value === 'YES') {
			setFPConsent(true);
		} else if (event.target.value === 'NO') {
			setFPConsent(false);
		}
	}

	const handleTypeBSiteConsent = event => {
		setFPConsentText(t('tick_here_text'));
		if (event.target.checked) {
			setFPConsent(false); // If selected means opt-out here
		} else {
			setFPConsent(true);
		}
	}

	// const handleTypeDConsent = (event, autoConsent) => {
	// 	setFPConsentText(t('by_continuing'));
	// 	if (event.target.checked || autoConsent) {
	// 		setTypeDConsentSelected(true);
	// 		setTypeAConsentSelected(false);
	// 		setFPConsent(true);
	// 	} else {
	// 		setTypeDConsentSelected(false);
	// 		setTypeAConsentSelected(false);
	// 		setFPConsent(false);
	// 	}
	// }

	useEffect(() => {
		if (countryType === 'D') {
			setTypeDConsentSelected(true);
			setTypeAConsentSelected(false);
			setFPConsent(true);
		} 
	},[countryType])

	const triggerLoginCallback = (data, token) => {
		if (identityConfig.persist_session === false) {
		  clearIRISCookies();
		}
		
		if (window.irisLoginCallback) {
		  let callbackUrl = ''
		  if (!identityConfig.show_progressive_profile){
			  callbackUrl =  `${identityConfig.audienceDomainURL}/v3/audience/profile/${data.id}`
		  }else{
			  callbackUrl = `${identityConfig.audienceDomainURL}/v3/audience/profile/${data.id}/${identityConfig.id}`
		  }
		  IRISService.makeGetRequest(
			callbackUrl,
			token
		  ).then(res => {
			if (res.status === 'success') {
			  const loginCallBackObj = { "success": true, "user": res.data[0], "token": token };
			  window.irisLoginCallback(loginCallBackObj);
			} else if (res.status === 'error') {
			  const loginCallBackObj = { "success": false, "user": null, "token": null };
			  window.irisLoginCallback(loginCallBackObj);
			}
			localStorage.setItem("CheckLocalUser", "NO");
			if (document.getElementById("iiris-modal-close-btn")) {
				document.getElementById("iiris-modal-close-btn").click();
			}
		  }).catch((err) => {
			const loginCallBackObj = { "success": false, "user": null, "token": null };
			window.irisLoginCallback(loginCallBackObj);
			localStorage.setItem("CheckLocalUser", "NO");
			if (document.getElementById("iiris-modal-close-btn")) {
				document.getElementById("iiris-modal-close-btn").click();
			}
		  });
		}
	  }

	return (
		<div>
			{isLoading && <LoadingContent />}
			{checkLocalUser && !isLoading && (
				<CheckLocalUser userObj={userObj} identityConfig={identityConfig} />
			)}
			{!checkLocalUser && !isLoading && (
				<div className={classes['iiris-registration-form']}>
					<form method='post' novalidate autoComplete='off'>
						<h4 className='iiris-text-center'>{props.identityConfig.siteName}</h4>
						{identityConfig.hide_social.length < 3 ?
							<div className={classes['iiris-hint-text']}>
								To make the most of our new website, please update your registration details.
							</div> : null}

						<div style={{ display: showEmailSignInInfo ? 'block' : 'none' }}>
							{identityConfig.hide_social.length < 3 ?
								<div className={`${classes['iiris-or-seperator']} iiris-or-seperator`}>
								</div>
								:
								null
							}

							<div
								className={classes['iiris-error-space']}
								style={{ display: error.length > 0 ? 'block' : 'none' }}
							>
								{error}
							</div>
							{identityConfig.default_social_option ?
								<h4 style={{ color: '#0593ff', margin: '15px 0' }}>Account details</h4> : ''
							}
							<div className={classes['iiris-form-container-custom']}>
								<div className={classes['iiris-form-group']}>
									<label className={classes['iiris-form-label']}>{t('first_name')}</label>
									<label style={{ color: 'red', marginLeft: '3px' }}>*</label>
									<div className='iiris-input-group'>
										<input
											type='text'
											className='iiris-form-control'
											name='given_name'
											required='required'
											maxlength='120'
											ref={givenNameInputRef}
										/>
									</div>
									<div
										className={
											classes[
											hasError('givenNameInputRef') ? 'iiris-error-space' : 'iiris-hidden'
											]
										}
									>
										{t('please_first')}
									</div>
								</div>
								<div className={classes['iiris-form-group']}>
									<label className={classes['iiris-form-label']}>{t('last_name')}</label>
									<label style={{ color: 'red', marginLeft: '3px' }}>*</label>
									<div className='iiris-input-group'>
										<input
											type='text'
											className='iiris-form-control'
											name='family_name'
											required='required'
											maxlength='120'
											ref={familyNameInputRef}
										/>
									</div>
									<div
										className={
											classes[
											hasError('familyNameInputRef')
												? 'iiris-error-space'
												: 'iiris-hidden'
											]
										}
									>
										{t('please_last')}
									</div>
								</div>
							</div>

							<div className={classes['iiris-form-container-custom']}>
								<div className={classes['iiris-form-group']}>
									<label className={classes['iiris-form-label']}>{t('country')}</label>
									<label style={{ color: 'red', marginLeft: '3px' }}>*</label>
									<div>
										<CustomSelectComponent
											isDisabled={false}
											text={t('select_country')}
											data={countryData}
											OnChange={OnCountrySelectChange}
											isSearchable={true}
											label='name'
											value='name'
											position='bottom'
											customPosition={true}
											selectedValue={selectedCountry}
										/>
									</div>

									<div
										className={
											classes[hasError('country') ? 'iiris-error-space' : 'iiris-hidden']
										}
									>
										{t('please_country')}
									</div>
								</div>

								<div className={classes['iiris-form-group']}>
									<label className={classes['iiris-form-label']}>
										{t('state')}
									</label>
									{(selectedCountryId === "US" ||
										selectedCountryId === "CA" ||
										selectedCountryId === "BR") && (
											<label style={{ color: "red", marginLeft: "3px" }}>*</label>)}
									<div>
										<CustomSelectComponent
											isDisabled={stateData.length === 0 ? true : false}
											text={
												stateData.length === 0
													? t('not_applicable')
													: t('select_state')
											}
											data={stateData}
											OnChange={OnStateSelectChange}
											isSearchable={true}
											label='name'
											value='name'
											position='bottom'
											customPosition={true}
											selectedValue={selectedState}
										/>
									</div>
									<div
										className={
											classes[hasError('state') ? 'iiris-error-space' : 'iiris-hidden']
										}
									>
										{t('please_state')}
									</div>
								</div>
							</div>

							{countryType === 'A' && (
								<div>
									<div className={classes['iiris-formbox']}>
										<div className='iiris-input-group'>
											<p>
												{t('news_promotions')}
											</p>
										</div>
										<div className={classes['iiris-form-group']}>
											<div className='iiris-form-check iiris-form-check-inline'>
												<input
													name='inlineRadioOptions1'
													className='iiris-form-check-input'
													type='radio'
													id='inlineRadio11'
													value='YES'
													onClick={handleTypeASiteConsent}
													checked= {fp_consent}
												/>
												<label className="iiris-form-check-label" for="inlineRadio11">{t('yes')}</label>
											</div>
											<div className='iiris-form-check iiris-form-check-inline'>
												<input
													name='inlineRadioOptions1'
													className='iiris-form-check-input'
													type='radio'
													id='inlineRadio12'
													value='NO'
													onClick={handleTypeASiteConsent}
													checked= {!fp_consent}
												/>
												<label className="iiris-form-check-label" for="inlineRadio12">{t('no')}</label>
											</div>
										</div>
									</div>
									<div
										className={
											classes[hasError('Aterms') ? 'iiris-error-space' : 'iiris-hidden']
										}
									>
										{t('please_consent')}
									</div>
								</div>
							)}

							{(countryType === 'B' || countryType === 'C') && (
								<div>
									<div className={classes['iiris-formbox']}>
										<label className={classes['iiris-checkbox-container']}>
											<input
												type='checkbox'
												id='typeBCConsentCheckBox'
												className={classes['iiris-checkbox-style']}
												onClick={handleTypeBSiteConsent}
											/>
											<span className={classes['iiris-checkmark']}></span>
											<span>
												{t('tick_here')}
											</span>
											<strong> {t('do_not')}</strong>
											<span> {t('want_to_receive')}</span>
										</label>
									</div>
								</div>
							)}

							{countryType === 'D' && (
								<div>
									<div className={classes['iiris-formbox']}>
										<div className='iiris-input-group'>
											<p>
												{t('by_continuing')}
											</p>
										</div>
									</div>
									{/* <div className={classes['iiris-formbox']}>
										<label className={classes['iiris-checkbox-container']}>
											<input
												type='checkbox'
												id='typeDConsentCheckBox'
												onClick={handleTypeDConsent}
												className={classes['iiris-checkbox-style']}
											/>
											<span className={classes['iiris-checkmark']}></span>
											<span>
												{t('i_agree')} &nbsp;
												<a
													style={{
														textDecoration: 'underline',
														color: '#0593FF',
													}}
													href='https://www.informa.com/privacy-policy/'
													target='_blank'
													rel='noreferrer'
												>
													<b>{t('privacy_policy')}</b>
												</a>
											</span>
										</label>
									</div>
									<div
										className={
											classes[hasError("Cterms") ? 'iiris-error-space' : 'iiris-hidden']
										}
									>
										{t('please_privacy_policy')}
									</div> */}
								</div>
							)}

							{countryType !== '' && (
								<div style={{
									width: '100%',
									marginBottom: '0.8rem',
								}}>
									<div className={classes['iiris-formbox']}>
										<span>
											{t('opt_out')}{' '}
											<a
												style={{ textDecoration: 'underline', color: '#0593FF' }}
												href='https://www.informa.com/privacy-policy/'
												target='_blank'
												rel='noreferrer'
											>
												<b>{t('privacy_policy')}</b>
											</a>
										</span>
									</div>
								</div>)}

							<div style={{
								width: '100%',
								marginBottom: '0.8rem',
							}}>
								<div className={classes['iiris-formbox-terms-condn']}>
									<span>
										{t('terms_and_condition')}
									</span>
								</div>
							</div>

							<div className="iiris-btn-center">
								<button
									onClick={update_profile}
									type='button'
									className={`${classes['iiris-social-btn']} iiris-btn iiris-btn-block `}
									style={{
										color: identityConfig.skin.skin_color,
										background: identityConfig.skin.skin_background,
									}}
								>
									Update
								</button>
							</div>
						</div>
					</form>
				</div>
			)}
		</div>
	)
};
