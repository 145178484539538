import React, { useEffect } from "react";

import { LoadingContent } from "../../common/LoadingContent";

export const RedirectToParent = props => {
  const { identityConfig } = props;

  useEffect(() => {
    if (localStorage.getItem("iiris_ust")) {
      const timeData = {};
      timeData.cat = 'IRISIdentityEvent';
      timeData.lbl = 'Capture Time';
      timeData.act = 'loginTime';
      timeData.prop = 'loginTime';
      const startTime = Date.parse(localStorage.getItem("iiris_ust"));
      const endTime = new Date();
      timeData.val =  (endTime - startTime).toFixed(2);
      if (window.IIRISTracker && window.IIRISTracker.trackStructEvt) {
        window.IIRISTracker.trackStructEvt(timeData);
      }
    }
    window.location.reload();
  }, []);
  return <LoadingContent />;
};