import React, { useState, useEffect } from "react";

import { RedirectToParent } from "./RedirectToParent";
import { LoadingContent } from "../common/LoadingContent";
import { WorkEmailCheck } from "./WorkEmailCheck";

export const CheckLocalUser = props => {
  const { identityConfig } = props;
  const [isLocalUser, setIsLocalUser] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const mail = { email: props.userObj.email };
    {
      try {
        if (localStorage.getItem("CheckLocalUser") &&
          localStorage.getItem("CheckLocalUser") === "NO") {
          setIsLocalUser(true);
          window.location.reload();
        } else {
          setIsLocalUser(false);
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }

  }, []);

  return (
    <div>
      {isLoading && <LoadingContent />}
      {!isLoading && !isLocalUser && (
        <WorkEmailCheck
          userObj={props.userObj}
          identityConfig={identityConfig}
        />
      )}
      {!isLoading && isLocalUser  && (
        <RedirectToParent userObj={props.userObj} identityConfig={identityConfig}/>
      )}
    </div>
  );
};
