import React, { useState, useRef, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import isEmail from 'validator/lib/isEmail';
import OTPInput from "otp-input-react";
import { v4 as uuidv4 } from 'uuid';

import { SocialProvider } from "../SocialProvider";
import { CheckLocalUser } from "../CheckLocalUser";
import { LoadingContent } from "../../common/LoadingContent";
import { IRISService  } from '../../service/IRISService';
import { CognitoService } from "../../service/CognitoService";
import { trackBusinessEvt } from "../../common/CustomEvents"
import { SignerService } from '../../service/SignerService';
import { sha256 } from '../../common/IdentityUtils';

import classes from "./SignIn.module.css";

export const SignIn = props => {
  const { identityConfig } = props;
  const { t } = useTranslation();

  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const [showEmailSignInInfo, setShowEmailSignInInfo] = useState(true);
  const [error, setError] = useState("");
  const [checkLocalUser, setCheckLocalUser] = useState(false);
  const [userObj, setUserObj] = useState(null);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showScreen, setShowScreen] = useState("ENTER_EMAIL");
  const [cognitoUser, setCognitoUser] = useState({});
  const [OTP, setOTP] = useState("");
  const [enteredEmail, setEnteredEmail] = useState("");
  const [invalidOtp, setInvalidOtp] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [countryData, setCountryData] = useState([]);
  const [screenType, setScreenType] = identityConfig.email_login_option === "otp" ? useState("signInWithCode") : useState('signInWithPassword')

  const redirectToSignUp = () => {
    props.setFormType("signup");
    try {
      //Tracker Initial Event for Sign Up
      const transactionID = uuidv4();
      window.localStorage.setItem("regTransactionId", transactionID);
      const evt = {
        entityId: 'NA',
        transactionId: transactionID,
        formId: 'NA',
        status: 'init',
        type: 'register',
        ts: new Date().toISOString()
      };
      const asset = {
        id: 'NA',
        name: window.location.host,
        type: 'form',
        size: 0,
        url: window.location.href,
        created: new Date().toISOString(),
        lastUpdated: new Date().toISOString(),
        tags: 'Initiated',
        metadata: ''
      };
      trackBusinessEvt(evt, asset);
    }
    catch (error) {
      console.log(error);
    }
    try {
      const evtObj = {};
      evtObj.cat = 'IRISIdentityEvent';
      evtObj.lbl = 'Page Visited';
      evtObj.act = 'register';
      evtObj.prop = 'emailRegister';
      evtObj.val = 1.00;
      if (window.IIRISTracker && window.IIRISTracker.trackStructEvt) {
        window.IIRISTracker.trackStructEvt(evtObj);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const showSignInWithEmailFields = () => {
    setError("");
    setShowEmailSignInInfo(true);
  }

  const clearObjError = obj => {
    obj.style.borderColor = "";
    setError("");
  }

  function hasError(key) {
		return errors.indexOf(key) !== -1;
	}

  const onSendSecureCode = event => {
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      sendSecureCode(event);
    }
  }

  const timeout = () => {
    setOTP('');
    setInvalidOtp(3);
    setError(t('wrong_otp'));
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (minutes + seconds > 0) {
        if (JSON.stringify(cognitoUser) !== "{}") {
          // check for the cognito user.
          if (seconds > 0) {
            setSeconds(seconds - 1);
          }
          if (seconds === 0) {
            if (minutes === 0) {
              timeout();
            } else {
              setMinutes(minutes - 1);
              setSeconds(59);
            }
          }
        }
      }
    }, 1000);

    if (
      seconds === 0 &&
      minutes === 0 &&
      cognitoUser &&
      JSON.stringify(cognitoUser) !== "{}"
    ) {
      timeout();
    }
    return () => {
      clearTimeout(timer)
    }
  }, [seconds, cognitoUser]);

  const setTimer = () => {
    setMinutes(2); // setting the timer to 2mins 0 sec.
    setSeconds(0);
  };

  const clearTimer = () => {
    setMinutes(0);
    setSeconds(0);
  };

  const sendSecureCode = async(event) => {
    clearTimer();
    event.preventDefault();
    if (!validateEnteredEmail()) {
      return;
    }
    const email = emailInputRef.current.value.trim();
    const username = identityConfig.email_case_insensitive ? email.toLowerCase() : email;
    setIsLoading(true);
    // Send secured login code to user's email
    setEnteredEmail(username);
    setTimer();
    const payload = {
      "auth_flow": "CUSTOM_AUTH",
      "username": username
    }
    const url = identityConfig.proxyURL + '/v2.1/authorization/public/passport/initiateauth';
    SignerService.signedAPIRequest(
      "execute-api",
      "POST",
      url,
      payload
    ).then(response => {
      if (response && response.status === 'success') {
        setCognitoUser(response.data);
        setIsLoading(false);
        setShowScreen("ENTER_CODE");
      } else if (response && response.status === 'error') {
        clearTimer();
        setIsLoading(false);
        const code = response.message;
        switch (code) {
          case 'PasswordResetRequiredException':
            props.setForgetPasswordScreenConfig({
              type: "ENTER_CODE",
              email: username
            });
            props.setFormType("forgetpassword");
            break;
          default:
            setError(t('incorrect_email_entered'));
        }
      }
    });
  }

  const resendCode = async() => {
    clearTimer();
    setError('');
    setOTP('');
    setInvalidOtp(0);
    setIsLoading(true);
    setTimer();
    const payload = {
      "auth_flow": "CUSTOM_AUTH",
      "username": cognitoUser.ChallengeParameters.email
    };
    const url = identityConfig.proxyURL + '/v2.1/authorization/public/passport/initiateauth';
    SignerService.signedAPIRequest(
      "execute-api",
      "POST",
      url,
      payload
    ).then(response => {
      if (response && response.status === 'success') {
        setCognitoUser(response.data);
        setIsLoading(false);
      }
    });
  }

  const retry = async() => {
    clearTimer();
    setError('');
    setOTP('');
    setInvalidOtp(0);
    setTimer();
    setShowScreen("ENTER_EMAIL")
  }
  const showHidePassword = event => {
    const type = passwordInputRef.current.type === "password" ? "text" : "password";
    passwordInputRef.current.type = type;
    event.target.classList.toggle("bi-eye");
  }

  const redirectToForgetPassword = () => {
    props.setFormType("forgetpassword");
  }

  const validateForm = () => {
    const enteredEmail = emailInputRef.current.value.trim();
    const enteredPassword = passwordInputRef.current.value;
    let isValid = true;
    const errs = [];
    if (enteredEmail === "") {
      isValid = false;
      errs.push("emailInputRef");
    }
    //email validation
    const validEmail = isEmail(enteredEmail);
    if (!validEmail) {
      isValid = false;
      errs.push("emailInputRef");
    }
    if (enteredPassword === "") {
      isValid = false;
      errs.push("passwordInputRef");
    }
    if (!isValid) {
      setError("");
    }
    setErrors(errs);
    return isValid;
  }

  const signInWithPassword = async(event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    setError("");
    setIsLoading(true);
    const email = emailInputRef.current.value.trim();
    const password = passwordInputRef.current.value;
    const username = identityConfig.email_case_insensitive ? email.toLowerCase() : email;

    // Create an AES cipher object with Cipher Block Chaining (CBC) mode
    const cipher = await window.crypto.subtle.importKey(
      'raw',
      new TextEncoder().encode(process.env.REACT_SECRET_KEY),
      { name: 'AES-GCM' },
      false,
      ['encrypt']
    );

    // The initialization vector should be a random value and different for each request
    const iv = new Uint8Array(16);
    window.crypto.getRandomValues(iv);

    // Encrypt the password with the IV and cipher
    const encodedPassword = new TextEncoder().encode(password);
    const encryptedPassword = new Uint8Array(await window.crypto.subtle.encrypt(
      { name: 'AES-GCM', iv: iv },
      cipher,
      encodedPassword
    ));

    // Encode the encrypted password and IV using Base64
    const encodedEncryptedPassword = btoa(String.fromCharCode(...new Uint8Array(encryptedPassword.buffer)));
    const encodedIV = btoa(String.fromCharCode(...new Uint8Array(iv.buffer)));
        
    // send the encoded encrypted password and IV in the POST body to the backend
    const payload = {
      "auth_flow": "CUSTOM_SRP_AUTH",
      "username": username,
      "srp_key": encodedEncryptedPassword,
      "salt": encodedIV
    }

    let user = {};
    const url = identityConfig.proxyURL + '/v2.1/authorization/public/passport/initiateauth';
    SignerService.signedAPIRequest(
      "execute-api",
      "POST",
      url,
      payload
    ).then(response => {
      if (response.status === 'success') {
        document.cookie = "IRIS_AUTH=" + response.data.AuthenticationResult.AccessToken + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
        document.cookie = "IRIS_HASH=" + response.data.AuthenticationResult.RefreshToken + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
        //document.cookie = "IRIS_IDT=" + response.data.AuthenticationResult.IdToken + "; path=/; samesite=strict; secure;domain=" + window.location.hostname;
        document.cookie = "IRIS_APPID=" + identityConfig.id + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
        localStorage.setItem("is_social_login", false);
        let profileinfo_payload = {
          "email" : username,
          "profile_type":"business_email"
        }
        IRISService.makePostRequest(
          `${identityConfig.audienceDomainURL}/v3/audience/profileinfo`,
          profileinfo_payload,
          response.data.AuthenticationResult.AccessToken
        ).then(audResponse => {
          if (audResponse.status === 'success') {
            //setUser(response.data[0]);
            const data = audResponse.data[0];
            user.id = data.id;
            user.email = data.business_email;
            user.given_name = data.first_name;
            user.family_name = data.last_name;
            user.flow = "emailSignin";
            user.country = data.country;
            user.countryType = "";
            user.state = data.state;
            user.company = data.company;
            user.job_title = data.job_title;
            user.is_social_login = data.is_social_login;
            user.business_type = data.business_type;
            user.job_function = data.job_function;
            user.social_accounts = data.social_accounts;
            user.product_ids = data.product_ids ? data.product_ids : [];
            //Set User to Track
            document.cookie = "IRIS_ID=" + user.id + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
            sha256(user.email.toLowerCase()).then((hash) => {
              document.cookie = "IRIS_UHASH=" + hash + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
            });
            if (window.IIRISTracker && window.snowplow) {
              window.snowplow("setUserIdFromCookie", "IRIS_ID");
            }
            try {
              const evtObj = {};
              evtObj.cat = 'IRISIdentityEvent';
              evtObj.lbl = 'Login Mode';
              evtObj.act = 'login';
              evtObj.prop = 'emailLogin';
              evtObj.val = 1.00;
              if (window.IIRISTracker && window.IIRISTracker.trackStructEvt) {
                window.IIRISTracker.trackStructEvt(evtObj);
              }
            } catch (error) {
              console.log(error);
            }
            if (!identityConfig.show_progressive_profile) {
              triggerLoginCallback(data, response.data.AuthenticationResult.AccessToken);
            }
            else if(!checkProfileInfo(data, response.data.AuthenticationResult.AccessToken) && identityConfig.show_progressive_profile){
              localStorage.setItem("audience_profile",JSON.stringify(data))
              setIsLoading(false);
              props.setFormType("profileform");
            }else{
              check_registration(user, data, response.data.AuthenticationResult.AccessToken);
            }
          } else if (audResponse.status === 'error') {
            setIsLoading(false);
            setError("User not found");
          }
        }).catch((err) => {
          setIsLoading(false);
          setError("User not found");
        });
      } else if (response.status === 'error') {
        setIsLoading(false);
        const code = response.message;
        switch (code) {
          case 'UserNotFoundException':
            setError(t('login_user_not_found'));
            break;
          case 'NotAuthorizedException':
            setError(t('login_not_authorized'));
            break;
          case 'PasswordResetRequiredException':
            setError(t('login_password_reset_required'));
            break;
          case 'UserNotConfirmedException':
            setError(t('login_user_not_confirmed'));
            break;
          default:
            setError(t('login_default_error'));
        }
      }
    });
  }

  const check_consent =  async (iris_id, access_token) => {
    await IRISService.makeGetRequest(
      `${identityConfig.audienceDomainURL}/v3/consent/data/iid/${iris_id}/2.x`,
      access_token
    ).then(consentResponse => {
      if (consentResponse.status === 'success') {
        localStorage.setItem("audience_consent",JSON.stringify(consentResponse.data))
      }
    }).catch((err) => {
    });
	}

  const check_registration = (user, data, access_token) => {
    IRISService.makeGetRequest(
      `${identityConfig.audienceDomainURL}/v3/audience/registration/${data.id}/${identityConfig.id}`,
      access_token
    ).then(regResponse => {
      if (regResponse.status === 'success') {
        triggerLoginCallback(data, access_token);
      } else if (regResponse.status === 'error') {
        if (!identityConfig.show_progressive_profile) {
          triggerLoginCallback(data, access_token);
        } else {
          setUserObj(user);
          setCheckLocalUser(true);
          setIsLoading(false);
        }
      }
    }).catch((err) => {
      if (!identityConfig.show_progressive_profile) {
        triggerLoginCallback(data, access_token);
      } else {
        setUserObj(user);
        setCheckLocalUser(true);
        setIsLoading(false);
      }
    });
  }
  const checkProfileInfo = (audience_profile, token)=> {
    let has_fn = audience_profile.first_name ? true : false
    let has_ln = audience_profile.last_name ? true : false
    let has_country_state = check_country(audience_profile)
    check_consent(audience_profile.id, token)
    return has_fn && has_ln && has_country_state
  }

  const getCountryData = useCallback(() => {
		SignerService.signedAPIRequest(
			'execute-api',
			'GET',
			identityConfig.proxyURL + `/v2/taxonomy/public/countries?locale=${identityConfig.language}`,
			''
		).then(response => {
			setCountryData(response.data);
		});
	}, [identityConfig]);

  const check_country = (audience_profile) => {
    if (!audience_profile.country) {
      return false
    }
    if (audience_profile.country) {
      if(audience_profile.country === 'US'){
        if (!audience_profile.state) {
          return false
        }
        if (audience_profile.state) {
          const us = countryData.find(item => item.id === 'US');
          if(us.states.some(item => item.id.includes(audience_profile.state))){
            return true
          }else {
            return false
          }
        }
      }else if(audience_profile.country === 'CA'){
        if (!audience_profile.state) {
          return false
        }
        const ca = countryData.find(item => item.id === 'CA');
        if(ca.states.some(item => item.id.includes(audience_profile.state))){
          return true
        }else {
          return false
        }
      }else if(countryData.some(item => item.id.includes(audience_profile.country))){
        return true
      }else {
        return false
      }
    }

  }

  useEffect(() => {
    localStorage.setItem('widget_screen', 'login');
		getCountryData();
	}, [getCountryData]);

  const login = async(event) => {
    event.preventDefault();
    if (!validateResetForm()) {
      return;
    }
    setError("");
    setIsLoading(true);
    const verificationCode = OTP;
    let user = {};
    const payload = {
      "challenge_name": "CUSTOM_CHALLENGE",
      "username": cognitoUser.ChallengeParameters.email,
      "code": verificationCode,
      "session": cognitoUser.Session
    }
    const url = identityConfig.proxyURL + '/v2.1/authorization/public/passport/respondchallenge';
    SignerService.signedAPIRequest(
      "execute-api",
      "POST",
      url,
      payload
    ).then(response => {
      if (response && response.status === 'success' && response.data.hasOwnProperty('AccessToken')) {
        document.cookie = "IRIS_AUTH=" + response.data.AccessToken + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
        document.cookie = "IRIS_HASH=" + response.data.RefreshToken + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
        //document.cookie = "IRIS_IDT=" + response.data.IdToken + "; path=/; samesite=strict; secure;domain=" + window.location.hostname;
        document.cookie = "IRIS_APPID=" + identityConfig.id + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
        localStorage.setItem("is_social_login", false);

        let profileinfo_payload = {
          "email" : cognitoUser.ChallengeParameters.email,
          "profile_type":"business_email"
        }
        IRISService.makePostRequest(
          `${identityConfig.audienceDomainURL}/v3/audience/profileinfo`,
          profileinfo_payload,
          response.data.AccessToken
        ).then(audResponse => {
          if (audResponse.status === 'success') {
            //setUser(response.data[0]);
            const data = audResponse.data[0];
            user.id = data.id;
            user.email = data.business_email;
            user.given_name = data.first_name;
            user.family_name = data.last_name;
            user.flow = "emailSignin";
            user.country = data.country;
            user.countryType = "";
            user.state = data.state;
            user.company = data.company;
            user.job_title = data.job_title;
            user.is_social_login = data.is_social_login;
            user.business_type = data.business_type;
            user.job_function = data.job_function;
            user.social_accounts = data.social_accounts;
            user.product_ids = data.product_ids ? data.product_ids : [];
            //Set User to Track
            document.cookie = "IRIS_ID=" + user.id + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
            sha256(user.email.toLowerCase()).then((hash) => {
            document.cookie = "IRIS_UHASH=" + hash + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
            });
            if (window.IIRISTracker && window.snowplow) {
              window.snowplow("setUserIdFromCookie", "IRIS_ID");
            }
            try {
              const evtObj = {};
              evtObj.cat = 'IRISIdentityEvent';
              evtObj.lbl = 'Login Mode';
              evtObj.act = 'login';
              evtObj.prop = 'emailLogin';
              evtObj.val = 1.00;
              if (window.IIRISTracker && window.IIRISTracker.trackStructEvt) {
                window.IIRISTracker.trackStructEvt(evtObj);
              }
            } catch (error) {
              console.log(error);
            }
            if (identityConfig.cognito_add_business_email_attr && !returnedResponse.attributes['custom:business_email']) {
              // UPDATE USER WITH BUSINESS EMAIL
              try {
                CognitoService.updateUserAttributes({
                  "Name": "custom:business_email",
                  "Value": data.business_email
                });
              } catch (e) {
                console.log(e);
              }
              try {
                let isLinkedInLogin = user.email.startsWith('linkedin.');
                CognitoService.updateTokens(
                  identityConfig.clientId,
                  isLinkedInLogin,
                  isLinkedInLogin ? user.email.split("linkedin.")[1] : user.email
                ).then(token => {
                  if (identityConfig.persist_session === false) {
                    clearIRISCookies();
                  }
                  const loginCallBackObj = { "success": true, "user": data, "token": token.access_token, "idToken": token.id_token };
                  if (window.irisLoginCallback) {
                    window.irisLoginCallback(loginCallBackObj);
                  }
                });
              } catch (e) {
                console.log(e);
              }
            }
            if (!identityConfig.show_progressive_profile) {
              triggerLoginCallback(data, response.data.AccessToken);
            }
            else if(!checkProfileInfo(data, response.data.AccessToken) && identityConfig.show_progressive_profile){
              localStorage.setItem("audience_profile",JSON.stringify(data))
              setIsLoading(false);
              props.setFormType("profileform");
            }else{
              check_registration(user, data, response.data.AccessToken);
            }
          }
        });
      } else if (response) {
        setCognitoUser(response.data);
        setInvalidOtp(() => {
          const invalidOtpCount = 3 - (invalidOtp + 1);
          setError(`${t('incorrect_code')}. ${invalidOtpCount} ${t('attempt')}${invalidOtpCount > 1 ? 's' : ''} ${t('remaining')}`);
          return invalidOtp + 1;
        });
        setIsLoading(false)
        if (invalidOtp > 3) {
          setInvalidOtp(invalidOtp + 1);
          clearTimer();
          setIsLoading(false);
          setError(t('wrong_otp'));
        }
      }
    });
  }

  const validateEnteredEmail = () => {
    const enteredEmail = emailInputRef.current.value.trim();
    let isValid = true;
    const errs = [];
    if (enteredEmail === "") {
      isValid = false;
      errs.push("emailInputRef");
    }
    //email validation
    const validEmail = isEmail(enteredEmail);
    if (!validEmail) {
      isValid = false;
      errs.push("emailInputRef");
    }
    if (!isValid) {
      setError("");
    }
    setErrors(errs);
    return isValid;
  }

  //Validating Reset Password Form
  const validateResetForm = () => {
    const enteredCode = OTP;
    let isValid = true;
    const errs = [];
    if (enteredCode === "") {
      isValid = false;
      errs.push("codeInputRef");
    }
    if (!isValid) {
      setError("");
    }
    setErrors(errs);
    return isValid;
  }

  const clearIRISCookies = () => {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      if (name.indexOf('IRIS_') >= 0) {
        document.cookie =
          name +
          '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=' +
          window.location.hostname
      }
    }
  }

  const triggerLoginCallback = (data, token) => {
    if (identityConfig.persist_session === false) {
      clearIRISCookies();
    }
    
    if (window.irisLoginCallback) {
      let callbackUrl = ''
      if (!identityConfig.show_progressive_profile){
          callbackUrl =  `${identityConfig.audienceDomainURL}/v3/audience/profile/${data.id}`
      }else{
          callbackUrl = `${identityConfig.audienceDomainURL}/v3/audience/profile/${data.id}/${identityConfig.id}`
      }
      IRISService.makeGetRequest(
        callbackUrl,
        token
      ).then(res => {
        if (res.status === 'success') {
          const loginCallBackObj = { "success": true, "user": res.data[0], "token": token };
          window.irisLoginCallback(loginCallBackObj);
        } else if (res.status === 'error') {
          const loginCallBackObj = { "success": false, "user": null, "token": null };
          window.irisLoginCallback(loginCallBackObj);
        }
      }).catch((err) => {
        const loginCallBackObj = { "success": false, "user": null, "token": null };
        window.irisLoginCallback(loginCallBackObj);
      });
    }
    localStorage.setItem("CheckLocalUser", "NO");
    if (document.getElementById("iiris-modal-close-btn")) {
      document.getElementById("iiris-modal-close-btn").click();
    }
  }

  return (
    <div>
      {isLoading && <LoadingContent />}
      {checkLocalUser && !isLoading && (
        <CheckLocalUser userObj={userObj} identityConfig={identityConfig} />
      )}
      {!checkLocalUser && !isLoading && (
        <div className={classes["iiris-login-form"]}>
          <form method="post" autoComplete="off">
            <h4 className="iiris-text-center">
              {props.identityConfig.siteName}
            </h4>
            <div className={classes["iiris-hint-text"]}>{t("sign_in")}</div>
            <div className={classes["iiris-hint-text"]}>
              {t("havent_joined")}{" "}
              <span
                className={classes["iiris-register-link"]}
                onClick={redirectToSignUp}
                style={{
                  cursor: "pointer"
                }}
              >
                {t("register")}
              </span>
            </div>
            <div
              className={`${classes["iiris-social-media-option-container"]} iiris-social-media-option-container`}
            >
              <SocialProvider
                identityConfig={identityConfig}
                showEmailSignInInfo={showEmailSignInInfo}
                showSignUpWithEmailFields={showSignInWithEmailFields}
              />
            </div>

            <div style={{ display: showEmailSignInInfo ? "block" : "none" }}>
              {!identityConfig.hide_social || identityConfig.hide_social.length < 4 ?
                <div
                  className={`${classes["iiris-or-seperator"]} iiris-or-seperator`}
                >
                  <span>Or</span>
                </div>
                : null
              }

              {!isLoading && showScreen === "ENTER_EMAIL" && (
                <>
                  <div className={classes["iiris-center-handler"]}>
                    <div
                      className={classes["iiris-error-space"]}
                      style={{
                        display: error.length > 0 ? "block" : "none",
                        width: "350px",
                        textAlign: "center !important",
                        marginTop: "5px",
                      }}
                    >
                      {error}
                    </div>

                    <div
                      style={{
                        width: "100%",
                        marginTop: screenType === "signInWithCode" ? '0' : '10px',
                      }}
                    >
                      <label className={classes["iiris-form-label"]}>
                        {screenType === "signInWithCode" ? t("business_email") : t("email")}
                      </label>
                      <label style={{ color: "red", marginLeft: "3px" }}>
                        *
                      </label>
                      <div className="iiris-input-group">
                        <input
                          type="email"
                          className="iiris-form-control"
                          name="email"
                          required="required"
                          ref={emailInputRef}
                          onKeyUp={onSendSecureCode}
                        />
                      </div>
                      <div
                        className={
                          classes[
                          hasError("emailInputRef")
                            ? "iiris-error-space"
                            : "iiris-hidden"
                          ]
                        }
                      >
                        {t("valid_email")}
                      </div>
                    </div>
                    {screenType === "signInWithCode" && (
                      <div
                        className={classes["iiris-hint-text"]}
                        style={{
                          margin: '10px 0'
                        }}
                      >
                        {t("send_verification_code_note")}
                      </div>
                    )}

                    {screenType === "signInWithPassword" && (
                      <>
                        <div style={{ width: "100%", marginTop: "10px" }}>
                          <label className={classes["iiris-form-label"]}>
                            {t("password")}
                          </label>
                          <label style={{ color: "red", marginLeft: "3px" }}>
                            *
                          </label>
                          <div className="iiris-input-group">
                            <input
                              type="password"
                              className="iiris-form-control"
                              name="password"
                              required="required"
                              ref={passwordInputRef}                             
                            />
                            <i
                              style={{
                                position: "absolute",
                                right: "12px",
                                zIndex: 5555,
                                cursor: "pointer",
                              }}
                              className="bi bi-eye-slash"
                              id="togglePassword"
                              onClick={showHidePassword}
                            ></i>
                          </div>
                          <div
                            className={
                              classes[
                              hasError("passwordInputRef")
                                ? "iiris-error-space"
                                : "iiris-hidden"
                              ]
                            }
                          >
                            {t("enter_password")}
                          </div>
                          <div
                            className="iiris-clearfix"
                            style={{ marginTop: "10px" }}
                          >
                            <a
                              href="#"
                              className={classes["iiris-forget-password-link"]}
                              onClick={redirectToForgetPassword}
                            >
                              {t("forgot")}
                            </a>
                          </div>
                        </div>

                        <div
                          className="iiris-text-center iiris-btn-center"
                          style={{ marginTop: "10px" }}
                        >
                          <button
                            onClick={signInWithPassword}
                            className={`${classes["iiris-social-btn"]} iiris-btn iiris-btn-block`}
                            style={{
                              color: identityConfig.skin.skin_color,
                              background: identityConfig.skin.skin_background,
                            }}
                            type="button"
                          >
                            {t("only_sign_in")}
                          </button>
                        </div>

                        {(identityConfig.email_login_option === "otp" || identityConfig.email_login_option === "all") && (<div
                          style={{
                            width: "100%",
                            marginTop: '10px',
                          }}>
                          <div className={`${classes["iiris-or-seperator"]}`}>
                            <span>{t("or")}</span>
                          </div>

                          <div className="iiris-text-center iiris-btn-center">
                            <button
                              onClick={() => setScreenType("signInWithCode")}
                              className={`${classes["iiris-social-btn"]} iiris-btn iiris-btn-block`}
                              style={{
                                color: identityConfig.skin.skin_color,
                                background: identityConfig.skin.skin_background,
                              }}
                              type="button"
                            >
                              {t("code_instead")}
                            </button>
                          </div>
                        </div>)}
                      </>
                    )}
                    {screenType === "signInWithCode" && (
                      <>
                        <div className="iiris-text-center iiris-btn-center">
                          <button
                            onClick={sendSecureCode}
                            className={`${classes["iiris-social-btn"]} iiris-btn iiris-btn-block `}
                            style={{
                              color: identityConfig.skin.skin_color,
                              background: identityConfig.skin.skin_background,
                              marginTop: "20px",
                            }}
                            type="button"
                          >
                            {t("send_verification")}
                          </button>
                        </div>

                        {identityConfig.email_login_option === "all" && (<div className="iiris-text-center">
                          <a
                            href="#"
                            className={classes["iiris-forget-password-link"]}
                            onClick={() => setScreenType("signInWithPassword")}
                          >
                            {t("only_back")}
                          </a>
                        </div>)}
                      </>
                    )}
                  </div>
                </>
              )}
              {!isLoading && showScreen === "ENTER_CODE" && (
                <div className={classes["iiris-center-handler"]}>
                  {invalidOtp < 3 ? (
                    <div
                      className={classes["iiris-hint-text"]}
                      style={{ width: "350px", margin: "1rem 0rem" }}
                    >
                      {t("otp_send_confirmation")}
                      <span style={{ color: "#2d7b33", fontWeight: 500 }}>
                        {enteredEmail}
                      </span>{" "}
                      {t("otp_send_confirmation_check_spam")}
                    </div>
                  ) : (
                    ""
                  )}

                  {!error.includes("Incorrect code") && (
                    <div
                      className={classes["iiris-error-space-center"]}
                      style={{
                        display: error.length > 0 ? "block" : "none",
                        color: "#0093ff",
                        fontWeight: 500,
                      }}
                    >
                      {error}
                    </div>
                  )}

                  <div className="iiris-text-center">
                    <div className={classes["iiris-center-handler"]}>
                      {minutes === 0 && seconds === 0 ? null : invalidOtp <
                        3 ? (
                        <span className={`${classes["iiris-small-text"]}`} style={{ color: "#0093ff" }}>
                          {t("code_expire_in")}{" "}
                          <span
                            className={`${classes["iiris-red"]}`}
                          >
                            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                          </span>
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    {invalidOtp < 3 && (
                      <div
                        className="iiris-form-group"
                        style={{ margin: "1rem 0rem 2rem 0rem" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            marginTop: "10px",
                          }}
                        >
                          <label>{t("verification_code")}</label>
                          <label style={{ color: "red", marginLeft: "3px" }}>
                            *
                          </label>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginTop: "10px",
                          }}
                        >
                          <OTPInput
                            value={OTP}
                            autoFocus={invalidOtp !== 3}
                            onChange={setOTP}
                            OTPLength={6}
                            otpType="number"
                            disabled={false}
                            style={{
                              justifyContent: 'space-between',
                              gap: '.2rem',
                              width: "100%"
                            }}
                            inputStyles={{
                              width: "45px",
                              marginRight: 0,
                              height: "45px",
                              border: "1px solid #ced4da",
                              borderColor: error.includes("Incorrect code") && 'red',
                              outline: "none"
                            }}
                          />
                        </div>
                        <div
                          className={
                            classes[
                            hasError("codeInputRef")
                              ? "iiris-error-space-center"
                              : "iiris-hidden"
                            ]
                          }
                        >
                          {t("please_enter_code")}
                        </div>
                        {error.includes("Incorrect code") && (
                          <div className={classes["iiris-center-handler"]}>
                            <div
                              className={classes["iiris-error-space-center"]}
                              style={{
                                display: error.length > 0 ? "block" : "none",
                                textAlign: "center !important",
                                marginTop: "5px",
                              }}
                            >
                              {error}
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {invalidOtp >= 3 ? (
                      <div className="iiris-text-center iiris-btn-center">
                        <button
                          onClick={retry}
                          type="button"
                          className={`${classes["iiris-social-btn"]} iiris-btn iiris-btn-block `}
                          style={{
                            color: identityConfig.skin.skin_color,
                            background: identityConfig.skin.skin_background,
                          }}
                        >
                          {t("retry")}
                        </button>
                      </div>
                    ) : (
                      <>
                        <div
                          className="iiris-text-center"
                          style={{ margin: "1rem 0rem" }}
                        >
                          <span>{t("didnt_receive_code")}</span>
                          <a
                            href="#"
                            className={classes["iiris-back-link"]}
                            style={{ fontSize: "14px" }}
                            onClick={resendCode}
                          >
                            {t("resend")}
                          </a>
                        </div>
                        <div className="iiris-text-center iiris-btn-center">
                          <button
                            onClick={login}
                            type="button"
                            className={`${classes["iiris-social-btn"]} iiris-btn iiris-btn-block `}
                            style={{
                              color: identityConfig.skin.skin_color,
                              background: identityConfig.skin.skin_background,
                            }}
                          >
                            {t("verify")}
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
            <button
              type="submit"
              disabled
              style={{ display: "none" }}
              aria-hidden="true"
            ></button>
          </form>
        </div>
      )}
    </div>
  );
};