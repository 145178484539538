import React, { useEffect } from "react";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

import awsconfig from "../../../aws-exports";

import classes from "./SocialProvider.module.css";

export const SocialProvider = props => {
  const { t } = useTranslation();
  const identityConfig = props.identityConfig;

  async function appleSigninSubmitHandler () {
    const window_id = `Apple ${new Date().getTime()}`;
    const {
      oauth: {
        domain,
        scope,
        responseType,
        redirectSignIn,
      },
      aws_user_pools_web_client_id,
    } = awsconfig;

    const url = new URL(
      `https://${domain}/oauth2/authorize?identity_provider=SignInWithApple&redirect_uri=${redirectSignIn}&response_type=${responseType.toUpperCase()}&client_id=${aws_user_pools_web_client_id}&scope=${scope.join(
        " "
      )}`
    );
    await OpenPopupCenter(url.toString(), window_id, 800, 600);
    localStorage.removeItem("CheckLocalUser");
    localStorage.setItem("iiris_ust", new Date());
  };


  async function linkedinSubmitHandler() {
    const window_id = `Linkedin ${new Date().getTime()}`;
    document.cookie = "IRIS_APPID=" + identityConfig.id + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
    const url = new URL(`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.LINKEDIN_CLIENTID}&redirect_uri=${identityConfig.linkedin_redirect_url}&scope=r_liteprofile%20r_emailaddress`);
    await OpenPopupCenter(url.toString(), window_id, 850, 600);
    localStorage.removeItem("CheckLocalUser");
    localStorage.setItem("iiris_ust", new Date());
  }

  async function googleSubmitHandler() {
    const window_id = `Google ${new Date().getTime()}`;
    const {
      oauth: {
        domain,
        scope,
        responseType,
        redirectSignIn,
      },
      aws_user_pools_web_client_id,
    } = awsconfig;
    let codeChallenge = '';
    let url = new URL(
      `https://${domain}/oauth2/authorize?identity_provider=Google&code_challenge_method=S256&code_challenge=${codeChallenge}&redirect_uri=${redirectSignIn}&response_type=${responseType.toUpperCase()}&client_id=${aws_user_pools_web_client_id}&scope=${scope.join(
        " "
      )}`
    );
    OpenPopupCenter(url.toString(), window_id, 850, 600);
    const codeVerifier = await generateNonce();
    localStorage.setItem('ouath_pkce_key', codeVerifier);
    codeChallenge = base64URLEncode(await sha256(codeVerifier));
    url = new URL(
      `https://${domain}/oauth2/authorize?identity_provider=Google&code_challenge_method=S256&code_challenge=${codeChallenge}&redirect_uri=${redirectSignIn}&response_type=${responseType.toUpperCase()}&client_id=${aws_user_pools_web_client_id}&scope=${scope.join(
        " "
      )}`
    );
    await OpenPopupCenter(url.toString(), window_id, 850, 600);
    localStorage.removeItem("CheckLocalUser");
    localStorage.setItem("iiris_ust", new Date());
  }

  async function facebookSubmitHandler() {
    const window_id = `Facebook ${new Date().getTime()}`;
    const {
      oauth: {
        domain,
        scope = ["public_profile", "email"],
        responseType,
        redirectSignIn,
      },
      aws_user_pools_web_client_id,
    } = awsconfig;
    let codeChallenge = '';
    let url = new URL(
      `https://${domain}/oauth2/authorize?identity_provider=Facebook&code_challenge_method=S256&code_challenge=${codeChallenge}&redirect_uri=${redirectSignIn}&response_type=${responseType.toUpperCase()}&client_id=${aws_user_pools_web_client_id}&scope=${scope.join(
        " "
      )}`
    );
    OpenPopupCenter(url.toString(), window_id, 800, 600);
    const codeVerifier = await generateNonce();
    localStorage.setItem('ouath_pkce_key', codeVerifier);
    codeChallenge = base64URLEncode(await sha256(codeVerifier));
    url = new URL(
      `https://${domain}/oauth2/authorize?identity_provider=Facebook&code_challenge_method=S256&code_challenge=${codeChallenge}&redirect_uri=${redirectSignIn}&response_type=${responseType.toUpperCase()}&client_id=${aws_user_pools_web_client_id}&scope=${scope.join(
        " "
      )}`
    );
    await OpenPopupCenter(url.toString(), window_id, 800, 600);
    localStorage.removeItem("CheckLocalUser");
    localStorage.setItem("iiris_ust", new Date());
  }

  async function OpenPopupCenter(pageURL, windowName, w, h) {
    document.cookie = "IRIS_APPID=" + identityConfig.id + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
    // Fixes dual-screen position
    const dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;
    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;
    const windowFeature = 'resizable=yes, copyhistory=yes, scrollbars=yes, width=' + w / systemZoom + ', height=' + h / systemZoom + ', top=' + top + ', left=' + left;
    const newWindow = window.open(pageURL, windowName, windowFeature);
    // Event listener to handle the cross domain redirect callback
    window.addEventListener('message', window.handleMessageEvent, false);
    if (newWindow === null || typeof(newWindow) === undefined) {
      alert(t('disabled_popup'));
      return;
    }
    // Puts focus on the newWindow
    if (window.focus && newWindow && newWindow.focus) {
      newWindow.focus();
    }
    return true;
  }

  const sha256 = async (str) => {
    return await crypto.subtle.digest("SHA-256", new TextEncoder().encode(str));
  };

  const generateNonce = async () => {
    const hash = await sha256(crypto.getRandomValues(new Uint32Array(4)).toString());
    // https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
    const hashArray = Array.from(new Uint8Array(hash));
    return hashArray.map(b => b.toString(16).padStart(2, "0")).join("");
  };

  const base64URLEncode = (string) => {
    return btoa(String.fromCharCode.apply(null, new Uint8Array(string)))
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "")
  };

  useEffect(() => {
    switch(identityConfig.default_social_option) {
      case 'email' : {
        if (!props.showEmailSignInInfo)
          props.showSignUpWithEmailFields();
        break;
      }
      default:
        break;
    }
  }, []);

  return (
		<div>
			<div className={classes["iiris-custom-row-container"]}>
				{!identityConfig.hide_social.includes("apple") && (
					<div
						className={classes["iiris-social-icon"]}
						title="Use Apple Profile"
						onClick={appleSigninSubmitHandler}
					>
						<Icon
							icon="ant-design:apple-filled"
							width="50"
							height="50"
							color="grey"
						/>
					</div>
				)}
				{!identityConfig.hide_social.includes("linkedin") && (
					<div
						className={`${classes["iiris-social-icon"]} `}
						title="Use Linkedin Profile"
						onClick={linkedinSubmitHandler}
					>
						<img
							src={identityConfig.publicAssetsUrl + "linkedin.svg"}
							style={{ color: "#007bb6" }}
							alt="linkedin"
						/>
					</div>
				)}
				{!identityConfig.hide_social.includes("google") && (
					<div
						className={`${classes["iiris-social-icon"]} `}
						title="Use Google Profile"
						onClick={googleSubmitHandler}
					>
						<img
							src={identityConfig.publicAssetsUrl + "google.svg"}
							style={{ color: "#007bb6" }}
							alt="google"
						/>
					</div>
				)}
				{!identityConfig.hide_social.includes("facebook") && (
					<div
						className={`${classes["iiris-social-icon"]} `}
						title="Use Facebook Profile"
						onClick={facebookSubmitHandler}
					>
						<img
							src={identityConfig.publicAssetsUrl + "facebook.svg"}
							alt="facebook"
							style={{ color: "#007bb6" }}
						/>
					</div>
				)}
			</div>
		</div>
	);
};
