//Custom Events
export function trackBusinessEvt(evt, asset) {

    if (window.IIRISTracker) {
        pushBusinessEvt(evt, asset);
        executeCallback(evt, asset); // Execute the callback function
    }
    window.addEventListener('trackerLoaded', function (event) {
        if (window.IIRISTracker) {
            pushBusinessEvt(evt, asset);
            executeCallback(evt, asset); // Execute the callback function
        }
    });
}

function pushBusinessEvt(evt, asset) {
    window.IIRISTracker.trackBusinessEvt(evt, asset);
}

function executeCallback(evt, asset) {
    // Call the global callback function and pass evt and asset as arguments for listeners 
    if (typeof window.scmBusinessEvtCallback === 'function') {
        window.scmBusinessEvtCallback(evt, asset);
    }
}
