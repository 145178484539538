const awsconfig = {
  "aws_project_region": process.env.REACT_APP_COGNITO_REGION,
  "aws_cognito_region": process.env.REACT_APP_COGNITO_REGION,
  "aws_user_pools_web_client_id": process.env.REACT_APP_COGNITO_WEBCLIENT_ID,
  "oauth": {
    "domain": process.env.REACT_APP_COGNITO_DOMAIN,
    "redirectSignIn": process.env.REACT_APP_REDIRECT_SIGNIN,
    "scope": ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
    "responseType": "code",
    "auth0_identity_provider": "Linkedin"
  }
};


export default awsconfig;
