import React from "react";
import { useTranslation } from "react-i18next";

import classes from "./LoadingContent.module.css";

export const LoadingContent = () => {
  const { t } = useTranslation();

  return (
    <div style={{ textAlign: "center", margin: "30px" }}>
      <div style={{ paddingBottom: "40px" }}>
        {t('loading')}
      </div>
      <div className={classes['iiris-loading']}></div>
    </div>
  );
}
