import React from "react";
import { Icon } from "@iconify/react";

import "../containers/SignUp/UpdatePasswordContainer.css";

export const MustContainItem = (props) => {
  const { data, showPasswordRules } = props;
  const label = data[0];
  const meetsReq = data[1];

  const setClass = () => {
    const classArr = ["iiris-must-text"];
    if (meetsReq) {classArr.push("iiris-must-enable");}
    return classArr.join(" ");
  };

  return (
		<>
			{!meetsReq ? (
				<div className="iiris-MustContainItem">
					<div className="iiris-must-item">
						<li className={setClass()}>
							<Icon
								icon="ion:close-circle-outline"
								width="20"
								height="20"
								color="grey"
							/>
							<span style={{ marginLeft: "10px" }}>{label}</span>
						</li>
					</div>
				</div>
			) : (
				showPasswordRules && (
					<div className="iiris-MustContainItem">
						<div className="iiris-must-item">
							<li className={setClass()}>
								<Icon
									icon="ic:outline-check-circle-outline"
									width="20"
									height="20"
									color="green"
								/>
								<span style={{ marginLeft: "10px" }}>{label}</span>
							</li>
						</div>
					</div>
				)
			)}
		</>
	);
};
