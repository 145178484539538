import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from "react-i18next";
import TextInput from 'react-autocomplete-input';
import 'react-autocomplete-input/dist/bundle.css';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import get from 'lodash/get';

import { LoadingContent } from '../../common/LoadingContent';
import { CustomSelectComponent } from '../../common/CustomSelectComponent';
import { IRISService } from '../../service/IRISService';
import { SignerService } from '../../service/SignerService';
import { trackBusinessEvt } from "../../common/CustomEvents";
import { sha256 } from '../../common/IdentityUtils';

import classes from './ProgressiveProfile.module.css';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'contents',
		justifyContent: 'center',
		flexWrap: 'wrap',
		'& > *': {
			margin: theme.spacing(0.5),
		},
	},
}))

export const ProgressiveProfile = props => {
	const { identityConfig, userObj, companyData, setState } = props;
	const { pages, division, brand, language, configureRoles } = identityConfig;
	const { t } = useTranslation();
	const classesMat = useStyles();
	const [isLoading, setIsLoading] = useState(false);

	const [questionSelection, setQuestionSelection] = useState(() => {
		return pages.map((page, index) => {
			return {
				[`page${index + 1}`]: page.questions.map((question) => {
					let profile_id = question.profile_id ? question.profile_id : question.id;
					const questionObj = {};
					questionObj.id = profile_id;
					if (question.hasOwnProperty('isCompany')) {
						questionObj.isCompany = true;
						questionObj.company_fields = question.company_fields;
					}
					if (question.hasOwnProperty('isRole')) {
						questionObj.isRole = true;
					}
					questionObj[profile_id] = '';
					questionObj["required"] = question.required != undefined ? question.required : true;
					questionObj.type = question.type ? question.type : '';
					return questionObj;
				}),
			}
		});
	});
	const [questionData, setQuestionData] = useState({});
	const [filteredQuestionData, setFilteredQuestionData] = useState({});
	const [profilePic, setProfilePic] = useState('');
	const [fullName, setFullName] = useState('');
	const [pageNumber, setPageNumber] = useState(1);
	const [error, setError] = useState('');
	const [errors, setErrors] = useState([]);
	const [checkedListItems, setCheckedListItems] = useState([]);

	const authToken = document.cookie.split('; ').find(row => row.startsWith('IRIS_AUTH=')).split('=')[1];
	const userDomain = userObj.email.split('@')[1];

	const { cols: gridCols, rows: gridRows } = useMemo(() =>
		pages[pageNumber - 1].questions.reduce((res, question) => ({
			rows: res.rows > question.layout.rows ? res.rows : question.layout.rows,
			cols: res.cols > question.layout.cols ? res.cols : question.layout.cols
		}), { rows: 0, cols: 0 }), [pages]);

	let orderedQuestions = useMemo(() =>
		pages[pageNumber - 1]
			.questions
			.slice()
			.sort((prev, next) => prev.layout.rows !== next.layout.rows
				? prev.layout.rows - next.layout.rows
				: prev.layout.cols - next.layout.cols
			)
		, [pages]);

	useEffect(() => {
		setQuestionSelection((prevState) => {
			const prevStateCopy = JSON.parse(JSON.stringify(prevState));
			prevStateCopy.forEach((page, index) => {
				page[`page${index + 1}`].forEach(question => {
					let profile_id = question.profile_id ? question.profile_id : question.id;
					if (question.hasOwnProperty('isCompany')) {
						if (question.company_fields.type === 'array') {
							const value = get(companyData[userDomain], question.company_fields.key_name);
							question[profile_id] = value ? value[0] : '';
						} else if (question.company_fields.type === 'string') {
							const value = get(companyData[userDomain], question.company_fields.key_name);
							question[profile_id] = value ? value.toString() : '';
						}
					}
				})
			})
			return prevStateCopy;
		})
	}, [companyData, userDomain]);

	const getQuestionData = useCallback(async () => {
		pages.map((page, index) => {
			page.questions.map(async (question, i) => {
				let profile_id = question.profile_id ? question.profile_id : question.id;
				if (question.fetch === 'dynamic') {
					try {
						const response = await SignerService.signedAPIRequest(
							"execute-api",
							"GET",
							identityConfig.proxyURL +
							"/" +
							question.url.replace("{division}/{brand}", "") +
							identityConfig.division +
							"/" +
							identityConfig.brand +
							"?locale=" +
							language,
							""
						);
						const questionId = profile_id;
						setQuestionData((prevState) => {
							const newData = {
								...prevState,
								[`page${index + 1}`]: {
									...prevState[`page${index + 1}`],
									[questionId]: response.data ? response.data : response,
								},
							}
							return newData
						});
						setFilteredQuestionData((prevState) => {
							const newData = {
								...prevState,
								[`page${index + 1}`]: {
									...prevState[`page${index + 1}`],
									[questionId]: response.data ? response.data : response,
								},
							}
							return newData
						});
					} catch (e) {
						console.log(e)
					}
				}
				else if (question.fetch === 'static') {
					const questionId = profile_id;
					setQuestionData((prevState) => {
						const newData = {
							...prevState,
							[`page${index + 1}`]: {
								...prevState[`page${index + 1}`],
								[questionId]: getLocalizedData(question.data),
							},
						}
						return newData;
					});
					setFilteredQuestionData((prevState) => {
						const newData = {
							...prevState,
							[`page${index + 1}`]: {
								...prevState[`page${index + 1}`],
								[questionId]: getLocalizedData(question.data),
							},
						}
						return newData;
					});
				}
			});
			return null;
		});
	}, [
		identityConfig.brand,
		identityConfig.division,
		identityConfig.proxyURL,
		pages,
	]);

	useEffect(() => {
		localStorage.setItem('widget_screen', 'progressive_profile');
		getQuestionData();
	}, [getQuestionData]);

	useEffect(() => {
		try {
			const evtObj = {};
			evtObj.cat = 'IRISIdentityEvent';
			evtObj.lbl = 'Page Visited';
			evtObj.act = 'progressiveProfile';
			evtObj.prop = 'progressiveProfile';
			evtObj.val = 1.00;
			if (window.IIRISTracker && window.IIRISTracker.trackStructEvt) {
				window.IIRISTracker.trackStructEvt(evtObj);
			}
		} catch (error) {
			console.log(error);
		}
		if (props.userObj.email) {
			getAudienceProfile(props.userObj.email);
		}
		setFullName(props.userObj.given_name + ' ' + props.userObj.family_name);
		if (props.userObj.picture) {
			setProfilePic(props.userObj.picture);
		}
		//Set User to Track
		if (props.userObj && props.userObj.id) {
			document.cookie = "IRIS_ID=" + props.userObj.id + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
			sha256(props.userObj.email.toLowerCase()).then((hash) => {
				document.cookie = "IRIS_UHASH=" + hash + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
			});
			if (window.IIRISTracker && window.snowplow) {
				window.snowplow("setUserIdFromCookie", "IRIS_ID");
			}
		}
	}, [identityConfig.proxyURL, props.userObj, userObj]);

	function hasError(key) {
		return errors.indexOf(key) !== -1;
	}

	const getLocalizedData = data => {
		let localizedData = [];
		const locale = language;
		if (data) {
			for (let i = 0; i < data.length; i++) {
				if (!data[i].locale && locale === 'en') {
					localizedData.push(data[i]);
				} else if (data[i].locale && data[i].locale === locale) {
					localizedData.push(data[i]);
				}
			}
		}
		return localizedData;
	}

	const validateForm = () => {
		let errs = [];
		let isValid = true;
		questionSelection.map((page, index) =>
			page[`page${index + 1}`].map((question) => {
				let profile_id = question.profile_id ? question.profile_id : question.id;
				let question_type = question.type ? question.type : '';
				const selectedQuestionId = profile_id;
				const selectedQuestionVal = question[profile_id];
				if (selectedQuestionId === "product_category" || question_type === 'multiselect-list') {
					if (checkedListItems.length === 0) {
						if (question.required) {
							errs.push(selectedQuestionId);
							isValid = false;
						}
					}
				}
				else {
					if (selectedQuestionId === "phone_number") {
						let isPhoneNumber = false;
						if (selectedQuestionVal.startsWith('+')) {
							const possiblePhoneNumber = selectedQuestionVal.replaceAll(' ', '').slice(1);
							const isNum = /^\d+$/.test(possiblePhoneNumber);
							isPhoneNumber = isNum && possiblePhoneNumber.length < 16 && possiblePhoneNumber.length > 8;
						}
						if (!isPhoneNumber) {
							if (question.required) {
								errs.push(selectedQuestionId);
								isValid = false;
							}
						}
					}
					if (!selectedQuestionVal) {
						if (question.required) {
							errs.push(selectedQuestionId);
							isValid = false;
						}
					}
				}
			})
		)
		setErrors(errs);
		return isValid;
	}

	const trackFields = prevStateCopy => {
		try {
			let trackerInfo = {};
			if (prevStateCopy) {
				trackerInfo = prevStateCopy;
			} else {
				trackerInfo = questionSelection;
			}
			const fieldInfo = {
				abandoned_on: "page2_dropout",
				email: userObj.email,
				first_name: userObj.given_name,
				last_name: userObj.family_name,
				country: userObj.country_id ? userObj.country_id : userObj.country,
				country_name: userObj.country_name,
				state: userObj.state,
				state_name: userObj.state_name,
			}
			trackerInfo.map((page, index) =>
				page[`page${index + 1}`].map((question) => {
					let profile_id = question.profile_id ? question.profile_id : question.id;
					const selectedQuestionId = profile_id;
					const selectedQuestionVal = question[profile_id];
					let filteredOrderedQuestion = orderedQuestions.filter(obj => { return obj.id == selectedQuestionId });
					if( "render" in filteredOrderedQuestion[0] ? filteredOrderedQuestion[0]["render"] : true ) {
						if (selectedQuestionId === "job_function") {
							fieldInfo['job_functions'] = [{ id: selectedQuestionVal, division: identityConfig.division, brand: identityConfig.brand }];
						} else if (selectedQuestionId === "business_type") {
							fieldInfo['business_types'] = [{ id: selectedQuestionVal, division: identityConfig.division, brand: identityConfig.brand }];
						} else if (selectedQuestionId === "product_category") {
							fieldInfo['product_categories'] = [{ categories: checkedListItems, division: identityConfig.division, brand: identityConfig.brand }];
						} else if (selectedQuestionId === "property_category") {
							fieldInfo['property_categories'] = [{ categories: checkedListItems, division: identityConfig.division, brand: identityConfig.brand }];
						} else if (selectedQuestionId === "phone_number") {
							fieldInfo['phones'] = [selectedQuestionVal.replaceAll(' ', '')];
						}
						else {
							if (isAudienceQuestion(selectedQuestionId)) {
								fieldInfo[selectedQuestionId] = selectedQuestionVal;
							}
						}
					}
				})
			)
			localStorage.setItem("trackedFields", JSON.stringify(fieldInfo));
		}
		catch (error) {
			console.log(error);
		}
	}

	const finishSignUp = async (event) => {
		event.preventDefault()
		if (!validateForm()) {
			return;
		}
		setIsLoading(true)
		try {
			let regPayload = {
				"iris_id": userObj.id,
				"product_id": props.identityConfig.id,
				"product_type": props.identityConfig.siteType,
				"product_sub_type": props.identityConfig.siteSubType,
				"product_name": props.identityConfig.siteName,
				"user_enroll_date": new Date(),
				"questions": {
					"user_enroll_type": language === "en" ? props.identityConfig.welcome_email_template : props.identityConfig.welcome_email_template + "_" + language
				},
				"mode":props.identityConfig.environment_mode
			};
			
			if (props.identityConfig.welcome_email_sender_id != "") {
				if(props.identityConfig.welcome_email_sender_id.toLowerCase() === "labs@iiris.com"){
					regPayload['welcome_email_sender_id'] = "support@iiris.com"
				}else{
					regPayload['welcome_email_sender_id'] = props.identityConfig.welcome_email_sender_id
				}
			}
			if (props.identityConfig.welcome_email_source_arn != "") {
				regPayload['welcome_email_source_arn'] = props.identityConfig.welcome_email_source_arn
			}
			if (props.identityConfig.welcome_email_return_path_arn != "") {
				regPayload['welcome_email_return_path_arn'] = props.identityConfig.welcome_email_return_path_arn
			}

			const rolesPayload = {
				product_id: props.identityConfig.id,
				iris_id: userObj.id,
				business_email: userObj.email,
				questions: {},
				product_type: props.identityConfig.siteType,
				product_sub_type: props.identityConfig.siteSubType,
				product_name: props.identityConfig.siteName,
			};
			let payload = {
				business_email: userObj.email,
				first_name: userObj.given_name,
				last_name: userObj.family_name,
				country: userObj.country_id ? userObj.country_id : userObj.country,
				country_name: userObj.country_name,
				state: userObj.state,
				state_name: userObj.state_name,
				is_social_login: userObj.is_social_login,
				social_accounts: userObj.social_accounts,
			}
			questionSelection.map((page, index) =>
				page[`page${index + 1}`].map((question) => {
					let profile_id = question.profile_id ? question.profile_id : question.id
					const selectedQuestionId = profile_id
					const selectedQuestionVal = question[profile_id]
					if (question.hasOwnProperty('isRole')) {
						rolesPayload.questions[selectedQuestionId] = selectedQuestionVal;
					}
					let filteredOrderedQuestion = orderedQuestions.filter(obj => { return obj.id == selectedQuestionId });
					if( "render" in filteredOrderedQuestion[0] ? filteredOrderedQuestion[0]["render"] : true ) {
						if (selectedQuestionId === "job_function") {
							payload['job_functions'] = [{ id: selectedQuestionVal, division: identityConfig.division, brand: identityConfig.brand }];
							regPayload.questions['job_functions'] = [{ id: selectedQuestionVal, division: identityConfig.division, brand: identityConfig.brand }];
						} else if (selectedQuestionId === "business_type") {
							payload['business_types'] = [{ id: selectedQuestionVal, division: identityConfig.division, brand: identityConfig.brand }];
							regPayload.questions['business_types'] = [{ id: selectedQuestionVal, division: identityConfig.division, brand: identityConfig.brand }];
						} else if (selectedQuestionId === "product_category") {
							payload['product_categories'] = [{ categories: checkedListItems, division: identityConfig.division, brand: identityConfig.brand }];
							regPayload.questions['product_categories'] = [{ categories: checkedListItems, division: identityConfig.division, brand: identityConfig.brand }];
						} else if (selectedQuestionId === "property_category") {
							payload['property_categories'] = [{ categories: checkedListItems, division: identityConfig.division, brand: identityConfig.brand }];
							regPayload.questions['property_categories'] = [{ categories: checkedListItems, division: identityConfig.division, brand: identityConfig.brand }];
						} else if (selectedQuestionId === "phone_number") {
							payload['phones'] = [selectedQuestionVal.replaceAll(' ', '')];
						} else if (selectedQuestionId === "industry") {
							payload['industries'] = [{ id: selectedQuestionVal, division: identityConfig.division, brand: identityConfig.brand }];
							regPayload.questions['industries'] = [{ id: selectedQuestionVal, division: identityConfig.division, brand: identityConfig.brand }];
						} else if (selectedQuestionId === "market_sectors") {
							payload['market_sectors'] = [{ id: selectedQuestionVal, division: identityConfig.division, brand: identityConfig.brand }];
							regPayload.questions['market_sectors'] = [{ id: selectedQuestionVal, division: identityConfig.division, brand: identityConfig.brand }];
						} else if (selectedQuestionId === "interests") {
							payload['interests'] = [{ id: selectedQuestionVal, division: identityConfig.division, brand: identityConfig.brand }];
							regPayload.questions['interests'] = [{ id: selectedQuestionVal, division: identityConfig.division, brand: identityConfig.brand }];
						}
						else {
							if (isAudienceQuestion(selectedQuestionId)) {
								payload[selectedQuestionId] = selectedQuestionVal;
							}
							regPayload.questions[selectedQuestionId] = selectedQuestionVal;
						}
					}
				})
			)
			try {
				await IRISService.makePostRequest(
					identityConfig.audienceDomainURL + "/v3/audience/registration",
					regPayload,
					authToken
				);
				if (configureRoles) {
					await IRISService.makePostRequest(
						identityConfig.proxyURL + "/v1/roles",
						rolesPayload,
						authToken
					);
				}
			} catch (regErr) {
				console.log(regErr);
			}

			if (userObj.id && userObj.id !== '') {
				payload.id = userObj.id
			}
			try {
				const response = await IRISService.makePostRequest(
					identityConfig.audienceDomainURL + '/v3/audience/profile',
					payload,
					authToken
				)
				if (response.status === 'success') {
					try {
						//Tracker Profile Submit Event for Sign Up
						const transactionID = window.localStorage.getItem("regTransactionId");
						const evt = {
							entityId: 'NA',
							transactionId: transactionID,
							formId: 'progressive',
							status: 'page2submit',
							type: 'register',
							ts: new Date().toISOString()
						};
						const asset = {
							id: 'NA',
							name: window.location.host,
							type: 'form',
							size: 0,
							url: window.location.href,
							created: new Date().toISOString(),
							lastUpdated: new Date().toISOString(),
							tags: 'success',
							metadata: JSON.stringify(payload),
						};
						trackBusinessEvt(evt, asset);
					}
					catch (error) {
						console.log(error);
					}
					if (localStorage.getItem("iiris_ust")) {
						const timeData = {};
						timeData.cat = 'IRISIdentityEvent';
						timeData.lbl = 'Capture Time';
						timeData.act = 'registrationTime';
						timeData.prop = 'registrationTime';
						const startTime = Date.parse(localStorage.getItem("iiris_ust"));
						const endTime = new Date();
						timeData.val = (endTime - startTime).toFixed(2);
						if (window.IIRISTracker && window.IIRISTracker.trackStructEvt) {
							window.IIRISTracker.trackStructEvt(timeData);
						}
					}
					if (identityConfig.persist_session === false) {
						clearIRISCookies();
					}
					if (window.irisRegisterCallback) {
						IRISService.makeGetRequest(
							`${identityConfig.audienceDomainURL}/v3/audience/profile/${response.data[0].id}/${identityConfig.id}`,
							authToken
						).then(res => {
							if (res.status === 'success') {
								let userCallbackdata = res.data[0]
								userCallbackdata.profile_picture = localStorage.getItem("profile_picture") ? localStorage.getItem("profile_picture") : ""
								userCallbackdata.is_social_login = JSON.parse(localStorage.getItem("is_social_login"))
								const regCallBackObj = { "success": true, "user": userCallbackdata, "token": authToken };
								window.irisRegisterCallback(regCallBackObj);
							} else if (res.status === 'error') {
								const regCallBackObj = { "success": false, "user": null, "token": null };
								window.irisRegisterCallback(regCallBackObj);
							}
						}).catch((err) => {
							const regCallBackObj = { "success": false, "user": null, "token": null };
							window.irisRegisterCallback(regCallBackObj);
						});
					}
					localStorage.setItem("CheckLocalUser", "NO");
					if (identityConfig.showShareIcons !== "") {
						setState("userDetails");
					} else {
						if (document.getElementById("iiris-modal-close-btn")) {
							document.getElementById("iiris-modal-close-btn").click();
						} else {
							identityConfig.toggleModal();
						}
					}
				} else if (response.status === 'error') {
					setIsLoading(false);
					setError(response.message);
				}
			} catch (e) {
				setIsLoading(false);
				setError(e.message);
			}
		} catch (authError) {
			setIsLoading(false);
			setError(t('error_registration'));
		}
	}

	const handleChipClick = (questionId, selectedChip, index) => {

		filterQuestionsData(questionId, [selectedChip], index);
		setQuestionSelection((prevState) => {
			const prevStateCopy = JSON.parse(JSON.stringify(prevState));
			prevStateCopy[pageNumber - 1][`page${pageNumber}`][index][questionId] = selectedChip.id;
			trackFields(prevStateCopy);
			return prevStateCopy;
		})
	}

	const handleTextInputChange = (questionId, val, index) => {
		setQuestionSelection((prevState) => {
			const prevStateCopy = JSON.parse(JSON.stringify(prevState));
			prevStateCopy[pageNumber - 1][`page${pageNumber}`][index][questionId] = val;
			trackFields(prevStateCopy);
			return prevStateCopy;
		})
	}

	const onDropdownChange = (questionId, e, index) => {

		filterQuestionsData(questionId, e, index);

		if (e.length !== 0) {
			setQuestionSelection((prevState) => {
				const prevStateCopy = JSON.parse(JSON.stringify(prevState));
				prevStateCopy[pageNumber - 1][`page${pageNumber}`][index][questionId] = e[0].id;
				trackFields(prevStateCopy);
				return prevStateCopy;
			})
		} else {
			setQuestionSelection((prevState) => {
				const prevStateCopy = JSON.parse(JSON.stringify(prevState));
				prevStateCopy[pageNumber - 1][`page${pageNumber}`][index][questionId] = '';
				trackFields(prevStateCopy);
				return prevStateCopy;
			})
		}
	}

	const handleCheckboxChange = (questionId, event, index) => {

		filterQuestionsData(questionId, event, index);

		setQuestionSelection((prevState) => {
			const prevStateCopy = JSON.parse(JSON.stringify(prevState));
			prevStateCopy[pageNumber - 1][`page${pageNumber}`][index][questionId] = event.target.checked;
			trackFields(prevStateCopy);
			return prevStateCopy;
		})
	}

	const handleRadioButton = (questionId, event, index) => {

		filterQuestionsData(questionId, event, index);

		setQuestionSelection((prevState) => {
			const prevStateCopy = JSON.parse(JSON.stringify(prevState));
			prevStateCopy[pageNumber - 1][`page${pageNumber}`][index][questionId] = event.target.value;
			trackFields(prevStateCopy);
			return prevStateCopy;
		})
	}

	function filterArrayDataByValue(arr, filterConstraint, question_id) {
		let data = arr.filter(obj => {
		  return obj.parentId === question_id && obj.parentValue.split(",").includes(filterConstraint.id);
		});

		return data;
	}

	// Dependent Prompts Logic: Function to filter questions data
	const filterQuestionsData = (questionID, selectedId, index) => {

		let questionConfigs = pages[pageNumber-1].questions.find(o => o.id === questionID);
		let selectedPage = "page" + (pageNumber);

		if ("children" in questionConfigs && questionConfigs.children.length != 0) {
			let questionsToFilter = questionConfigs.children;
			for (let i = 0; i < questionsToFilter.length; i++) {
				let filteredData = filterArrayDataByValue(questionData[selectedPage][questionsToFilter[i]], selectedId[0], questionID);
				Object.keys(filteredQuestionData[selectedPage]).forEach((item) => {
					if (item == questionsToFilter[i]) {
						filteredQuestionData[selectedPage][item] = filteredData;
					}
				})

				//  Render the dependent questions by changing render property.
				let filteredOrderedQuestion = orderedQuestions.filter(obj => { return obj.id == questionsToFilter[i] });
				if ("render" in filteredOrderedQuestion[0] && filteredOrderedQuestion[0]["data"] && checkIfParentValueContainsSelectedID(filteredOrderedQuestion[0]["data"], selectedId[0].id)) {
					filteredOrderedQuestion[0]["render"] = true;
				}
				else if("render" in filteredOrderedQuestion[0]){
					filteredOrderedQuestion[0]["render"] = false;
				}

				// Reset the children question selections
				let filteredQuestionSelection = questionSelection[pageNumber - 1][selectedPage].filter(obj => { return obj.id == questionsToFilter[i] });
				if (questionsToFilter[i] in filteredQuestionSelection[0]) {
					filteredQuestionSelection[0][questionsToFilter[i]] = '';
				}

				// Reset the multi-select children question selection if any
				if ((questionsToFilter[i] in questionData[selectedPage]) && orderedQuestions.filter(obj => {return obj.id == questionsToFilter[i]})[0]['type'] == "multiselect-list") {
					setCheckedListItems([]);
				}

			}
		}
	}

	const checkIfParentValueContainsSelectedID = (data, selectedId) => {
		let isIdSelected = false;
	  
		data.forEach(obj => {
		  const parentValueArr = obj.parentValue.split(',');
		  parentValueArr.forEach(val => {
			if (val.trim() === selectedId) {
			  isIdSelected = true;
			  return;
			}
		  });
		  if (isIdSelected) {
			return;
		  }
		});
	  
		return isIdSelected;
	  }
	  

	const createImageFromInitials = (size, name, color) => {
		if (name == null) {
			return;
		}
		name = getInitials(name);
		const canvas = document.createElement('canvas');
		const context = canvas.getContext('2d');
		canvas.width = canvas.height = size;
		context.fillStyle = '#ffffff';
		context.fillRect(0, 0, size, size);
		context.fillStyle = `${color}50`;
		context.fillRect(0, 0, size, size);
		context.fillStyle = color;
		context.textBaseline = 'middle';
		context.textAlign = 'center';
		context.font = `${size / 2}px Arial`;
		context.fillText(name, size / 2, size / 2);
		return canvas.toDataURL();
	}

	const getInitials = name => {
		let initials;
		const nameSplit = name.split(' ');
		const nameLength = nameSplit.length;
		if (nameLength > 1) {
			initials = nameSplit[0].substring(0, 1) + nameSplit[nameLength - 1].substring(0, 1);
		} else if (nameLength === 1) {
			initials = nameSplit[0].substring(0, 1);
		} else {
			return;
		}
		return initials.toUpperCase();
	}

	// Add/Remove checked item from list
	const handleListCheck = event => {
		let updatedList = [...checkedListItems];

		if (event.target.checked) {
			updatedList = [...checkedListItems, { id: event.target.value }];
		} else {
			updatedList = checkedListItems.filter((listItem) => listItem.id !== event.target.value);
		}
		setCheckedListItems(updatedList);
	}

	const getAudienceProfile = email => {
		try {
			setIsLoading(true);
			/*const uri = identityConfig.audienceDomainURL + "/v3/audience/profile?business_email=" + encodeURIComponent(email);
			axios.get(uri, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": authToken,
				}
			}).then((audResponse) => {
				if (audResponse.status === 200) {
					userObj.id = audResponse.data.data[0].id;
					userObj.country = audResponse.data.data[0].country;
					userObj.state = audResponse.data.data[0].state;
					userObj.is_social_login = audResponse.data.data[0].is_social_login;
					userObj.social_accounts = audResponse.data.data[0].social_accounts;
					setIsLoading(false);
				}
			}).catch((e) => {
				setIsLoading(false);
			});*/
			let payload = {
				"email" : email,
				"profile_type":"business_email"
			}
			IRISService.makePostRequest(
				identityConfig.audienceDomainURL + '/v3/audience/profileinfo',
				payload,
				authToken
			).then((audResponse) => {
				if (audResponse.status === 'success') {
					const data = audResponse.data[0];
					userObj.id = data.id;
					userObj.country = data.country;
					userObj.state = data.state;
					userObj.is_social_login = data.is_social_login;
					userObj.social_accounts = data.social_accounts;
					setIsLoading(false);

				}
			}).catch((e) => {
				setIsLoading(false);
			});
		} catch (errorResp) {
			setIsLoading(false);
		}
	}

	const handlePageClick = (e, type) => {
		e.preventDefault();
		if (type === 'prev') {
			setPageNumber(prevState => prevState - 1);
		}
		if (type === 'next') {
			setPageNumber(prevState => prevState + 1);
		}
	};

	const getQuestionTitle = (question) => {
		if (question.hasOwnProperty("config")) {
			const filteredQuestionArray = question.config.filter(
				(configObj) =>
					configObj.brand === brand &&
					configObj.division === division &&
					configObj.locale === language
			);
			if (filteredQuestionArray.length > 0) {
				return filteredQuestionArray[0].title;
			} else {
				return question.title || '';
			}
		} else {
			return question.title || '';
		}
	};

	const isAudienceQuestion = (questionId) => {
		let flag = false;
		const questions = pages[0].questions;
		questions.map(que => {
			let profile_id = que.profile_id ? que.profile_id : que.id
			if (profile_id === questionId) {
				if (que.isAudience) {
					flag = true;
					return flag;
				}
			}
		})
		return flag;
	};

	const clearInput = (e, questionId, index) => {
		e.preventDefault();
		setQuestionSelection((prevState) => {
			const prevStateCopy = JSON.parse(JSON.stringify(prevState));
			prevStateCopy[pageNumber - 1][`page${pageNumber}`][index][questionId] = '';
			return prevStateCopy;
		})
	}

	const clearIRISCookies = () => {
		const cookies = document.cookie.split(';');
		for (let cookie of cookies) {
			let eqPos = cookie.indexOf('=');
			let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			if (name.indexOf('IRIS_') >= 0) {
				document.cookie =
					name +
					'=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=' +
					window.location.hostname;
			}
		}
	}

	return (
		<div>
			{isLoading && <LoadingContent />}
			{!isLoading && (
				<div className={classes['iiris-profile-form']}>
					<form method='post' autoComplete='off'>
						<div className='iiris-text-center'>
							<img
								src={
									profilePic !== ''
										? profilePic
										: createImageFromInitials(80, fullName, '#11A7D9')
								}
								alt='Avatar'
								class={classes['avatar']}
							/>
						</div>

						<h4 className='iiris-text-center'>
							{t('welcome')} {props.userObj.given_name} {props.userObj.family_name}!
						</h4>

						<div className='iiris-form-group iiris-text-center'>
							{t('please_tell_us')}
						</div>

						<div
							className={classes['iiris-error-space']}
							style={{ display: error.length > 0 ? 'block' : 'none' }}
						>
							{error}
						</div>

						<div
							className={classes['iiris-formItemsContainer']}
							style={{ gridTemplateRows: `repeat(1, 1fr)`, gridTemplateColumns: `repeat(1, 1fr)` }}
						>
							{orderedQuestions.map((question, index, questions) => {
								let profile_id = question.profile_id ? question.profile_id : question.id
								return (
									<div
										key={profile_id}
										className={classes['iiris-form-container-custom']}
										style={{
											gridColumnStart: Number(question.layout.cols),
											gridColumnEnd: questions[index + 1]?.layout?.rows === question.layout.rows ? questions[index + 1].layout.cols : Number(gridCols) + 1,
											gridRowStart: Number(question.layout.rows),
											gridRowEnd: Number(question.layout.rows) + 1,
										}}
									>
										<div className='iiris-form-group' style={{ flexGrow: 1 }}>
											{question.type !== 'checkbox' && ('render' in question ? question.render : true) && (
												<label className={classes['iiris-form-label']}>
													{getQuestionTitle(question)}:
												</label>
											)}

											{question.required === true && ('render' in question ? question.render : true) && (
												<label style={{ color: "red", marginLeft: "3px" }}>*</label>
											)}

											{question.type === 'chips' && !question.config.filter(
												(configObj) =>
													configObj.brand === brand &&
													configObj.division === division &&
													configObj.locale === language
											)[0]?.hasOwnProperty('type') && ('render' in question ? question.render : true) && (
													<>
														<div className={classes['iiris-chips']}>
															<div className={classesMat.root}>
																{filteredQuestionData[`page${pageNumber}`] &&
																	filteredQuestionData[`page${pageNumber}`][
																		profile_id
																	]?.map((bType) => (
																		<Chip
																			className={
																				questionSelection[pageNumber - 1][
																					`page${pageNumber}`
																				][index][profile_id] === bType.id
																					? classes['iiris-chipSelected']
																					: classes['']
																			}
																			variant='outlined'
																			size='large'
																			icon={
																				<Icon
																					icon={bType.icon}
																					color={bType.color}
																					height='23'
																					width='23'
																				/>
																			}
																			label={bType.name}
																			onClick={() => {
																				handleChipClick(profile_id, bType, index)
																			}}
																		/>
																	))}

															</div>
														</div>
														<div
															className={
																classes[hasError(profile_id) ? "iiris-error-space" : "iiris-hidden"]
															}
														>
															{t('please_select')} {getQuestionTitle(question).toLowerCase()}
														</div>
													</>
												)}

											{question.type === 'multiselect-list' && ('render' in question ? question.render : true) && (
												<>
													<div style={{ border: '1px solid #ced4da', borderRadius: '.25rem' }}>
														<div className={classes['iiris-list-container']}>
															{filteredQuestionData[`page${pageNumber}`] &&
																filteredQuestionData[`page${pageNumber}`][
																	profile_id
																]?.map((item, index) => (
																	<div key={index}>
																		<input value={item.id} type="checkbox" onChange={handleListCheck} checked={checkedListItems.map(item => item.id).includes(item.id)} />
																		<span style={{ marginLeft: '10px' }}>{item.name}</span>
																	</div>
																))}
														</div>
													</div>
													<div
														className={
															classes[hasError(profile_id) ? "iiris-error-space" : "iiris-hidden"]
														}
													>
														{t('please_select')} {getQuestionTitle(question).toLowerCase()}
													</div>
												</>
											)}
											{question.type === 'text' && profile_id !== 'company' && ('render' in question ? question.render : true) && (
												<>
													<input
														type='text'
														className='iiris-form-control'
														name={profile_id}
														maxlength='120'
														value={
															questionSelection[pageNumber - 1][
															`page${pageNumber}`
															][index][profile_id]
														}
														onChange={(e) =>
															handleTextInputChange(
																profile_id,
																e.target.value,
																index
															)
														}
														style={{ fontSize: '14px' }}
													/>
													<div
														className={
															classes[hasError(profile_id) ? "iiris-error-space" : "iiris-hidden"]
														}
													>
														{t('please_enter')} {getQuestionTitle(question).toLowerCase()}
													</div>
												</>
											)}

											{question.type === 'text' && profile_id === 'company' && ('render' in question ? question.render : true) && (
												<div className={classes['iiris-clearable-input']}>
													<TextInput
														value={
															questionSelection[pageNumber - 1][
															`page${pageNumber}`
															][index][profile_id]
														}
														className='iiris-form-control'
														trigger=""
														Component="input"
														options={companyData['informa.com']?.company?.aliases}
														onChange={(val) =>
															handleTextInputChange(
																profile_id,
																val,
																index
															)
														}
													/>
													<button
														className={classes[questionSelection[pageNumber - 1][
															`page${pageNumber}`
														][index][profile_id].length === 0 ? "iiris-hidden" : 'iiris-clear-btn']}
														onClick={(e) => clearInput(e, profile_id, index)}
													>
														X
													</button>
													<div
														className={
															classes[hasError(profile_id) ? "iiris-error-space" : "iiris-hidden"]
														}
													>
														{t('please_enter')} {getQuestionTitle(question).toLowerCase()}
													</div>
												</div>
											)}

											{(question.type === 'dropdown' || (question.config?.filter(
												(configObj) =>
													configObj.brand === brand &&
													configObj.division === division &&
													configObj.locale === language
											)[0]?.hasOwnProperty('type') && question.config.filter(
												(configObj) =>
													configObj.brand === brand &&
													configObj.division === division &&
													configObj.locale === language
											)[0].type === 'dropdown')) && ('render' in question ? question.render : true) && (
													<>
														<div>
															<CustomSelectComponent
																isDisabled={false}
																text={
																	questionSelection[pageNumber - 1][
																	`page${pageNumber}`
																	][index][profile_id]
																}
																data={
																	filteredQuestionData[`page${pageNumber}`]
																		? filteredQuestionData[`page${pageNumber}`][
																		profile_id
																		]
																		: []
																}
																OnChange={(e) =>
																	onDropdownChange(profile_id, e, index)
																}
																isSearchable={true}
																label='name'
																value='name'
																position='bottom'
																customPosition={false}
															/>
														</div>
														<div
															className={
																classes[hasError(profile_id) ? "iiris-error-space" : "iiris-hidden"]
															}
														>
															{t('please_select')} {getQuestionTitle(question).toLowerCase()}
														</div>
													</>
												)}

											{question.type === 'checkbox' && ('render' in question ? question.render : true) && (
												<>
													<input
														type='checkbox'
														className={classes['iiris-checkbox-style']}
														onClick={(e) =>
															handleCheckboxChange(profile_id, e, index)
														}
													/>
													<span>
														{getQuestionTitle(question)}
													</span>
												</>
											)}

											{question.type === 'radio-button' && ('render' in question ? question.render : true) && (
												<>
													<div>
														{filteredQuestionData[`page${pageNumber}`] &&
															filteredQuestionData[`page${pageNumber}`][
																profile_id
															]?.map((item) => (
																<div>
																	<input value={item.name}
																		name='iris-radio-button-question'
																		className={classes['iiris-form-label']}
																		type="radio"
																		id={item.id}
																		onClick={(e) =>
																			handleRadioButton(profile_id, e, index)
																		} />
																	<label className="iiris-form-check-label" for={item.id}>{item.name}</label>
																</div>
															))}
													</div>

													<div
														className={
															classes[hasError(profile_id) ? "iiris-error-space" : "iiris-hidden"]
														}
													>
														{t('please_select')} {getQuestionTitle(question).toLowerCase()}
													</div>
												</>
											)}
										</div>
									</div>
								)
							})}
						</div>

						{pages.length > 1 && pageNumber !== 1 && (
							<div className="iiris-text-center">
								<div className={classes['iiris-register-button-container']}>
									<button
										onClick={(e) => handlePageClick(e, 'prev')}
										className={`${classes['iiris-social-btn']} iiris-btn iiris-btn-block `}
										style={{
											color: identityConfig.skin.skin_color,
											background: identityConfig.skin.skin_background,
										}}
									>
										Prev Page
									</button>
								</div>
							</div>
						)}

						{pages.length > 1 && pageNumber !== pages.length && (
							<div className="iiris-text-center">
								<div className={classes['iiris-register-button-container']}>
									<button
										onClick={(e) => handlePageClick(e, 'next')}
										className={`${classes['iiris-social-btn']} iiris-btn iiris-btn-block `}
										style={{
											color: identityConfig.skin.skin_color,
											background: identityConfig.skin.skin_background,
										}}
									>
										Next Page
									</button>
								</div>
							</div>
						)}

						<div className='iiris-text-center iiris-btn-center'>
							<div className={classes['iiris-register-button-container']}>
								<button
									onClick={finishSignUp}
									type='button'
									className={`${classes['iiris-social-btn']} iiris-btn iiris-btn-block `}
									style={{
										color: identityConfig.skin.skin_color,
										background: identityConfig.skin.skin_background,
									}}
								>
									{t('submit')}
								</button>
							</div>
						</div>
					</form>
				</div>
			)}
		</div>
	)
}