// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iiris-y1nTB {\n  display: inline-block;\n  width: 50px;\n  height: 50px;\n  border: 3px solid rgba(3, 114, 158, 0.3);\n  border-radius: 50%;\n  border-top-color: teal;\n  animation: iiris-APj7R 1s ease-in-out infinite;\n  -webkit-animation: iiris-APj7R 1s ease-in-out infinite;\n}\n\n@keyframes iiris-APj7R {\n  to {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes iiris-APj7R {\n  to {\n    -webkit-transform: rotate(360deg);\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/identity/dependents/common/LoadingContent/LoadingContent.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,wCAAwC;EACxC,kBAAkB;EAClB,sBAAsB;EACtB,8CAAuC;EACvC,sDAA+C;AACjD;;AAEA;EACE;IACE,iCAAiC;EACnC;AACF;AACA;EACE;IACE,iCAAiC;EACnC;AACF","sourcesContent":[".iiris-loading {\n  display: inline-block;\n  width: 50px;\n  height: 50px;\n  border: 3px solid rgba(3, 114, 158, 0.3);\n  border-radius: 50%;\n  border-top-color: teal;\n  animation: spin 1s ease-in-out infinite;\n  -webkit-animation: spin 1s ease-in-out infinite;\n}\n\n@keyframes spin {\n  to {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes spin {\n  to {\n    -webkit-transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iiris-loading": "iiris-y1nTB",
	"spin": "iiris-APj7R"
};
export default ___CSS_LOADER_EXPORT___;
