import React from "react";
import { useTranslation } from "react-i18next";
import { FacebookShareButton, LinkedinShareButton } from "react-share";
import { FacebookIcon, LinkedinIcon } from "react-share";

import classes from "./UserDetails.module.css";

export const UserDetails = ({ identityConfig, userObj }) => {
	const {	showShareIcons } = identityConfig
	const { t } = useTranslation();
	const showFacebook = showShareIcons.includes("facebook");
	const showLinkedin = showShareIcons.includes("linkedin");

	return (
    <div className={classes.iiris_userDetailsContainer}>
      <p className={classes.iiris_userDetailsText}>
        {t("welcome")} {userObj.given_name} {userObj.family_name}!
      </p>
      <div className={classes.iiris_shareIconsContainer}>
        {showFacebook && (
          <FacebookShareButton url={window.location.href} hashtag="#event">
            <FacebookIcon size={32} round />
          </FacebookShareButton>
        )}
        {showLinkedin && (
          <LinkedinShareButton
            url={window.location.href}
            title="Hey, I am going to this event!!!"
            summary="Hey, I am going to this event!!!"
            description="Hey, I am going to this event!!!"
            source="Hey, I am going to this event!!!"
          >
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        )}
      </div>
      <button
        className="iiris-btn iiris-btn-block"
        style={{
          color: identityConfig.skin.skin_color,
          background: identityConfig.skin.skin_background,
        }}
        onClick={() => identityConfig.toggleModal()}
      >
        Continue to site
      </button>
    </div>
  );
};
