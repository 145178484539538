import React, { useRef, useState, useEffect } from "react";
import styled from "@emotion/styled";
import Select from "react-dropdown-select";
import SelectSearch from 'react-select-search';

import './CustomSelectComponent.css';

//Styled Select
const StyledSelect = styled(Select)`
  background: #fff;
  border: 1px solid #ced4da !important;
  color: #495057;
  padding-left:10px !important;
  min-height: 38px !important;

  :focus {
    border-color: #80bdff !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%) !important;
  }

  :focus-within {
    border-color: #80bdff !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%) !important;
  }

  ::placeholder {
    color: black;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: black;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
   color: black;
  }
  
  .react-dropdown-select-clear,
  .react-dropdown-select-dropdown-handle {
    color: #000000;
  }
  .react-dropdown-select-option {
    border: 1px solid #ced4da;
  }
  .react-dropdown-select-item {
    color: #495057;
  }
  .react-dropdown-select-input {
    color: #495057;
  }

  .react-dropdown-select-dropdown {
    position: absolute;
    left: 0;
    border: 1px #ced4da;
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    max-height: 250px;
    overflow-x: hidden;
    z-index: 55555;
    background: #fff;
    color: #000000 !important;
  }
  .react-dropdown-select-item {
    color: #000000;

    :hover {
      color: #000000;
      background: rgb(187 218 224 / 25%);
    }
    :focus {
      background: rgb(187 218 224 / 25%);
    }
    ::placeholder {
      color: black;
      opacity: 1; /* Firefox */
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
     color: black;
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
     color: black;
    }
  }

  .react-dropdown-select-content {
    flex-wrap: nowrap;
  }

  .react-dropdown-select-item.react-dropdown-select-item-selected,
  .react-dropdown-select-item.react-dropdown-select-item-active {
    color: #000000;
    background: rgb(236 248 251);
    font-weight: 600;
  }
  .react-dropdown-select-item.react-dropdown-select-item-disabled {
    background: #777;
    color: #ccc;
  }
  
`;

//Styled Select
const CustomStyledSelect = styled.span`
  .select-search__options {
    top: calc(100% + 7px);
  }
`;

export const CustomSelectComponent = ({
  data,
  text,
  isSearchable,
  label,
  value,
  OnChange,
  selectedValue,
  isDisabled,
  position,
  customPosition
}) => {
  const searchInput = useRef();
  const [key, setKey] = useState(0);

  let newData = customPosition ? data?.map(e => {
    e.value ? '' : e.value = e[value];
    return e;
  }) : [];

  const handleFilter = (items) => {
    return (searchValue) => {
      if (searchValue.length === 0) {
        return newData;
      }
      const updatedItems = items.filter((list) => {
        return list[label].toLowerCase().includes(searchValue.toLowerCase());
      });
      return updatedItems;
    };
  };

  // Re-renders the dropdown whenever the data changes
  useEffect(() => {
    setKey(key => key + 1);
  }, [data]);

  return (
    <>
      {customPosition === true ? (
        <CustomStyledSelect>
          <SelectSearch
            ref={searchInput}
            options={newData}
            name={label}
            value={selectedValue}
            type="No"
            search={isSearchable}
            searchBy={label}
            placeholder={text}
            onChange={OnChange}
            disabled={isDisabled}
            dropdownPosition={position}
            filterOptions={handleFilter}
          />
        </CustomStyledSelect>
      ) : (
        <StyledSelect
          options={data}
          labelField={label}
          valueField={value}
          searchable={isSearchable}
          searchBy={label}
          onChange={OnChange}
          disabled={isDisabled}
          dropdownPosition={position}
          key={key}
        />
      )}
    </>
  );
};