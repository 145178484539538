const resources = {
	en: {
		translation: {
			// Sign up page
			sign_up: "Sign up using one of these options",
			already_have: "Already have an account?",
			log_in: "Log in",
			powered_by: "Powered By",
			first_name: "First Name",
			please_first: "Please enter first name",
			last_name: "Last Name",
			please_last: "Please enter last name",
			business_email: "Business Email",
			valid_email: "Please enter valid email",
			invalid_user_status: "Invalid user status, please contact customer service for help",
			email_already_exist: "Business email already exists. Please click",
			email_already_exist_need_password_reset: "Business email already exists, but needs password reset. Please click",
			here: "here",
			to_login_in: "to log in",
			to_reset_password: "to reset password",
			country: "Country",
			please_country: "Please select country",
			state: "State/Province",
			please_state: "Please select state",
			password: "Password",
			please_password: "Please enter password as per password policy",
			confirm_password: "Confirm Password",
			please_confirm_password: "Please enter valid confirm password",
			news_promotions:
				"We’d like to keep in touch to send your news and promotions from Informa about products and services relevant to you. These may include information from our selected partners however, we will not share your details unless you agree to us doing so directly.",
			please_consent: "Please provide your consent",
			tick_here: "Tick here if you",
			do_not: "do not",
			want_to_receive:
				"want to receive news and promotions from Informa about products and services relevant to you. These may include information from our selected partners however, we will not share your details unless you agree to us doing so directly.",
			by_continuing:
				"By continuing you agree that Informa may contact you with updates about products and services relevant to you. These may include information from our selected partners.",
			i_agree: "I agree to the",
			privacy_policy: "Privacy Policy",
			please_privacy_policy: "Please accept Privacy Policy",
			opt_out:
				"You can opt out at any time by clicking unsubscribe link in our emails or contacting us. You can find more information in our",
			please_captcha: "Please confirm captcha",
			sign_up_email: "Sign up with Email",
			eight_char: "At least 8 characters",
			lower_case: "At least 1 lower case character",
			upper_case: "At least 1 upper case character",
			special_char: "At least 1 special character",
			one_number: "At least 1 number",
			passwords_match: "Passwords match",
			select_country: "Select Country",
			not_applicable: "Not Applicable",
			select_state: "Select State",
			privacy_policy_text:
				"You can opt out at any time by clicking unsubscribe link in our emails or contacting us. You can find more information in our Privacy Policy",
			tick_here_text:
				"Tick here if you do not want to receive news and promotions from Informa about products and services relevant to you. These may include information from our selected partners however, we will not share your details unless you agree to us doing so directly.",
			FP_consent_text:
				"We would like to keep in touch to send your news and promotions from Informa about products and services relevant to you. These may include information from our selected partners however, we will not share your details unless you agree to us doing so directly.",
			terms_and_condition: "Your IIRIS account gives you access to a whole range of Informa products, meaning that you will not need to enter these details again. For more information about how Informa uses your personal data, please read our Privacy Policy.",
			
			// Sign in page
			sign_in: "Log in using one of these options",
			only_sign_in: "Log in with email",
			or: "Or",
			code_instead: "Log in with code",
			send_verification: "Send Verification code",
			only_back: "Back",
			havent_joined: "Don’t have an account?",
			register: "Register here",
			enter_password: "Please enter password",
			forgot: "Forgot your password?",
			sign_in_email: "Log in with email",
			email: "Email",
			incorrect_username_password: "Incorrect username or password",

			// ForgetPassword page
			forgot_password: "Forgot Password?",
			send_instructions:
				"We will send you instructions to reset your password.",
			submit: "Submit",
			back_sign_in: "Back to Sign In",
			reset_password: "Reset Password",
			must_verification_code:
				"You must have received an email with the verification code.",
			check_email: "Please check your email.",
			verification_code: "Verification Code",
			please_verification_code: "Please enter valid verification code",
			new_password: "New Password",
			back: "back",
			password_reset_successful: "Password Reset Successful",
			sign_in_new_password:
				"Please sign in with new password to continue using the site.",
			email_error:
				"Email not found. Please enter the email you used to create your account. In case of difficulties, please contact customer service for help.",

			// Progressive Profile
			welcome: "Welcome",
			please_tell_us: "Please tell us about yourself",
			please_select: "Please select",
			please_enter: "Please enter",
			sign_up_for_the: "Sign up for the",
			newsletter: "newsletter",
			error_registration:
				"Error occurred during registration. Please contact system administrator.",
			loading: "Please wait while we process your request...",

			// Work email page
			more_details: "Please provide more details",
			current_email: "your current business email ?",
			yes: "YES",
			no: "NO",
			continue: "Continue",
			missing_access_right: 'Wrong credentials or missing access rights to the application',
			incorrect_email_entered: 'Email not found. Please enter the email you used to register or create an account.',
			wrong_otp: "The code you entered is incorrect or expired.",
			send_verification_code_note : "To help protect your account and verify your identity, we’ll send you a verification code.",
			otp_send_confirmation: "A verification code was sent to your email -",
			otp_send_confirmation_check_spam: "Please check your spam and junk folder as well.",
			resend: "Resend",
			didnt_receive_code: "Didn't receive verifcation code? ",
			verify: "Verify",
			retry: "Retry",
			please_enter_code: "Please enter login verification code",
			send_verification_code: "Send Verification Code",
			incorrect_code: "Incorrect code",
			attempt: "attempt",
			remaining: "remaining",
			code_expire_in: "Your code will expire in",
			login_user_not_found: "Business email does not exist. Please use the 'Register here' link above to register.",
			login_not_authorized: "Business email exists, but the password specified is incorrect. Please re-enter or consider using 'Forgot your password?' link below to reset password.",
			login_password_reset_required: "Business email exists, but needs password reset. Please use the 'Forgot your password?' link below to reset password.",
			login_user_not_confirmed: "Business email exists, but has incorrect setup. Please contact customer service for help.",
			login_default_error: "Login Error. Please contact customer service for help.",
			disabled_popup: "Please disable your pop-up blocker and click on the same link again.",
			try_after_sometime: "You have exceeded password reset limit. Please try after 30 mins.",
			error_while_reset: "Error occurred while resetting password. Please contact customer service for help."
		},
	},
	fr: {
		translation: {
			// Sign up page
			sign_up: "Inscrivez-vous en utilisant l'une de ces options",
			already_have: "Vous avez déjà un compte?",
			log_in: "Connexion",
			powered_by: "Alimenté par",
			first_name: "Prénom",
			please_first: "Veuillez entrer le prénom",
			last_name: "Nom de famille",
			please_last: "Veuillez entrer le nom de famille",
			business_email: "E-mail professionnel",
			valid_email: "Veuillez saisir une adresse e-mail valide",
			invalid_user_status: "Statut d'utilisateur non valide, veuillez contacter service client pour aider",
			email_already_exist: "La messagerie professionnelle existe déjà. Cliquez s'il vous plait",
			email_already_exist_need_password_reset: "La messagerie professionnelle existe déjà, mais le mot de passe doit être réinitialisé. Cliquez s'il vous plait",
			here: "ici",
			to_login_in: "ouvrir une session",
			to_reset_password: "pour réinitialiser le mot de passe",
			country: "De campagne",
			please_country: "Veuillez sélectionner le pays",
			state: "État/Province",
			please_state: "Please select state",
			password: "Mot de passe",
			please_password:
				"Veuillez entrer le mot de passe conformément à la politique de mot de passe",
			confirm_password: "Confirmez le mot de passe",
			please_confirm_password:
				"Veuillez saisir un mot de passe de confirmation valide",
			news_promotions:
				"Nous aimerions rester en contact pour vous envoyer des informations sur les produits et services d’Informa qui peuvent vous intéresser. Certaines communications peuvent inclure des informations de Partenaires  sélectionnés , cependant, nous ne partagerons pas vos coordonnées à moins que vous acceptiez explicitement de le faire directement.",
			please_consent: "Veuillez donner votre consentement",
			tick_here: "Cochez ici si vous",
			do_not: "ne pas",
			want_to_receive:
				"souhaitez recevoir des informations et des promotions d'Informa sur les produits et services qui vous concernent. Celles-ci peuvent inclure des informations de nos partenaires sélectionnés, cependant, nous ne partagerons pas vos coordonnées à moins que vous ne nous acceptiez de le faire directement.",
			by_continuing:
				"En continuant, vous acceptez qu'Informa puisse vous contacter avec des mises à jour sur les produits et services qui vous concernent. Certaines communications peuvent inclure des informations de Partenaires  sélectionnés.",
			i_agree: "J'accepte la",
			privacy_policy: "Politique de confidentialité",
			please_privacy_policy:
				"Veuillez accepter la politique de confidentialité",
			opt_out:
				"Vous pouvez vous désinscrire à tout moment en cliquant sur le lien de désabonnement dans nos e-mails ou en nous contactant directement. Vous pouvez trouver plus d'informations dans notre",
			please_captcha: "Veuillez confirmer le captcha",
			sign_up_email: "S'enregistrer avec l'adresse e-mail",
			eight_char: "Au moins 8 caractères",
			lower_case: "Au moins 1 caractère minuscule",
			upper_case: "Au moins 1 caractère majuscule",
			special_char: "Au moins 1 caractère spécial",
			one_number: "Au moins 1 numéro",
			passwords_match: "Les mots de passe correspondent",
			select_country: "Choisissez le pays",
			not_applicable: "N'est pas applicable",
			select_state: "Sélectionnez l'état",
			privacy_policy_text:
				"Vous pouvez vous désabonner à tout moment en cliquant sur le lien de désabonnement dans nos e-mails ou en nous contactant. Vous pouvez trouver plus d'informations dans notre politique de confidentialité",
			tick_here_text:
				"Cochez ici si vous ne souhaitez pas recevoir d'actualités et de promotions d'Informa sur les produits et services qui vous concernent. Certaines communications peuvent inclure des informations de Partenaires sélectionnés, cependant, nous ne partagerons pas vos coordonnées à moins que vous acceptiez explicitement de le faire.",
			FP_consent_text:
				"Nous aimerions rester en contact pour vous envoyer des nouvelles et des promotions d'Informa sur les produits et services qui vous intéressent. Celles-ci peuvent inclure des informations de nos partenaires sélectionnés, cependant, nous ne partagerons pas vos coordonnées à moins que vous ne nous acceptiez de le faire directement.",
			terms_and_condition: "Votre compte IIRIS vous donne accès à toute une gamme de produits Informa, ce qui signifie que vous n'aurez pas besoin de saisir à nouveau ces informations. Pour plus d'informations sur la manière dont Informa utilise vos données personnelles, veuillez lire notre Politique de confidentialité.",
			
			// Sign in page
			sign_in: "Connectez-vous en utilisant l'une de ces options",
			only_sign_in: "S'identifier",
			or: "Ou",
			code_instead: "Connectez-vous avec le code à la place",
			send_verification: "Envoyer le code de vérification",
			only_back: "Retour",
			havent_joined: "Vous n'avez pas de compte ?",
			register: "Inscrivez-vous ici",
			enter_password: "Veuillez entrer le mot de passe",
			forgot: "Mot de passe oublié?",
			sign_in_email: "Connectez-vous avec un e-mail",
			email: "E-mail",
			incorrect_username_password: "identifiant ou mot de passe incorrect",

			// ForgetPassword page
			forgot_password: "Mot de passe oublié?",
			send_instructions:
				"Nous vous enverrons des instructions pour réinitialiser votre mot de passe.",
			submit: "Soumettre",
			back_sign_in: "Retour à la connexion",
			reset_password: "réinitialiser le mot de passe",
			must_verification_code:
				"Vous devez avoir reçu un e-mail avec le code de vérification.",
			check_email: "Merci de consulter vos emails.",
			verification_code: "Le code de vérification",
			please_verification_code:
				"Veuillez entrer un code de vérification valide",
			new_password: "nouveau mot de passe",
			back: "retour",
			password_reset_successful: "Réinitialisation du mot de passe réussie",
			sign_in_new_password:
				"Veuillez vous connecter avec un nouveau mot de passe pour continuer à utiliser le site.",
			email_error:
				"Email non trouvé. Veuillez entrer l'e-mail que vous avez utilisé pour créer votre compte. En cas de difficultés, veuillez contacter service client pour obtenir de l'aide.",

			// Progressive Profile
			welcome: "Bienvenu",
			please_tell_us: "Veuillez nous parler de vous",
			please_select: "Veuillez sélectionner",
			please_enter: "Entrez s'il vous plait",
			sign_up_for_the: "Inscrivez-vous pour le",
			newsletter: "bulletin",
			error_registration:
				"Une erreur s'est produite lors de l'enregistrement. Veuillez contacter l'administrateur système.",
			loading:
				"Veuillez patienter pendant que nous accédons à votre requête...",

			// Work email page
			more_details: "Veuillez fournir plus de détails",
			current_email: "votre e-mail professionnel actuel ?",
			yes: "OUI",
			no: "NON",
			continue: "Continuer",
			missing_access_right: "Identifiants erronés ou droits d'accès manquants à l'application",
			incorrect_email_entered: "Email non trouvé. Veuillez entrer l'e-mail que vous avez utilisé pour vous inscrire ou créer un compte.",
			wrong_otp: "Le code que vous avez entré est incorrect ou a expiré",
			send_verification_code_note: "Pour aider à protéger votre compte et vérifier votre identité, nous vous enverrons un code de vérification.",
			otp_send_confirmation: "Un code de vérification a été envoyé à votre adresse e-mail -",
			otp_send_confirmation_check_spam: "Veuillez également vérifier votre dossier spam et courrier indésirable.",
			resend: "Renvoyer",
			didnt_receive_code: "Vous n'avez pas reçu le code de vérification ? ",
			verify: "Vérifier",
			retry: "Retenter",
			please_enter_code: "Veuillez entrer le code de vérification de connexion",
			send_verification_code: "Envoyer le code de vérification",
			incorrect_code: "code incorrect",
			attempt: "tenter",
			remaining: "restant",
			code_expire_in: "Votre code expirera dans",
			login_user_not_found: "La messagerie professionnelle n'existe pas. Veuillez utiliser le lien « Inscrivez-vous ici » ci-dessus pour vous inscrire.",
			login_not_authorized: "Une adresse e-mail professionnelle existe, mais le mot de passe spécifié est incorrect. Veuillez saisir à nouveau ou envisager d'utiliser 'Mot de passe oublié ?' lien ci-dessous pour réinitialiser le mot de passe.",
			login_password_reset_required: "La messagerie professionnelle existe, mais le mot de passe doit être réinitialisé. Veuillez utiliser le champ 'Mot de passe oublié ?' lien ci-dessous pour réinitialiser le mot de passe.",
			login_user_not_confirmed: "La messagerie professionnelle existe, mais sa configuration est incorrecte. Veuillez contacter service client pour obtenir de l'aide.",
			login_default_error: "Erreur de début de session. Póngase en contacto con service client para obtener ayuda.",
			disabled_popup: "Veuillez désactiver votre bloqueur de pop-up et cliquer à nouveau sur le même lien.",
			try_after_sometime: "Vous avez dépassé la limite de réinitialisation du mot de passe. Veuillez essayer après 30 minutes.",
			error_while_reset: "Une erreur s'est produite lors de la réinitialisation du mot de passe. Veuillez contacter service client pour obtenir de l'aide."
		},
	},
	es: {
		translation: {
			sign_up: "Regístrese usando una de estas opciones",
			already_have: "Ya tienes una cuenta?",
			log_in: "Iniciar sesión",
			powered_by: "Energizado por",
			first_name: "Primer nombre",
			please_first: "Por favor, introduzca el nombre",
			last_name: "Apellido",
			please_last: "Por favor ingrese apellido",
			business_email: "email de negocios",
			valid_email: "Por favor introduzca un correo electrónico válido",
			invalid_user_status: "El correo electrónico comercial ya existe, pero tiene una configuración incorrecta. Póngase en contacto con Servicio al Cliente para obtener ayuda",
			email_already_exist: "El correo electrónico comercial ya existe. Por favor haz click",
			email_already_exist_need_password_reset: "El correo electrónico comercial ya existe, pero necesita restablecer la contraseña. Por favor haz click",
			here: "aquí",
			to_login_in: "iniciar sesión",
			to_reset_password: "para restablecer la contraseña",
			country: "País",
			please_country: "Por favor seleccione país",
			state: "Provincia del estado",
			please_state: "Por favor seleccione estado",
			password: "Clave",
			please_password: "Ingrese la contraseña según la política de contraseñas",
			confirm_password: "Confirmar contraseña",
			please_confirm_password: "Por favor, introduzca una contraseña de confirmación válida",
			news_promotions:
				"Nos gustaría mantenernos en contacto para enviar sus noticias y promociones de Informa sobre productos y servicios relevantes para usted. Estos pueden incluir información de nuestros socios seleccionados; sin embargo, no compartiremos sus datos a menos que acepte que lo hagamos directamente.",
			please_consent: "Por favor proporcione su consentimiento",
			tick_here: "Marque aquí si Ud.",
			do_not: "no haga",
			want_to_receive:
				"desea recibir noticias y promociones de Informa sobre productos y servicios relevantes para usted. Estos pueden incluir información de nuestros socios seleccionados; sin embargo, no compartiremos sus datos a menos que acepte que lo hagamos directamente.",
			by_continuing:
				"Al continuar, acepta que Informa puede contactarlo con actualizaciones sobre productos y servicios relevantes para usted. Estos pueden incluir información de nuestros socios seleccionados.",
			i_agree: "Estoy de acuerdo con la",
			privacy_policy: "Política de privacidad",
			please_privacy_policy: "Por favor acepte la Política de Privacidad",
			opt_out:
				"Puede darse de baja en cualquier momento haciendo clic en el enlace para darse de baja en nuestros correos electrónicos o poniéndose en contacto con nosotros. Puede encontrar más información en nuestro",
			please_captcha: "Por favor confirme el captcha",
			sign_up_email: "Ingresa con e-mail",
			eight_char: "Al menos 8 carácteres",
			lower_case: "Al menos 1 carácter en minúscula",
			upper_case: "Al menos 1 carácter en mayúscula",
			special_char: "Al menos 1 carácter especial",
			one_number: "Al menos 1 número",
			passwords_match: "Las contraseñas coinciden",
			select_country: "Seleccionar país",
			not_applicable: "No aplica",
			select_state: "Seleccione estado",
			privacy_policy_text:
				"Puede darse de baja en cualquier momento haciendo clic en el enlace para darse de baja en nuestros correos electrónicos o poniéndose en contacto con nosotros. Puede encontrar más información en nuestra Política de Privacidad",
			tick_here_text:
				"Marque aquí si no desea recibir noticias y promociones de Informa sobre productos y servicios relevantes para usted. Estos pueden incluir información de nuestros socios seleccionados; sin embargo, no compartiremos sus datos a menos que acepte que lo hagamos directamente.",
			FP_consent_text:
				"Nos gustaría mantenernos en contacto para enviar sus noticias y promociones de Informa sobre productos y servicios relevantes para usted. Estos pueden incluir información de nuestros socios seleccionados; sin embargo, no compartiremos sus datos a menos que acepte que lo hagamos directamente.",
			terms_and_condition: "Su cuenta IIRIS le brinda acceso a una amplia gama de productos de Informa, lo que significa que no necesitará ingresar estos detalles nuevamente. Para obtener más información sobre cómo Informa utiliza sus datos personales, lea nuestra Política de privacidad",
			
			sign_in: "Inicie sesión usando una de estas opciones",
			only_sign_in: "Iniciar sesión",
			or: "O",
			code_instead: "Inicie sesión con código en su lugar",
			send_verification: "Envíe el código de verificación",
			only_back: "atrás",
			havent_joined: "No tienes una cuenta?",
			register: "Registrarse aquí",
			enter_password: "Por favor, ingrese contraseña",
			forgot: "Olvidaste tu contraseña?",
			sign_in_email: "Iniciar sesión con correo electrónico",
			email: "Correo electrónico",
			incorrect_username_password: "Nombre de usuario o contraseña incorrecta",

			forgot_password: "Has olvidado tu contraseña?",
			send_instructions:
				"Le enviaremos instrucciones para restablecer su contraseña.",
			submit: "Enviar",
			back_sign_in: "Volver a iniciar sesión",
			reset_password: "Restablecer la contraseña",
			must_verification_code:
				"Debes haber recibido un correo electrónico con el código de verificación.",
			check_email: "Por favor revise su correo electrónico.",
			verification_code: "Código de verificación",
			please_verification_code: "Por favor, introduzca un código de verificación válido",
			new_password: "Nueva contraseña",
			back: "espalda",
			password_reset_successful: "Restablecimiento de contraseña exitosor",
			sign_in_new_password:
				"Inicie sesión con una nueva contraseña para continuar usando el sitio.",
			email_error:
				"El correo electrónico no encontrado. Por favor, introduzca el correo electrónico que utilizó para crear su cuenta. En caso de dificultades, comuníquese con Servicio al Cliente para obtener ayuda.",

			welcome: "Bienvenidas",
			please_tell_us: "Por favor, cuéntenos acerca de usted",
			please_select: "Por favor seleccione",
			please_enter: "Por favor escribe",
			sign_up_for_the: "Regístrese para el",
			newsletter: "Boletin informativo",
			error_registration:
				"Ocurrió un error durante el registro. Póngase en contacto con el administrador del sistema.",
			loading: "Espere mientras procesamos su solicitud...",

			more_details: "Proporcione más detalles",
			current_email: "su correo electrónico comercial actual?",
			yes: "SÍ",
			no: "NO",
			continue: "Continuar",
			missing_access_right: 'Credenciales incorrectas o falta de derechos de acceso a la aplicación',
			incorrect_email_entered: 'El correo electrónico no encontrado. Ingrese el correo electrónico que utilizó para registrarse o crear una cuenta.',
			wrong_otp: "El código que ingresó es incorrecto o está vencido.",
			send_verification_code_note : "Para ayudar a proteger su cuenta y verificar su identidad, le enviaremos un código de verificación.",
			otp_send_confirmation: "Se envió un código de verificación a su correo electrónico:",
			otp_send_confirmation_check_spam: "Por favor revise su carpeta de spam y basura también.",
			resend: "reenviar",
			didnt_receive_code: "No recibiste el código de verificación?",
			verify: "Verificar",
			retry: "Rever",
			please_enter_code: "Por favor, introduzca el código de verificación de inicio de sesión",
			send_verification_code: "Envíe el código de verificación",
			incorrect_code: "código incorrecto",
			attempt: "intentar",
			remaining: "restante",
			code_expire_in: "Su código caducará en",
			login_user_not_found: "El correo electrónico comercial no existe. Utilice el enlace 'Registrarse aquí' de arriba para registrarse.",
			login_not_authorized: "El correo electrónico comercial existe, pero la contraseña especificada es incorrecta. Vuelva a ingresar o considere usar '¿Olvidó su contraseña?' enlace a continuación para restablecer la contraseña.",
			login_password_reset_required: "El correo electrónico comercial existe, pero necesita restablecer la contraseña. Utilice la opción '¿Olvidó su contraseña?' enlace a continuación para restablecer la contraseña.",
			login_user_not_confirmed: "El correo electrónico comercial existe, pero tiene una configuración incorrecta. Póngase en contacto con Servicio al Cliente para obtener ayuda.",
			login_default_error: "Error de inicio de sesión. Póngase en contacto con Servicio al Cliente para obtener ayuda.",
			disabled_popup: "Desactive su bloqueador de ventanas emergentes y haga clic en el mismo enlace nuevamente.",
			try_after_sometime: "Ha superado el límite de restablecimiento de contraseña. Intente después de 30 minutos.",
			error_while_reset: "Ocurrió un error al restablecer la contraseña. Póngase en contacto con Servicio al Cliente para obtener ayuda."
		},
	},
};

export default resources;
