import React, { useState, useRef } from "react";
import OTPInput from "otp-input-react";
import ReCAPTCHA from 'react-google-recaptcha'
import HCaptcha from "@hcaptcha/react-hcaptcha";
import isEmail from 'validator/lib/isEmail';
import { useTranslation } from "react-i18next";

import { MustContainItem } from "../MustContainItem";
import { SignerService } from '../../service/SignerService'
import { LoadingContent } from "../../common/LoadingContent";

import classes from "./ForgetPassword.module.css";

export const ForgetPassword = props => {
  const google_captcha_key = process.env.GOOGLE_CAPTCHA_KEY;
  const hcaptcha_key = process.env.HCAPTCHA_KEY;

  const { identityConfig } = props;
  const { t } = useTranslation();

  const [showChangePasswordScreen, setShowPasswordScreen] = useState("ENTER_EMAIL");
  const [errors, setErrors] = useState([]);
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passPolicyError, setPassPolicyError] = useState("");
  const [userName, setUserName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [OTP, setOTP] = useState("");
  const [isHuman, setIsHuman] = useState(false);
  const passwordInputRef = useRef();
  const codeInputRef = useRef();
  const emailInputRef = useRef();
  const recaptchaRef = useRef();
  const hcaptchaRef = useRef();

  // booleans for password validations
  const [containsUL, setContainsUL] = useState(false); // uppercase letter
  const [containsLL, setContainsLL] = useState(false); // lowercase letter
  const [containsN, setContainsN] = useState(false); // number
  const [containsSC, setContainsSC] = useState(false); // special character
  const [contains8C, setContains8C] = useState(false); // min 8 characters

  // labels and state boolean corresponding to each validation
  const mustContainData = [
    [t('eight_char'), contains8C],
    [t('lower_case'), containsLL],
    [t('upper_case'), containsUL],
    [t('special_char'), containsSC],
    [t('one_number'), containsN],
  ];

  //Back to Sign In Functionality
  const redirectToSignIn = () => props.setFormType("signin");

  const redirectBack = () => {
    setOTP("");
    setShowPasswordScreen("ENTER_EMAIL");
  }

  //Clear Error
  const clearObjError = obj => {
    obj.style.borderColor = "";
    setError("");
    setEmailError("");
    setPassPolicyError("");
  }

  // ReCAPTCHA Client Side
  const onCaptchaLoad = () => console.log('Captcha loaded.');

  const verifyCaptcha = res => {
    if (res) {
      setIsHuman(true);
    }
  }

  const onError = (err) => {
    console.log(`hCaptcha Error: ${err}`);
  };

  // ReCAPTCHA Expired
  const expireCaptcha = () => setIsHuman(false);

  //OnClick of Forgot Password
  const sendInstructions = () => {
    if (!validateForgotPasswordForm()) {
      return;
    }
    if (identityConfig.captcha) {
      if (identityConfig.captcha_type === "recaptcha") {
        recaptchaRef.current.reset();
      } else if (identityConfig.captcha_type === "hcaptcha") {
        hcaptchaRef.current.resetCaptcha();
      }
      setIsHuman(false);
    }
    // Send confirmation code to user's email
    const email = emailInputRef.current.value.trim();
    const username = identityConfig.email_case_insensitive ? email.toLowerCase() : email;
    setUserName(username);
    setIsLoading(true);
    const payload = {
      "locale": "en",
      "username": username
    };
    const url = identityConfig.proxyURL + '/v2.1/authorization/public/passport/forgotpassword';
    SignerService.signedAPIRequest(
      "execute-api",
      "POST",
      url,
      payload,
      // identityConfig.isAutomated
    ).then(response => {
      if (response && response.status === 'success') {
        setIsLoading(false);
        setShowPasswordScreen("ENTER_CODE");
      } else if (response && response.status === 'error') {
        setIsLoading(false);
        setEmailError(t('email_error'));
      }
    });
  }

  //OnClick of Reset Password
  const changePassword = event => {
    event.preventDefault();
    if (!validateResetForm()) {
      return;
    }
    const verificationCode = OTP;
    const newPassword = passwordInputRef.current.value;
    setIsLoading(true);
    // Collect confirmation code and new password, then
    const payload = {
      "username": userName,
      "confirmation_code": verificationCode.trim(),
      "password": newPassword
    };
    const url = identityConfig.proxyURL + '/v2.1/authorization/public/passport/confirmpassword';
    SignerService.signedAPIRequest(
      "execute-api",
      "POST",
      url,
      payload
    ).then(response => {
      if (response && response.status === 'success') {
        setIsLoading(false);
        setShowPasswordScreen("SUCCESS");
      } else if (response && response.status === 'error') {
        setIsLoading(false);
        if (response.message === 'LimitExceededException') {
          setError(t("try_after_sometime"));
        } else {
          setError(t("error_while_reset"));
        }
      }
    });
  }

  //Validate Forgot Password Form
  const validateForgotPasswordForm = () => {
    const enteredEmail = emailInputRef.current.value.trim();
    let isValid = true;
    const errs = [];
    if (enteredEmail === "") {
      isValid = false;
      errs.push("emailInputRef");
    }
    //email validation
    var validEmail = isEmail(enteredEmail);
    if (!validEmail) {
      isValid = false;
      errs.push("emailInputRef");
    }
    if (identityConfig.captcha && !isHuman) {
      isValid = false;
      errs.push('captcha');
    }
    if (!isValid) {
      setError("");
    }
    setErrors(errs);
    return isValid;
  }

  //Validating Reset Password Form
  const validateResetForm = () => {
    const enteredCode = OTP;
    const enteredPassword = passwordInputRef.current.value;
    let isValid = true;
    const errs = [];
    if (enteredCode === "") {
      isValid = false;
      errs.push("codeInputRef");
    }
    if (!isNumeric(enteredCode)) {
      isValid = false;
      errs.push("codeInputRef");
    }
    if (enteredPassword.length < 8) {
      isValid = false;
      errs.push('passwordInputRef');
    }
    if (enteredPassword.toLowerCase() === enteredPassword) {
      isValid = false;
      errs.push('passwordInputRef');
    }
    if (enteredPassword.toUpperCase() === enteredPassword) {
      isValid = false;
      errs.push('passwordInputRef');
    }
    if (!/\d/.test(enteredPassword)) {
      isValid = false;
      errs.push('passwordInputRef');
    }
    if (!hasSpecialChar(enteredPassword)) {
      isValid = false;
      errs.push('passwordInputRef');
    }
    if (!isValid) {
      setError("");
    }
    setErrors(errs);
    if(hasError("passwordInputRef")){
      setPassPolicyError(t('please_password'))
    }
    return isValid;
  }

  function hasError(key) {
		return errors.indexOf(key) !== -1;
	}

  const hasSpecialChar = strVal => {
    const charSet = "~`!#$%@^&*+=-[]\\\'_;,/{}()|\":<>?";
    for (let j = 0; j < strVal.length; j++) {
      if (charSet.indexOf(strVal.charAt(j)) != -1) {
        return true;
      }
    }
    return false;
  }

  //Password input eye button functionality
  const showHidePassword = event => {
    const type = passwordInputRef.current.type === "password" ? "text" : "password";
    passwordInputRef.current.type = type;
    event.target.classList.toggle("bi-eye");
  }

  const isNumeric = val => !isNaN(val);

  //Validating the password complexity
  const validatePassword = () => {
    // has uppercase letter
    const hasUpperCase = passwordInputRef.current.value.toLowerCase() != passwordInputRef.current.value;
    setContainsUL(hasUpperCase);

    // has lowercase letter
    const hasLowerCase = passwordInputRef.current.value.toUpperCase() != passwordInputRef.current.value;
    setContainsLL(hasLowerCase);

    // has number
    const hasNumber = /\d/.test(passwordInputRef.current.value);
    setContainsN(hasNumber);

    // has special character
    const hasSplChr = hasSpecialChar(passwordInputRef.current.value);
    setContainsSC(hasSplChr);

    // has 8 characters
    const hasLength8 = passwordInputRef.current.value.length >= 8;
    setContains8C(hasLength8);
  };

  return (
    <>
      {/* Forgot Password Popup - This block renders by default whenever forgot passowrd is rendered*/}
      {isLoading && <LoadingContent />}
      {showChangePasswordScreen === "ENTER_EMAIL" && !isLoading && (
        <>
          <div className={classes["iiris-forgetpassword-form"]}>
            <form method="post" autoComplete="off">
              <h4 className="iiris-text-center">{t('forgot_password')}</h4>
              <div
                className="iiris-form-group"
                style={{ margin: "1rem 0rem 2rem 0rem", textAlign: "center" }}
              >
                <h6>{t('send_instructions')} </h6>
              </div>
              <div
                className={classes["iiris-error-space"]}
                style={{ display: emailError.length > 0 ? "block" : "none" }}
              >
                {emailError}
              </div>

              <div>
                <div
                  className="iiris-form-group"
                  style={{ margin: "1rem 0rem 2rem 0rem" }}
                >
                  <label className={classes["iiris-form-label"]}>{t('email')}</label>
                  <label style={{ color: "red", marginLeft: "3px" }}>*</label>
                  <div className="iiris-input-group">
                    <input
                      type="text"
                      className="iiris-form-control"
                      name="email"
                      required="required"
                      ref={emailInputRef}
                      onChange={(e) => clearObjError(e.target)}
                    />
                  </div>
                  <div
                    className={
                      classes[
                      hasError("emailInputRef") ? "iiris-error-space" : "iiris-hidden"
                      ]
                    }
                  >
                    {t('valid_email')}
                  </div>
                </div>
    
                {   
                    identityConfig.captcha && (
                      <div className={classes['iiris-g-recaptcha']}>
                        {identityConfig.captcha_type === "recaptcha" && (
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={google_captcha_key}
                            onloadCallback={onCaptchaLoad}
                            onChange={verifyCaptcha}
                            onExpired={expireCaptcha}
                            type="image"
                            hl={identityConfig.language}
                          />
                        )}
                        {identityConfig.captcha_type === "hcaptcha" && (
                          <HCaptcha
                            ref={hcaptchaRef}
                            sitekey={hcaptcha_key}
                            onLoad={onCaptchaLoad}
                            onVerify={verifyCaptcha}
                            onError={onError}
                            onExpire={expireCaptcha}
                          />
                        )}
                        <div
                          className={
                            classes[hasError('captcha') ? 'iiris-error-space' : 'iiris-hidden']
                          }
                        >
                          {t('please_captcha')}
                        </div>
                      </div>
                    )
                }
                <div className="iiris-text-center iiris-btn-center">
                  <button
                    onClick={sendInstructions}
                    type="button"
                    className={`${classes["social-btn"]} iiris-btn iiris-btn-block `}
                    style={{ color: identityConfig.skin.skin_color, background: identityConfig.skin.skin_background }}
                  >
                    {t('submit')}
                  </button>
                </div>
                <div className="iiris-text-center" style={{ margin: "1rem 0rem" }}>
                  <a
                    href="#"
                    className={classes["iiris-back-link"]}
                    onClick={redirectToSignIn}
                  >
                    {t('back_sign_in')}
                  </a>
                </div>
              </div>
              <button
                type="submit"
                disabled
                style={{ display: "none" }}
                aria-hidden="true"
              ></button>
            </form>
          </div>
        </>
      )}

      {/* Reset Password Popup - This block renders when users submit the email ID on forgot password screen*/}
      {showChangePasswordScreen === "ENTER_CODE" && !isLoading && (
        <>
          <div className={classes["iiris-forgetpassword-form"]}>
            <form autoComplete="off">
              <h4 className="iiris-text-center">{t('reset_password')}</h4>
              <div className="iiris-text-center">
                {t('must_verification_code')}
              </div>

              <div
                className="iiris-form-group iiris-text-center"
                style={{ marginTop: "15px" }}
              >
                {t('check_email')}
              </div>

              <div
                className={classes["iiris-error-space"]}
                style={{ display: error.length > 0 ? "block" : "none" }}
              >
                {error}
              </div>

              <div>
                <div
                  className="iiris-form-group"
                  style={{ margin: "1rem 0rem 2rem 0rem" }}
                >
                  <label className={classes["iiris-form-label"]}>
                    {t('verification_code')}
                  </label>
                  <label style={{ color: "red", marginLeft: "3px" }}>*</label>
                  <div className="iiris-input-group">
                    <OTPInput
                      value={OTP}
                      autoFocus={true}
                      onChange={setOTP}
                      OTPLength={6}
                      otpType="number"
                      disabled={false}
                      style={{
                        justifyContent: 'space-between',
                        gap: '.2rem',
                        width: "100%"
                      }}
                      inputStyles={{
                        width: "45px",
                        marginRight: 0,
                        height: "45px",
                        border: "1px solid #ced4da",
                        outline: "none"
                      }}
                    />
                  </div>
                  <div
                    className={
                      classes[
                      hasError("codeInputRef") ? "iiris-error-space" : "iiris-hidden"
                      ]
                    }
                  >
                    {t('please_verification_code')}
                  </div>
                  <div style={{ width: "100%", marginTop: "10px" }}>
                    <label className={classes["iiris-form-label"]}>
                      {t('new_password')}
                    </label>
                    <label style={{ color: "red", marginLeft: "3px" }}>*</label>
                    <div className="iiris-input-group">
                      <input
                        type="password"
                        className="iiris-form-control"
                        name="password"
                        required="required"
                        ref={passwordInputRef}
                        onKeyUp={validatePassword}
                        onChange={(e) => clearObjError(e.target)}
                      />
                      <i
                        style={{
                          position: "absolute",
                          right: "12px",
                          zIndex: 5555,
                          cursor: "pointer",
                        }}
                        class="bi bi-eye-slash"
                        id="togglePassword"
                        onClick={showHidePassword}
                      ></i>
                    </div>
                    <div
                      className={classes["iiris-error-space"]}
                      style={{ display: passPolicyError.length > 0 ? "block" : "none" }}
                    >
                      {passPolicyError}
                    </div>

                    <div className="iiris-form-group">
                      <ul
                        style={{
                          columnCount: 1,
                          margin: "5px 0px 20px",
                          padding: "0px 0px",
                        }}
                      >
                        {mustContainData.map((data) => (
                          <MustContainItem data={data} />
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="iiris-text-center iiris-btn-center">
                  <button
                    onClick={changePassword}
                    type="button"
                    className={`${classes["social-btn"]} iiris-btn iiris-btn-block `}
                    style={{ color: identityConfig.skin.skin_color, background: identityConfig.skin.skin_background }}
                  >
                    {t('reset_password')}
                  </button>
                </div>
                <div className="iiris-text-center" style={{ margin: "1rem 0rem" }}>
                  <a
                    href="#"
                    className={classes["iiris-back-link"]}
                    onClick={redirectBack}
                  >
                    {t('back')}
                  </a>
                </div>
              </div>
              <button
                type="submit"
                disabled
                style={{ display: "none" }}
                aria-hidden="true"
              ></button>
            </form>
          </div>
        </>
      )}

      {/* Reset Password Popup - This block renders when password reset is successful*/}
      {showChangePasswordScreen === "SUCCESS" && !isLoading && (
        <>
          <div className={classes["iiris-forgetpassword-form"]}>
            <form autoComplete="off">
              <h2 className="iiris-text-center">{t('password_reset_successful')}</h2>
              <div
                className="iiris-form-group"
                style={{ margin: "1rem 0rem 2rem 0rem" }}
              >
                <h6>{t('sign_in_new_password')} </h6>
              </div>

              <div>
                <div className="iiris-text-center" style={{ margin: "1rem 0rem" }}>
                  <a
                    href="#"
                    className={classes["iiris-back-link"]}
                    onClick={redirectToSignIn}
                  >
                    {t('back_sign_in')}
                  </a>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
}
