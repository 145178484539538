import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import isEmail from 'validator/lib/isEmail';
import { useTranslation } from "react-i18next";

import { ProgressiveProfile } from "../ProgressiveProfile";
import { UserDetails } from '../UserDetails'
import { CustomSelectComponent } from "../../common/CustomSelectComponent";
import { IRISService } from "../../service/IRISService";
import { SignerService } from "../../service/SignerService";
import { LoadingContent } from "../../common/LoadingContent";
import { CognitoService } from "../../service/CognitoService";
import { trackBusinessEvt } from "../../common/CustomEvents";
import { sha256 } from '../../common/IdentityUtils';

import classes from "./WorkEmailCheck.module.css";

var fieldInfo = {};

export const WorkEmailCheck = props => {
  const identityConfig = props.identityConfig;
  const { t } = useTranslation();
  const emailInputRef = useRef();
  const [countryData, setCountryData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [state, setState] = useState("");
  const [errors, setErrors] = useState([]);
  const [countryType, setCountryType] = useState("");
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCountryId, setSelectedCountryId] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedStateId, setSelectedStateId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [socialType, setSocialType] = useState("");
  const [userAttributes, setUserAttributes] = useState({});
  const givenNameInputRef = useRef();
  const familyNameInputRef = useRef();
  const [userObj, setUserObj] = useState(
    props.userObj != null ? props.userObj : null
  );
  const privacy_policy_text = t('privacy_policy_text');
  const [FP_text, setFPConsentText] = useState('');
  const [fp_consent, setFPConsent] = useState(null);
  const [isTypeAConsentSelected, setTypeAConsentSelected] = useState(false);
  const [isTypeDConsentSelected, setTypeDConsentSelected] = useState(false);

  let authToken = document.cookie.split('; ').find(row => row.startsWith('IRIS_AUTH=')).split('=')[1];
  //let idToken = document.cookie?.split('; ').find(row => row.startsWith('IRIS_IDT='))?.split('=')[1];

  useEffect(() => {
    localStorage.setItem('widget_screen', 'work_email');
    const userDomain = userObj.email.split('@')[1];
    const fetchCompany = async (domain) => {
      try {
        const companyData = await axios.get(`${identityConfig.proxyURL}/v4/enrichment/company/domain/${domain}`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": authToken,
          }
        });
        setCompanyData(companyData.data);
      } catch (e) {
        setCompanyData({});
      }
    }
    if (!identityConfig.show_progressive_profile && has_company_question(identityConfig.pages)) {
      fetchCompany(userDomain);
    }
  }, [authToken, identityConfig.proxyURL, userObj])

  function has_company_question(pages){
    pages.map((page, index) => {
			page.questions.map(async (question, i) => {
				let id = question.profile_id ? question.profile_id : question.id;
        if (id === 'company') {
          return true
        }
      });
		});
    return false
  }
  const updateCognitoAttribute = async (businessEmail, isLinkedInLogin, email) => {
    // UPDATE USER WITH BUSINESS EMAIL
    try {
      await CognitoService.updateUserAttributes({
        "Name": "custom:business_email",
        "Value": businessEmail
      });
    } catch (e) {
      console.log(e);
    }
    try {
      await CognitoService.updateTokens(
        identityConfig.clientId,
        isLinkedInLogin,
        email
      ).then(token => {
        authToken = token.access_token;
        //idToken = token.id_token;
      });
    } catch (e) {
      console.log(e);
    }
  }

  const showProgressiveProfile = async(business_email, id) => {
    if (business_email && identityConfig.cognito_add_business_email_attr && userObj && !userObj['custom:business_email']) {
      const isLinkedInLogin = (socialType === 'linkedin' || userObj.email.startsWith('linkedin.'));
      await updateCognitoAttribute(
        business_email,
        isLinkedInLogin,
        isLinkedInLogin ? userObj.email.split("linkedin.")[1] : userObj.email
      );
    }
    if (identityConfig.show_progressive_profile) {
      setState("progressiveprofile");
    } else if (userObj) {
      const data = {
        email: userObj.email,
        first_name: userObj.given_name,
        last_name: userObj.family_name,
        phone_number: '',
        country: userObj.country_name,
        country_id: userObj.country_id ? userObj.country_id : '',
        country_type: userObj.countryType ? userObj.countryType : '',
        state: userObj.state_name,
        state_id: userObj.state,
        city: '',
        terms: true,
        is_social_login: userObj.is_social_login,
        social_accounts: userObj.social_accounts
      }
      if (business_email) {  // checking for business email
        data.email = business_email;
        setIsLoading(true);
      }

      if (localStorage.getItem("iiris_ust")) {
        const timeData = {};
        timeData.cat = 'IRISIdentityEvent';
        timeData.lbl = 'Capture Time';
        timeData.act = 'registrationTime';
        timeData.prop = 'registrationTime';
        const startTime = Date.parse(localStorage.getItem("iiris_ust"));
        const endTime = new Date();
        timeData.val = (endTime - startTime).toFixed(2);
        if (window.IIRISTracker && window.IIRISTracker.trackStructEvt) {
          window.IIRISTracker.trackStructEvt(timeData);
        }
      }
      if (identityConfig.persist_session === false) {
        clearIRISCookies();
      }
      if (window.irisRegisterCallback) {
        let userData = {};
        if (id) {
          let callbackUrl = ''
          if (!identityConfig.show_progressive_profile){
            callbackUrl =  `${identityConfig.audienceDomainURL}/v3/audience/profile/${id}`
          }else{
            callbackUrl = `${identityConfig.audienceDomainURL}/v3/audience/profile/${id}/${identityConfig.id}`
          }
          IRISService.makeGetRequest(
            callbackUrl,
            authToken
          ).then(res => {
            if (res.status === 'success') {
              userData = res.data[0];
              userData.profile_picture = props.userObj.picture ? props.userObj.picture : ""
              userData.is_social_login = JSON.parse(localStorage.getItem("is_social_login"))
              const regCallBackObj = { "success": true, "user": userData, "token": authToken };
              window.irisRegisterCallback(regCallBackObj);
            } else if (res.status === 'error') {
              const regCallBackObj = { "success": false, "user": null, "token": null };
              window.irisRegisterCallback(regCallBackObj);
            }
          }).catch((err) => {
            const regCallBackObj = { "success": false, "user": null, "token": null };
            window.irisRegisterCallback(regCallBackObj);
          });
        } else {
          userData = data;
          userData.profile_picture = props.userObj.picture ? props.userObj.picture : ""
          const regCallBackObj = { "success": true, "user": userData, "token": authToken, "idToken": "" };
          window.irisRegisterCallback(regCallBackObj);
        }
      }
      localStorage.setItem('CheckLocalUser', 'NO');
      identityConfig.redirectToProfilePage();
      setIsLoading(false);
    }
  }

  const clearObjError = obj => {
    obj.style.borderColor = "";
    setError("");
  }

  function hasError(key) {
		return errors.indexOf(key) !== -1;
	}

  const handleConsent = async(token, userObj) => {
    try {
      const consentPayload = {
        iiris_id: userObj.id,
        product_id: identityConfig.id,
        product_type: identityConfig.siteType,
        product_sub_type: identityConfig.siteSubType,
        version: '2.x',
        date: new Date(),
        country: userObj.country,
        country_group: userObj.countryType ? userObj.countryType : '',
        consent: [
          {
            type: 'PP',
            sub_type: '',
            text: privacy_policy_text,
            link: 'https://www.informa.com/privacy-policy/',
            flag: true,
          },
        ],
      }
      if (fp_consent !== null) {
        consentPayload.consent.push({
          type: '1P',
          sub_type: 'non-purchase',
          text: FP_text,
          link: '',
          flag: fp_consent,
        });
      } else if (fp_consent == null && (countryType === 'B' || countryType === 'C')) {
        consentPayload.consent.push({
          type: '1P',
          sub_type: 'non-purchase',
          text: t('tick_here_text'),
          link: '',
          flag: true,
        });
      }

      IRISService.makePostRequest(
        identityConfig.audienceDomainURL + '/v3/consent/data',
        consentPayload,
        token
      );
    } catch (e) {
      console.log(e)
    }
  }

  const onContinue = async() => {
    if (!validateForm()) {
      return;
    }
    setIsLoading(true);
    let socialHandleDetails = userAttributes.hasOwnProperty('identities')
      ? JSON.parse(userAttributes.identities)[0]
      : undefined;

    let first_name = '';
    let last_name = '';

    if (props.userObj.identities) {
      console.log(props.userObj.identities)
      const identities = JSON.parse(props.userObj.identities);
      const socialType = identities[0].providerName.toLowerCase();
      if (!props.userObj.family_name) {
        first_name = props.userObj.given_name;
        last_name = familyNameInputRef.current.value;
      } else {
        first_name = props.userObj.given_name;
        last_name = props.userObj.family_name;
      }
    } else {
      first_name = props.userObj.given_name;
      last_name = props.userObj.family_name;
    }

    let payload = {
      business_email: emailInputRef.current.value,
      first_name,
      last_name,
      company: "",
      product_ids: [identityConfig.id],
      country: selectedCountryId,
      country_name: selectedCountry,
      state: selectedStateId,
      state_name: selectedState,
      is_social_login: true,
      business_type: "",
      job_function: "",
      job_title: "",
      social_accounts: [
        {
          social_type: socialType,
          social_email: props.userObj.email.startsWith('linkedin.') ? props.userObj.email.split("linkedin.")[1] : props.userObj.email,
          profile_picture: props.userObj.picture ? props.userObj.picture : ""
        },
      ],
    };

    if (socialHandleDetails !== undefined) {
      if (socialHandleDetails.providerName === "Google") {
        payload.google_id = socialHandleDetails.providerName + "_" + socialHandleDetails.userId;
      }
      else if (socialHandleDetails.providerName === "Facebook") {
        payload.facebook_id = socialHandleDetails.providerName + "_" + socialHandleDetails.userId;
      }
      else if (socialHandleDetails.providerName === "Linkedin") {
        payload.linkedin_id = socialHandleDetails.providerName + "_" + socialHandleDetails.userId;

      }
    }

    IRISService.makePostRequest(
      identityConfig.audienceDomainURL + '/v3/audience/profile',
      payload,
      authToken
    )
      .then((audResponse) => {
        if (audResponse.status === 'success') {
          try {
            //Tracker Sign Up Event for Sign Up
            const transactionID = window.localStorage.getItem("regTransactionId");
            const evt = {
              entityId: 'NA',
              transactionId: transactionID,
              formId: 'registration',
              status: 'page1submit-social',
              type: 'register',
              ts: new Date().toISOString()
            }
            const asset = {
              id: 'NA',
              name: window.location.host,
              type: 'form',
              size: 0,
              url: window.location.href,
              created: new Date().toISOString(),
              lastUpdated: new Date().toISOString(),
              tags: 'SignUp',
              metadata: JSON.stringify(payload),
            }
            trackBusinessEvt(evt, asset);
          }
          catch (error) {
            console.log(error);
          }
          const data = audResponse.data[0];
          let user = {
            id: data.id,
            email: data.business_email,
            given_name: data.first_name,
            family_name: data.last_name,
            flow: "social",
            country: data.country,
            country_id: selectedCountryId,
            product_ids: data.product_ids,
            country_name: data.country_name,
            countryType: countryType,
            state: data.state,
            state_name: data.state_name,
            company: data.company,
            job_function: data.job_function,
            business_type: data.business_type,
            status: "success",
            picture: props.userObj.picture ? props.userObj.picture : "",
            social_accounts: data.social_accounts,
            job_title: data.job_title,
            is_social_login: data.is_social_login,
          };
          handleConsent(authToken, user);
          setUserObj(user);
          setIsLoading(false);
          showProgressiveProfile(data.business_email, data.id);
        }
      }).catch((e) => {
        setIsLoading(false);
        setError("Unable to create user profile.");
      });
  }

  const validateForm = () => {
    const enteredEmail = emailInputRef.current.value;
    let socialType = ""
    if (props.userObj.identities) {
      const identities = JSON.parse(props.userObj.identities);
      socialType = identities[0].providerName.toLowerCase();
    }
    let isValid = true;
    let errs = [];
    if (enteredEmail === "") {
      isValid = false;
      errs.push("emailInputRef");
    }
    if (socialType === "signinwithapple") {
      const firstName = givenNameInputRef.current.value;
      const lastName = familyNameInputRef.current.value;
      if (firstName === "") {
        isValid = false;
        errs.push("givenNameInputRef");
      }
      if (lastName === "") {
        isValid = false;
        errs.push("familyNameInputRef");
      }
    }
    //email validation
    var validEmail = isEmail(enteredEmail);
    if (!validEmail) {
      isValid = false;
      errs.push("emailInputRef");
    }
    if (selectedCountry === "") {
      isValid = false;
      errs.push("country");
    }
    if (selectedState === "" && stateData.length > 0) {
      isValid = false;
      errs.push("state");
    }
    switch (countryType) {
      case "A":
        if (!isTypeAConsentSelected) {
          isValid = false;
          errs.push("Aterms");
        }
        break;
      case "D":
        if (!isTypeDConsentSelected) {
          isValid = false;
          errs.push("Cterms");
        }
        break;
      default:
        break;
    }

    if (!isValid) {
      setError("");
    }
    setErrors(errs);
    return isValid;
  }

  const getCountryData = useCallback(() => {
    SignerService.signedAPIRequest(
      "execute-api",
      "GET",
      identityConfig.proxyURL + `/v2/taxonomy/public/countries?locale=${identityConfig.language}`,
      ""
    ).then(response => {
      setCountryData(response.data);
    });
  }, [identityConfig]);

  const OnCountrySelectChange = (...e) => {
    if (e.length !== 0) {
      setSelectedCountry(e[1].name);
      setSelectedCountryId(e[1].id);
      setStateData(e[1].states);
      fieldInfo["country"] = e[1].id;
      fieldInfo["country_name"] = e[1].name;
      if (e[1].states.length === 0) {
        setSelectedState('');
        setSelectedStateId('');
      }
      var countryTypeVal = e[1].group;
      setCountryType(countryTypeVal);
    } else {
      setSelectedCountry('');
      setCountryType('');
    }
    trackFields();
  }

  const OnStateSelectChange = (...e) => {
    if (e.length !== 0) {
      setSelectedStateId(e[1].id);
      setSelectedState(e[1].name);
      fieldInfo["state"] = e[1].id;
      fieldInfo["state_name"] = e[1].name;
    } else {
      setSelectedState('');
      setSelectedStateId('');
    }
    trackFields();
  }

  const trackFields = () => {
    try {
      fieldInfo["abandoned_on"] = "page1_dropout";
      fieldInfo["email"] = emailInputRef.current.value;
      localStorage.setItem("trackedFields", JSON.stringify(fieldInfo));
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleTypeASiteConsent = (event) => {
    setTypeAConsentSelected(true);
    setTypeDConsentSelected(false);
    setFPConsentText(t('FP_consent_text'));
    if (event.target.value === 'YES') {
      setFPConsent(true);
    } else if (event.target.value === 'NO') {
      setFPConsent(false);
    }
  }

  const handleTypeBSiteConsent = event => {
    setFPConsentText(t('tick_here_text'));
    if (event.target.checked) {
      setFPConsent(false); // If selected means opt-out here
    } else {
      setFPConsent(true);
    }
  }

  const handleTypeDConsent = event => {
    setFPConsentText(t('by_continuing'));
    if (event.target.checked) {
      setTypeDConsentSelected(true);
      setTypeAConsentSelected(false);
      setFPConsent(true);
    } else {
      setTypeDConsentSelected(false);
      setTypeAConsentSelected(false);
      setFPConsent(false);
    }
  }

  useEffect(() => {
    try {
      CognitoService.getUserAttributes().then(userAttr => {
        setUserAttributes(userAttr);
      });
    } catch (e) {
      console.log(e);
    }
    getCountryData();
    localStorage.setItem("profile_picture", props.userObj.picture ? props.userObj.picture : "");

    if (props.userObj.identities) {
      const identities = JSON.parse(props.userObj.identities);
      let socialType = identities[0].providerName.toLowerCase();
      if (socialType === 'signinwithapple') {
        socialType = 'apple';
      }
      setSocialType(socialType);
      handleSocialFlows(socialType, props.userObj.email, authToken);
      try {
        const evtObj = {};
        evtObj.cat = 'IRISIdentityEvent';
        evtObj.lbl = 'Login Mode';
        evtObj.act = 'login';
        evtObj.prop = 'socialLogin';
        evtObj.val = getSocialType(identities[0].providerName.toLowerCase());
        if (window.IIRISTracker && window.IIRISTracker.trackStructEvt) {
          window.IIRISTracker.trackStructEvt(evtObj);
        }
      } catch (error) {
        console.log(error);
      }
    } else if (props.userObj.email.startsWith('linkedin.')) {
      setSocialType('linkedin');
      handleSocialFlows('linkedin', props.userObj.email.split("linkedin.")[1], authToken);
      try {
        const evtObj = {};
        evtObj.cat = 'IRISIdentityEvent';
        evtObj.lbl = 'Login Mode';
        evtObj.act = 'login';
        evtObj.prop = 'socialLogin';
        evtObj.val = getSocialType('linkedin');
        if (window.IIRISTracker && window.IIRISTracker.trackStructEvt) {
          window.IIRISTracker.trackStructEvt(evtObj);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      const authToken = getIIRISCookie("IRIS_AUTH");
      const irisId = getIIRISCookie("IRIS_ID");
      if(authToken && irisId && identityConfig.show_progressive_profile){
        setIsLoading(true);
        IRISService.makeGetRequest(
          `${identityConfig.audienceDomainURL}/v3/audience/registration/${irisId}/${identityConfig.id}`,
          authToken
        ).then(regResponse => {
          if (regResponse.status === 'success') {
            localStorage.setItem('CheckLocalUser', 'NO');
            identityConfig.redirectToProfilePage();
          } else if (regResponse.status === 'error') {
              setIsLoading(false);
              showProgressiveProfile();
          }
        }).catch((err) => {
            setIsLoading(false);
            showProgressiveProfile();
        });
      }else{
        showProgressiveProfile();
      }
    }
  }, []);

  const getIIRISCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const getSocialType = social => {
    if (social === 'linkedin') {
      return 2.00;
    } else if (social === 'google') {
      return 3.00;
    } else if (social === 'facebook') {
      return 4.00;
    }
    return 0;
  }

  const handleSocialFlows = (socialType, socialEmail, token) => {
    try {
      const evtObj = {};
      evtObj.cat = 'IRISIdentityEvent';
      evtObj.lbl = 'Page Visited';
      evtObj.act = 'businessEmailCheck';
      evtObj.prop = 'social';
      evtObj.val = 1.00;
      if (window.IIRISTracker && window.IIRISTracker.trackStructEvt) {
        window.IIRISTracker.trackStructEvt(evtObj);
      }
    } catch (error) {
      console.log(error);
    }

    setIsLoading(true);

    const headers = {
      "Authorization": token
    };
    
    let profileinfo_payload = {
      "email" : socialEmail,
      "profile_type":socialType
    }
    IRISService.makePostRequest(
      `${identityConfig.audienceDomainURL}/v3/audience/profileinfo`,
        profileinfo_payload,
        token
    ).then((socialChkResponse) => {
      if (socialChkResponse.status === 'success') {
        const audienceData = socialChkResponse.data[0];
        const irisid = audienceData.id;
        //Set User to Track
        document.cookie = "IRIS_ID=" + irisid + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
        sha256(audienceData.business_email.toLowerCase()).then((hash) => {
          document.cookie = "IRIS_UHASH=" + hash + "; path=/; max-age=7776000; samesite=strict; secure;domain=" + window.location.hostname;
        });
        if (window.IIRISTracker && window.snowplow) {
          window.snowplow("setUserIdFromCookie", "IRIS_ID");
        }
        let user = {
          id: audienceData.id,
          email: audienceData.business_email,
          given_name: audienceData.first_name,
          family_name: audienceData.last_name,
          flow: "social",
          country: audienceData.country,
          country_id: selectedCountryId,
          countryType: countryType,
          state: audienceData.state,
          company: audienceData.company,
          job_function: audienceData.job_function,
          business_type: audienceData.business_type,
          status: "success",
          picture: props.userObj.picture ? props.userObj.picture : "",
          social_accounts: audienceData.social_accounts,
          job_title: audienceData.job_title,
          is_social_login: audienceData.is_social_login,
        };
        //check for user registration
        const regUri = identityConfig.audienceDomainURL + "/v3/audience/registration/" + irisid + "/" + identityConfig.id;
        axios.get(regUri, { headers }).then(async (regResponse) => {
          if (localStorage.getItem("iiris_ust")) {
            const timeData = {};
            timeData.cat = 'IRISIdentityEvent';
            timeData.lbl = 'Capture Time';
            timeData.act = 'loginTime';
            timeData.prop = 'loginTime';
            const startTime = Date.parse(localStorage.getItem("iiris_ust"));
            const endTime = new Date();
            timeData.val = (endTime - startTime).toFixed(2);
            if (window.IIRISTracker && window.IIRISTracker.trackStructEvt) {
              window.IIRISTracker.trackStructEvt(timeData);
            }
          }
          if (regResponse.status === 200) {
            //if registration found rediret to parent
            if (!props.userObj['custom:business_email'] && identityConfig.cognito_add_business_email_attr) {
              const isLinkedInLogin = (socialEmail.startsWith('linkedin.'));
              await updateCognitoAttribute(
                audienceData.business_email,
                isLinkedInLogin,
                isLinkedInLogin ? socialEmail.split("linkedin.")[1] : socialEmail
              );
            }
            if (identityConfig.persist_session === false) {
              clearIRISCookies();
            }
            if (window.irisLoginCallback) {
              let callbackUrl = ''
              if (!identityConfig.show_progressive_profile){
                callbackUrl =  `${identityConfig.audienceDomainURL}/v3/audience/profile/${audienceData.id}`
              }else{
                callbackUrl = `${identityConfig.audienceDomainURL}/v3/audience/profile/${audienceData.id}/${identityConfig.id}`
              }
              IRISService.makeGetRequest(
                callbackUrl,
                authToken
              ).then(res => {
                if (res.status === 'success') {
                  let userData = res.data[0];
                  userData.profile_picture = props.userObj.picture ? props.userObj.picture : ""
                  userData.is_social_login = JSON.parse(localStorage.getItem("is_social_login"))
                  const loginCallBackObj = { "success": true, "user": userData, "token": authToken };
                  window.irisLoginCallback(loginCallBackObj);
                } else if (res.status === 'error') {
                  const loginCallBackObj = { "success": false, "user": null, "token": null };
                  window.irisLoginCallback(loginCallBackObj);
                }
              }).catch((err) => {
                const loginCallBackObj = { "success": false, "user": null, "token": null };
                window.irisLoginCallback(loginCallBackObj);
              });
            }
            localStorage.setItem("CheckLocalUser", "NO");
            identityConfig.redirectToProfilePage();
          }
        }).catch(error => {
          setIsLoading(false);
          if (error.response) {
            if (error.response.status === 404) {
              //show progressive profile screen
              setUserObj(user);
              showProgressiveProfile(audienceData.business_email, audienceData.id);
              setIsLoading(false);
            }
          } else {
            setIsLoading(false);
          }
        });
      }
    }).catch(error => {
      setIsLoading(false);
      if (error.response) {
        if (error.response.status === 404) {
          //show work email check screen
          setState("social");
        }
      } else {
        setIsLoading(false);
      }
    });
  }

  const clearIRISCookies = () => {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      let eqPos = cookie.indexOf('=')
      let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      if (name.indexOf('IRIS_') >= 0) {
        document.cookie =
          name +
          '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=' +
          window.location.hostname;
      }
    }
  }

  return (
    <div>
      {isLoading && <LoadingContent />}
      {state === "social" && !isLoading && (
        <div className={classes["iiris-work-email-form"]}>
          <form method="post" autoComplete="off">
            <h4 className="iiris-text-center">
              {props.identityConfig.siteName}
            </h4>
            <div className="iiris-text-center">{t('more_details')}</div>
            <div
              className={classes["iiris-error-space"]}
              style={{ display: error.length > 0 ? "block" : "none" }}
            >
              {error}
            </div>
            <div className="iiris-form-group">
              <label className={classes["iiris-form-label"]}>{t('business_email')}</label>
              <label style={{ color: "red", marginLeft: "3px" }}>*</label>
              <div className="iiris-input-group">
                <input
                  type="text"
                  className="iiris-form-control"
                  name="email"
                  required="required"
                  ref={emailInputRef}
                  onChange={(e) => { clearObjError(e.target); trackFields() }}
                />
              </div>
              <div
                className={
                  classes[hasError("emailInputRef") ? "iiris-error-space" : "iiris-hidden"]
                }
              >
                {t('valid_email')}
              </div>
            </div>
            {(socialType && (!props.userObj.family_name)) && (
                <>
                  {/* <div className={classes["iiris-form-group"]}>
                    <label className={classes["iiris-form-label"]}>
                      {t("first_name")}
                    </label>
                    <label
                      id="red-text"
                      style={{ color: "red", marginLeft: "3px" }}
                    >
                      *
                    </label>
                    <div className="iiris-input-group">
                      <input
                        type="text"
                        className="iiris-form-control"
                        name="given_name"
                        required="required"
                        maxlength="120"
                        ref={givenNameInputRef}
                        onChange={(e) => clearObjError(e.target)}
                      />
                    </div>
                    <div
                      id="error-text"
                      className={
                        classes[
                        hasError("givenNameInputRef")
                          ? "iiris-error-space"
                          : "iiris-hidden"
                        ]
                      }
                    >
                      {t("please_first")}
                    </div>
                  </div> */}
                  <div className={classes["iiris-form-group"]}>
                    <label className={classes["iiris-form-label"]}>
                      {t("last_name")}
                    </label>
                    <label
                      id="red-text"
                      style={{ color: "red", marginLeft: "3px" }}
                    >
                      *
                    </label>
                    <div className="iiris-input-group">
                      <input
                        type="text"
                        className="iiris-form-control"
                        name="family_name"
                        required="required"
                        maxlength="120"
                        ref={familyNameInputRef}
                        onChange={(e) => clearObjError(e.target)}
                      />
                    </div>
                    <div
                      id="error-text"
                      className={
                        classes[
                        hasError("familyNameInputRef")
                          ? "iiris-error-space"
                          : "iiris-hidden"
                        ]
                      }
                    >
                      {t("please_last")}
                    </div>
                  </div>
                </>
              )}
            <div className={classes["iiris-form-container-custom"]}>
              <div className={classes["iiris-form-group"]}>
                <label className={classes["iiris-form-label"]}>{t('country')}</label>
                <label style={{ color: "red", marginLeft: "3px" }}>*</label>
                <div>
                  <CustomSelectComponent
                    isDisabled={false}
                    text={t('select_country')}
                    data={countryData}
                    OnChange={OnCountrySelectChange}
                    isSearchable={true}
                    label="name"
                    value="name"
                    position="bottom"
                    customPosition={true}
                    selectedValue={selectedCountry}
                  />
                </div>
                <div
                  className={
                    classes[hasError("country") ? "iiris-error-space" : "iiris-hidden"]
                  }
                >
                  {t('please_country')}
                </div>
              </div>
              <div className={classes["iiris-form-group"]}>
                <label className={classes["iiris-form-label"]}>{t('state')}</label>
                {(selectedCountryId === "US" ||
                  selectedCountryId === "CA" ||
                  selectedCountryId === "BR") && (
                    <label style={{ color: "red", marginLeft: "3px" }}>*</label>)}
                <div>
                  <CustomSelectComponent
                    isDisabled={stateData.length === 0 ? true : false}
                    text={
                      stateData.length === 0 ? t('not_applicable') : t('select_state')
                    }
                    data={stateData}
                    OnChange={OnStateSelectChange}
                    isSearchable={true}
                    label="name"
                    value="name"
                    position="bottom"
                    customPosition={true}
                    selectedValue={selectedState}
                  />
                </div>
                <div
                  className={
                    classes[hasError("state") ? "iiris-error-space" : "iiris-hidden"]
                  }
                >
                  {t('please_state')}
                </div>
              </div>
            </div>

            {countryType === 'A' && (
              <div>
                <div className={classes['iiris-formbox']}>
                  <div className='iiris-input-group'>
                    <p>
                      {t('news_promotions')}
                    </p>
                  </div>
                  <div className='iiris-form-group'>
                    <div class='iiris-form-check iiris-form-check-inline'>
                      <input
                        name='inlineRadioOptions1'
                        class='iiris-form-check-input'
                        type='radio'
                        id='inlineRadio11'
                        value='YES'
                        onClick={handleTypeASiteConsent}
                      />
                      <label class="iiris-form-check-label" for="inlineRadio11">{t('yes')}</label>
                    </div>
                    <div class='iiris-form-check iiris-form-check-inline'>
                      <input
                        name='inlineRadioOptions1'
                        class='iiris-form-check-input'
                        type='radio'
                        id='inlineRadio12'
                        value='NO'
                        onClick={handleTypeASiteConsent}
                      />
                      <label class="iiris-form-check-label" for="inlineRadio12">{t('no')}</label>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    classes[hasError('Aterms') ? 'iiris-error-space' : 'iiris-hidden']
                  }
                >
                  {t('please_consent')}
                </div>
              </div>
            )}

            {(countryType === 'B' || countryType === 'C') && (
              <div>
                <div className={classes['iiris-formbox']}>
                  <label className={classes['iiris-checkbox-container']}>
                    <input
                      type='checkbox'
                      className={classes['iiris-checkbox-style']}
                      onClick={handleTypeBSiteConsent}
                    />
                    <span className={classes['iiris-checkmark']}></span>
                    <span>
                      {t('tick_here')}
                    </span>
                    <strong> {t('do_not')}</strong>
                    <span> {t('want_to_receive')}</span>
                  </label>
                </div>
              </div>
            )}

            {countryType === 'D' && (
              <div>
                <div className={classes['iiris-formbox']}>
                  <div className='iiris-input-group'>
                    <p>
                      {t('by_continuing')}
                    </p>
                  </div>
                </div>
                <div className={classes['iiris-formbox']}>
                  <label className={classes['iiris-checkbox-container']}>
                    <input
                      type='checkbox'
                      onClick={handleTypeDConsent}
                      className={classes['iiris-checkbox-style']}
                    />
                    <span className={classes['iiris-checkmark']}></span>
                    <span>
                      {t('i_agree')} &nbsp;
                      <a
                        style={{
                          textDecoration: 'underline',
                          color: '#0593FF',
                        }}
                        href='https://www.informa.com/privacy-policy/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <b>{t('privacy_policy')}</b>
                      </a>
                    </span>
                  </label>
                </div>
                <div
                  className={
                    classes[hasError("Cterms") ? 'iiris-error-space' : 'iiris-hidden']
                  }
                >
                  {t('please_privacy_policy')}
                </div>
              </div>
            )}

            {countryType !== '' && (
              <div className='iiris-form-group'>
                <div className={classes['iiris-formbox']}>
                  <span>
                    {t('opt_out')}{' '}
                    <a
                      style={{ textDecoration: 'underline', color: '#0593FF' }}
                      href='https://www.informa.com/privacy-policy/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <b>{t('privacy_policy')}</b>
                    </a>
                  </span>
                </div>
              </div>)}

            <div className="iiris-text-center">
              <button
                onClick={onContinue}
                className={`${classes["iiris-social-btn"]} iiris-btn iiris-btn-block iiris-btn-center`}
                type="button"
                style={{ color: identityConfig.skin.skin_color, background: identityConfig.skin.skin_background }}
              >
                {t('continue')}
              </button>
            </div>
          </form>
        </div>
      )}
      {state === "progressiveprofile" &&
        identityConfig.show_progressive_profile &&
        !isLoading && (
          <ProgressiveProfile
            userObj={userObj}
            identityConfig={identityConfig}
            companyData={companyData}
            setState={setState}
          />
        )}
      {state === "userDetails" &&
        <UserDetails identityConfig={identityConfig} userObj={userObj} />
      }
    </div>
  );
};