import React from 'react';
import { useTranslation } from "react-i18next";

import { SignUp } from './SignUp';
import { SignIn } from './SignIn';
import { SignInWithPassword } from './SignIn/SignInWithPassword';
import { ForgetPassword } from './ForgetPassword';
import { CheckLocalUser } from './CheckLocalUser';
import { WorkEmailCheck } from "./WorkEmailCheck";
import { LoadingContent } from '../common/LoadingContent';
import { ProfileForm } from './ProfileForm'

export const WidgetType = ({ isLoading, formType, setFormType, identityConfig, forgetPasswordScreenConfig, setForgetPasswordScreenConfig, user, isLoggedInUser }) => {
    const { t } = useTranslation();

    return (
        <div style={{ flexGrow: 1 }}>
            {isLoading && <LoadingContent />}
            {formType === 'signin' &&
                (identityConfig.passwordless_login ?
                    <SignIn
                        setFormType={setFormType}
                        identityConfig={identityConfig}
                        setForgetPasswordScreenConfig={setForgetPasswordScreenConfig}
                    />
                    :
                    <SignInWithPassword
                        setFormType={setFormType}
                        identityConfig={identityConfig}
                        setForgetPasswordScreenConfig={setForgetPasswordScreenConfig}
                    />
                )
            }
            {formType === 'signup' && (
                <SignUp
                    setFormType={setFormType}
                    identityConfig={identityConfig}
                />
            )}
            {formType === 'forgetpassword' && (
                <ForgetPassword
                    setFormType={setFormType}
                    identityConfig={identityConfig}
                    screenConfig={forgetPasswordScreenConfig}
                />
            )}
            {isLoggedInUser && user != null && formType === '' && (
                <CheckLocalUser
                    userObj={user}
                    identityConfig={identityConfig}
                />
            )}
            {formType === "workemailcheck" && user != null && !isLoading && (
                <WorkEmailCheck
                    userObj={user}
                    identityConfig={identityConfig}
                />
            )}
            {formType === "profileform" && !isLoading && (
                <ProfileForm
                    userObj={user}
                    identityConfig={identityConfig}
                />
            )}
            {!isLoggedInUser && formType === '' && !identityConfig.show_registration && (
                <div className='iiris-identity-error'>
                    {t('missing_access_right')}
                </div>
            )}
            {!isLoading && !identityConfig.is_modal ?
                <div id="iris-identity-powered-by" className="iiris-powered-by">
                    {t('powered_by')}&nbsp; <strong>IIRIS</strong>
                </div>
            : ""}
        </div>
    )
};